import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { setTemplateCategory } from "../../../../redux/slices/userSlice";
import { RootState } from "../../../../redux/store";

import AddItemDialog, { CategoryDialogResponse, categoryItems } from "./AddItemDialog";
import ColorPickerDialog, {SelectedColorType} from "./ColorPickerDialog";
import AlertDialog from "../../../../components/AlertDialog";
import AddItemForm from "./AddItemForm";

import styles from "../../../../styles/Dashboard/CMS/CMSHome.module.css";


type Props = {
    onCanceled: () => void;
}


const CMSHome: React.FC<Props> = ({onCanceled}) => {
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState<string>("");
    const [alertDialogTitle, setAlertDialogTitle] = useState<string>("")

    const [showAddItemDialog, setShowAddItemDialog] = useState(false);
    const [showColorPickerDialog, setShowColorPickerDialog] = useState(false);
    const [showAddItemForm, setShowAddItemForm] = useState(false);

    const { template } = useSelector((state: RootState) => state.users)
    const { addingNewItemState } = useSelector((state: RootState) => state.home)
    const dispatch = useDispatch();

    // watch template category changes
    useEffect(() => {
        if(addingNewItemState.action === "editing"){
            if(template.category == null && template.attributes.categories.length > 0){
                const categoryLabels: string[] = JSON.parse(JSON.stringify(template.attributes.categories[0]));

                //console.log(template.attributes.categories, categoryLabels)
                const selectedLabel = categoryLabels.pop();
                let selectedIndex = 0;
                const selectStack: number[] = []

                let currentItems = [...categoryItems]
                categoryLabels.forEach((label) => {
                    const labelIndex = currentItems.findIndex((item) => item.label.toLowerCase() === label.toLowerCase());
                    selectStack.push(labelIndex);
                    currentItems = [...categoryItems[labelIndex].items!];
                })
                selectedIndex = currentItems.findIndex((item) => item.label.toLowerCase() === selectedLabel!.toLowerCase())

                dispatch(setTemplateCategory({category: {"selectIndex": selectedIndex,"selectStack": selectStack},threeD: "no"}))
            }     
        }
        
        if(!template.category){
            setShowAddItemDialog(true);
            setShowAddItemForm(false);
        }else{
            setShowAddItemDialog(false);
            setShowAddItemForm(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.category])


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const displayAlertDialog = (title: string, message: string) => {
        setAlertDialogTitle(title);
        setAlertDialogMessage(message);
    }

    // handle close Alert dialog
    const closeAlertDialog = () => {
        setShowAlertDialog(false);
    }

    // handle selected color
    const handleColorSelected = (selectedColorInfo: SelectedColorType) => {
        console.log(selectedColorInfo)
        handleColorDialogClosed();
    }

    // Close color picker dialog
    const handleColorDialogClosed = () => {
        setShowColorPickerDialog(false);
    }

    // Close addItem dialog (cancelled by user)
    const handleCloseAddItemDialog = () => {
        setShowAddItemDialog(false);
        if(onCanceled) onCanceled();  //user terminated the adding process
    }

    // The user selected a category and is ready to proceed
    const handleCategorySelected = (selectedCategoryInfo: null|CategoryDialogResponse) => {
        //setSelectedCategoryInfo(selectedCategoryInfo);
        if(selectedCategoryInfo === null) return;
        dispatch(setTemplateCategory(selectedCategoryInfo))
    }

    return (
        <div className={styles.Home}>
            {showAlertDialog && // show Alert Dialog
                <AlertDialog message={alertDialogMessage} title={alertDialogTitle} onDialogClose={closeAlertDialog}/>
            }
            {showAddItemDialog && // show Add Item Dialog
                <AddItemDialog onCategorySelected={handleCategorySelected} onDialogClose={handleCloseAddItemDialog}/>
            }
            {showColorPickerDialog && // show color Picker dialog
                <ColorPickerDialog onClose={handleColorDialogClosed} onSubmit={handleColorSelected}/>
            }
            {showAddItemForm &&  // show add item form
                <AddItemForm/>
            }
        </div>
    )
}

export default CMSHome;