// src/redux/slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { CategoryDialogResponse as CategoryDialogResponseType } from '../../router/screens/DashBoard/CMS/AddItemDialog';
import { ColorType } from '../../router/screens/DashBoard/CMS/ColorPickerDialog';

const Materials = {
    cotton: { name:"Cotton", value:"cotton" },
    wool: { name:"Wool", value:"wool" },
    silk: { name:"Silk", value:"silk" }
}
type MT = typeof Materials;

type UnitType1 = "cm"|"ft"|"inch";
type UnitType2 = "cm"|"inch";
type CurrencyType = "$"|"€"|"£";
type MatType = keyof MT;

interface ParameterType {
    "XXXS": number, "XXS": number, "XS": number, "S": number, "M": number, "L": number, 
    "XL": number, "XXL": number, "XXXL": number, "4XL": number
} 
type MeasurementsType = {
    shoulder: number; sleeve: number; chest: number; waist: number; back: number
    unit: UnitType2
}
type MaterialType = {
    name: MatType, percentage: number
}
interface ClothesType {
    colors: null | {
        colorName: string, 
        colorValue: string
        images: {url: string, display: boolean}[]
        distanceFromCam: {
            value: number, unit:UnitType1
        },
        zoomLevel: number,
        setSameParameters: boolean,
        setSameMeasurements: boolean,
        parameters: ParameterType,
        measurements: MeasurementsType,
    }[]
    threeD: null | {
        front: {url: string}
        side: {url: string}
        back: {url: string}
    }[]
}
interface TemplateClothChangeType {
    colorName: null|string, 
    colorValue: null|string
    images: null|{url: string, display: boolean}[]
    distanceFromCam: null|{
        value: number, unit: UnitType1
    },
    zoomLevel: null|number,
    setSameParameters: null|boolean,
    setSameMeasurements: null|boolean,
    parameters: null|ParameterType,
    measurements: null|MeasurementsType,
    threeD: {
        front: null|{url: string}
        side: null|{url: string}
        back: null|{url: string}
    }
}
interface ClothAttributes {
    name: string,
    description: string,
    price: number,
    currency: CurrencyType,
    materials: MaterialType[]
    mainMaterial: ""|MatType
    tags: string[],
    categories: Array<string[]>
    dateCreated: number,
    ratings: {rating:number, count: number},
    published: boolean
}
interface TemplateType {
    category: null | CategoryDialogResponseType,
    clothes: ClothesType,
    selectedColorIndex: number,
    attributes: ClothAttributes,
    itemID: string,
    uuid:string,
    saved: boolean,
    clothChanges: boolean,
}

interface DressType {
    clothes: null | ClothesType
    attributes: ClothAttributes
    uuid: string
    itemID: string
}

interface TemplateChangeType {
    name: null|string,
    description: null|string,
    price: null|number,
    currency: null|CurrencyType,
    materials: null|MaterialType[]
    mainMaterial: null|""|MatType
    tags: null|string[],
    categories: null|Array<string[]>
}
interface TemplateChangeUpdateType<K extends keyof TemplateChangeType> {
    key: K; value: TemplateChangeType[K]
}
interface TemplateClothChangeUpdateType<K extends keyof TemplateClothChangeType> {
    key: K; value: TemplateClothChangeType[K]
}

interface UserState {
    username: string;
    brandName: string;
    template: TemplateType
    templateChanges: TemplateChangeType
    templateClothChanges: TemplateClothChangeType[]
    dresses: DressType[],
}

let defaultTemplate: TemplateType = {
    category: null,
    clothes: {
        colors: null,
        threeD: null,
    },
    selectedColorIndex: 0,
    attributes: {
        name: "", description: "", price: 0, currency: "$", mainMaterial: "", tags: [], dateCreated: 0,
        materials: [{name:"cotton", percentage:0}, {name:"wool", percentage:0}, {name:"silk", percentage:0}],
        ratings: {rating:0, count: 0}, published: false, categories:[]
    },
    itemID: "",
    uuid: uuid(),
    saved: true,
    clothChanges: false,  //true if the selected cloth index has changes
}
let initialState: UserState = {
    username: "",
    brandName: "",
    template: {...defaultTemplate},
    templateClothChanges: [],
    templateChanges: {
        categories: null, currency:null, description:null, mainMaterial: null,
        materials: null, name: null, price: null, tags:null,
    },
    dresses: []
};

/* REMOVE THIS LATER */
initialState = {
    ...initialState,
    template: {
        ...initialState.template,
        /*category: {
            category: {
                "selectIndex": 2,
                "selectStack": [1]
            },
            threeD: "yes"
        },
        clothes: {
            colors: [
                {
                    colorName: "Red", colorValue: "red", images: [{url:"Cloth1.png", display:false}, {url:"Cloth2.png",display:false}], 
                    distanceFromCam:{unit:"cm", value:0}, zoomLevel:5, setSameParameters: true, setSameMeasurements:false,
                    measurements: {shoulder: 13, sleeve: 4, chest: 6, waist: 8, back: 16, unit:"inch"},
                    parameters:{XXXS:0, XXS:0, XS:10, S:0, M:0, L:0, XL:15, XXL:0, XXXL:0, "4XL":0},
                
                },
                {
                    colorName: "Blue", colorValue: "blue", images: [{url:"Cloth3.png", display:false}], 
                    distanceFromCam:{unit:"ft", value:10}, zoomLevel:0, setSameParameters: false, setSameMeasurements:true,
                    measurements: {shoulder: 18, sleeve: 41, chest: 3, waist: 10, back: 12, unit:"cm"},
                    parameters:{XXXS:0, XXS:5, XS:0, S:3, M:0, L:0, XL:0, XXL:6, XXXL:0, "4XL":0},
                }, 
                {
                    colorName: "Black", colorValue: "black", images: [{url:"Cloth2.png", display:false}, {url:"Cloth1.png",display:false}], 
                    distanceFromCam:{unit:"inch", value:30}, zoomLevel:4, setSameParameters: false, setSameMeasurements:true, 
                    measurements: {shoulder: 13, sleeve: 13, chest: 6, waist: 12, back: 6, unit:"inch"},
                    parameters:{XXXS:2, XXS:0, XS:0, S:0, M:30, L:0, XL:0, XXL:11, XXXL:0, "4XL":5},
                }
            ],
            threeD: [
                {front: {url: "Cloth1.png"}, side: {url: ""}, back: {url: ""}},
                {front: {url: ""}, side: {url: "Cloth3.png"}, back: {url: ""}},
                {front: {url: ""}, side: {url: ""}, back: {url: "Cloth2.png"}}
            ]
        },
        attributes: {
            name:"Item1", description:"This is Item1 Description", price: 200.50, currency:"£",
            materials:[{name:"cotton", percentage:20}, {name:"wool", percentage:10}, {name:"silk", percentage:0}],
            mainMaterial:"cotton", tags:["Street", "Party", "Stylish"], dateCreated: 1703302120,
            ratings: {rating:3.0, count: 20}, published: false, categories: [["women", "clothing", "T-shirt"]]
        },
        uuid: uuid()*/
    },
    dresses: []
        /*[{
            clothes: {
                colors: [
                    {
                        colorName: "Red", colorValue: "red", images: [{url:"Cloth1.png", display:false}, {url:"Cloth2.png",display:false}], 
                        distanceFromCam:{unit:"cm", value:0}, zoomLevel:5, setSameParameters: true, setSameMeasurements:false, 
                        measurements: {shoulder: 13, sleeve: 4, chest: 6, waist: 8, back: 16, unit:"inch"},
                        parameters:{XXXS:0, XXS:0, XS:10, S:0, M:0, L:0, XL:15, XXL:0, XXXL:0, "4XL":0}
                    },
                    {
                        colorName: "Blue", colorValue: "blue", images: [{url:"Cloth3.png", display:false}], 
                        distanceFromCam:{unit:"ft", value:10}, zoomLevel:0,setSameParameters: false, setSameMeasurements:true, 
                        measurements: {shoulder: 18, sleeve: 41, chest: 3, waist: 10, back: 12, unit:"cm"},
                        parameters:{XXXS:0, XXS:5, XS:0, S:3, M:0, L:0, XL:0, XXL:6, XXXL:0, "4XL":0},
                    }, 
                    {
                        colorName: "Black", colorValue: "black", images: [{url:"Cloth2.png", display:false}, {url:"Cloth1.png",display:false}], 
                        distanceFromCam:{unit:"inch", value:30}, zoomLevel:4, setSameParameters: false, setSameMeasurements:true, 
                        measurements: {shoulder: 13, sleeve: 13, chest: 6, waist: 12, back: 6, unit:"inch"},
                        parameters:{XXXS:2, XXS:0, XS:0, S:0, M:30, L:0, XL:0, XXL:11, XXXL:0, "4XL":5},
                    }
                ],
                threeD: [
                    {front: {url: "Cloth1.png"}, side: {url: ""}, back: {url: ""}},
                    {front: {url: ""}, side: {url: "Cloth3.png"}, back: {url: ""}},
                    {front: {url: ""}, side: {url: ""}, back: {url: "Cloth2.png"}}
                ]
            },
            attributes:{
                name:"Item1", description:"This is Item1 Description", price: 200.50, currency:"£",
                materials:[{name:"cotton", percentage:20}, {name:"wool", percentage:10}, {name:"silk", percentage:0}],
                mainMaterial:"cotton", tags:["Street", "Party", "Stylish"], dateCreated: 1703302120,
                ratings: {rating:4.5, count: 200}, published: true, categories:[["women", "skirts"]]
            },
            uuid: uuid(), itemID:""
        },
        {
            clothes:{
                colors: [
                    {
                        colorName: "Green", colorValue: "green", images: [{url:"Cloth2.png", display:false}, {url:"Cloth1.png",display:false}], 
                        distanceFromCam:{unit:"ft", value:0}, zoomLevel:9,  
                        setSameParameters: true, setSameMeasurements:false, 
                        measurements: {shoulder: 13, sleeve: 4, chest: 6, waist: 8, back: 16, unit:"inch"},
                        parameters:{XXXS:0, XXS:0, XS:10, S:0, M:0, L:0, XL:15, XXL:0, XXXL:0, "4XL":0},
                        
                    },
                    {
                        colorName: "Blue", colorValue: "blue", images: [{url:"Cloth3.png", display:false}], 
                        distanceFromCam:{unit:"ft", value:10}, zoomLevel:0, setSameParameters: false, setSameMeasurements:true, 
                        measurements: {shoulder: 18, sleeve: 41, chest: 3, waist: 10, back: 12, unit:"cm"},
                        parameters:{XXXS:0, XXS:5, XS:0, S:3, M:0, L:0, XL:0, XXL:6, XXXL:0, "4XL":0},
                    },
                ],
                threeD: [
                        {front: {url: "Cloth1.png"}, side: {url: ""}, back: {url: ""}},
                        {front: {url: ""}, side: {url: ""}, back: {url: "Cloth2.png"}}
                ]
            },
            attributes : {
                name:"Item3", description:"This is Item4 Description", price: 300.50, currency:"£",
                materials:[{name:"cotton", percentage:20}, {name:"wool", percentage:10}, {name:"silk", percentage:0}],
                mainMaterial:"cotton", tags:["Street", "Party", "Stylish"], dateCreated: 1703300020,
                ratings: {rating:4.0, count: 40}, published: false, categories:[["men", "trousers"]]
            },
            uuid: uuid(), itemID:""
        }
    ]*/
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username =  action.payload;
        },
        setBrandName: (state, action: PayloadAction<string>) => {
            state.brandName = action.payload;
        },
        setTemplateItemID: (state, action: PayloadAction<string>) => {
            state.template.itemID = action.payload;
        },
        setTemplateAttributeCreateDate: (state, action: PayloadAction<number>) => {
            state.template.attributes.dateCreated = action.payload;
        },


        /** Template Functions */
        setTemplateCategory: (state, action: PayloadAction<CategoryDialogResponseType>) => {
            state.template.category = action.payload;
        },
        addColors: (state, action: PayloadAction<ColorType[]>) => {
            if(!state.template.clothes.colors){
                state.template.clothes.colors = [];
                state.template.clothes.threeD = [];
            }
            action.payload.forEach(({name, value}) => {
                //check if color already added
                const colorIndex = state.template.clothes.colors?.findIndex(({colorValue}) => value === colorValue);

                // go through all previous 3D clothes, if there is one with an image assign a copy to the new item
                const threeDCloth = state.template.clothes.threeD?.find((threeDCloth) => {
                    return threeDCloth.back || threeDCloth.front || threeDCloth.side
                })

                if(colorIndex === undefined || colorIndex === -1){
                    state.template.clothes.colors?.push(
                        { 
                            colorName: name, colorValue: value, images: [],
                            distanceFromCam:{unit:"cm", value:0}, zoomLevel:0, 
                            setSameParameters:false, setSameMeasurements: false,
                            parameters:{XXXS:0, XXS:0, XS:0, S:0, M:0, L:0, XL:0, XXL:0, XXXL:0, "4XL":0},
                            measurements: {shoulder: 0, sleeve: 0, chest: 0, waist: 0, back: 0, unit:"inch"},
                        }
                    )
                    state.template.clothes.threeD?.push(
                        threeDCloth? threeDCloth : {front:{url:""}, side:{url:""}, back:{url:""}}
                    )

                    // add a cloth template changes entry for this new color
                    state.templateClothChanges.push({
                        colorName: name, colorValue: value, images: [],
                        distanceFromCam:{unit:"cm", value:0}, zoomLevel:0, 
                        setSameParameters:false, setSameMeasurements: false,
                        parameters:{XXXS:0, XXS:0, XS:0, S:0, M:0, L:0, XL:0, XXL:0, XXXL:0, "4XL":0},
                        measurements: {shoulder: 0, sleeve: 0, chest: 0, waist: 0, back: 0, unit:"inch"},
                        threeD:threeDCloth? threeDCloth : {front:{url:""}, side:{url:""}, back:{url:""}}
                    })
                }
            })
        },
        setSelectedColorTemplateColorIndex: ((state, action: PayloadAction<number>) => {
            state.template.selectedColorIndex = action.payload;
        }),
        setTemplate3DStatus: ((state, action: PayloadAction<"yes"|"no">) => {
            // adds 3D option to cloth template
            if(state.template.category){
                state.template.category.threeD = action.payload;
            }
        }),
        deleteColorItem: ((state, action: PayloadAction<string>) => {
            //delete a color variant of a cloth and all its associated data

            if(!state.template.clothes.colors || state.template.clothes.colors.length === 0) return;
            const targetIndex = state.template.clothes.colors.findIndex(({colorValue}) => colorValue === action.payload)

            // remove images
            if(targetIndex === -1) return;
            state.template.clothes.colors = [...state.template.clothes.colors.slice(0, targetIndex), 
                                                ...state.template.clothes.colors.slice(targetIndex+1)]

            // remove 3D images
            if(state.template.clothes.threeD !== null){
                state.template.clothes.threeD = [...state.template.clothes.threeD.slice(0, targetIndex), 
                    ...state.template.clothes.threeD.slice(targetIndex+1)]
            }

            // update selected color index
            state.template.selectedColorIndex = Math.max(0, state.template.selectedColorIndex-1);

            // delete cloth template entry
            state.templateClothChanges = [...state.templateClothChanges.slice(0, targetIndex),
                    ...state.templateClothChanges.slice(targetIndex+1)]

            // delete item from dresses
            if(!state.template.itemID) return;  // item has not been saved
            const dressIndex = state.dresses.findIndex((dress) => dress.itemID === state.template.itemID)
            if(dressIndex !== -1){
                if(state.dresses[dressIndex].clothes && state.dresses[dressIndex].clothes?.colors){
                    const targetColor = state.dresses[dressIndex].clothes?.colors;
                    if(targetColor){
                        if(targetColor.length > 0 && (targetIndex < targetColor.length)){
                            state.dresses[dressIndex].clothes?.colors?.splice(targetIndex, 1);
                        }
                    }
                }
            }
        }),
        setTemplateSavedState: ((state, action: PayloadAction<boolean>) => {
            state.template.saved = action.payload;
        }),
        resetTemplate: ((state, action: PayloadAction<boolean>) => {
            state.template = {...defaultTemplate};
            state.template.uuid = uuid();
            state.templateClothChanges = [];
        }),
        populateTemplate: ((state, action: PayloadAction<TemplateType>) => {
            state.template = JSON.parse(JSON.stringify(action.payload));
            state.template.uuid = uuid();
            state.templateClothChanges = [];

            // fix empty 3D item bug
            state.template.clothes.colors?.forEach((_, index) => {
                if(!state.template.clothes.threeD || !state.template.clothes.threeD[index]){
                    if(!state.template.clothes.threeD) state.template.clothes.threeD = [];
                    state.template.clothes.threeD.push({back:{url:""}, front:{url:""}, side:{url:""}})
                }
            })

            // reset template changes
            state.templateChanges = {
                categories: null, currency:null, description:null, mainMaterial: null,
                materials: null, name: null, price: null, tags:null,
            }

            //reset cloth changes for colors if present - set defaults for each color
            if(state.template.clothes.colors && state.template.clothes.colors.length > 0){
                state.template.clothes.colors.forEach(() => {
                    state.templateClothChanges.push({
                        colorName: null, colorValue: null, images: null, distanceFromCam:null, zoomLevel:null, 
                        setSameParameters:null, setSameMeasurements: null, parameters:null,
                        measurements: null, threeD:{front:null, side:null, back:null}
                    })
                })
            }
        }),
        //update templateChanges (set)
        setTemplateChange: (<K extends keyof TemplateChangeType>(state: UserState, action: PayloadAction<TemplateChangeUpdateType<K>>) => {
            const { key, value } = action.payload;
            state.templateChanges[key] = value
        }),
        //update templateChanges (remove)
        unsetTemplateChange: ((state: UserState, action: PayloadAction<keyof TemplateChangeType>) => {
            state.templateChanges[action.payload] = null;
        }),
        //add item display image
        addItemImage : ((state: UserState, action: PayloadAction<{itemIndex:number, fileName:string}>) => {
            if(!state.template.itemID) return;    // item has not been saved
            // update template
            if(state.template.clothes.colors){
                state.template.clothes.colors[action.payload.itemIndex].images.push({url:action.payload.fileName, display:false});
            }

            // update dresses
            const dressIndex = state.dresses.findIndex((dress) => dress.itemID === state.template.itemID);
            if(dressIndex === -1) return;

            if(state.dresses[dressIndex].clothes && state.dresses[dressIndex].clothes?.colors !== null){
                state.dresses[dressIndex].clothes?.colors![action.payload.itemIndex].images.push({url:action.payload.fileName, display:false})
            }
        }),
        //delete an item display image at a particular index
        deleteItemImage : ((state: UserState, action: PayloadAction<{itemIndex:number, imageIndex:number}>) => {
            if(!state.template.itemID) return;    // item has not been saved

            // update template
            if(state.template.clothes.colors){
                state.template.clothes.colors[action.payload.itemIndex].images.splice(action.payload.imageIndex,1)
            }

            // update dresses
            const dressIndex = state.dresses.findIndex((dress) => dress.itemID === state.template.itemID);
            if(dressIndex === -1) return;

            if(state.dresses[dressIndex].clothes && state.dresses[dressIndex].clothes?.colors !== null){
                state.dresses[dressIndex].clothes?.colors![action.payload.itemIndex].images.splice(action.payload.imageIndex,1)
            }
        }),
        // update a threeD side image (for now do same for all indices)
        addThreeDImage : ((state: UserState, action: PayloadAction<{itemIndex:number, fileName:string}>) => {
            if(!state.template.itemID) return;    // item has not been saved
            // update template
            if(state.template.clothes.threeD){
                state.template.clothes.threeD.forEach((obj, index) => {
                    switch (action.payload.itemIndex){
                        case 1:
                            state.template.clothes.threeD![index].front.url = action.payload.fileName
                            break
                        case 2:
                            state.template.clothes.threeD![index].side.url = action.payload.fileName
                            break
                        case 3:
                            state.template.clothes.threeD![index].back.url = action.payload.fileName
                            break
                    }
                })
            }

            // update dresses
            const dressIndex = state.dresses.findIndex((dress) => dress.itemID === state.template.itemID);
            if(dressIndex === -1) return;

            if(state.dresses[dressIndex].clothes && state.dresses[dressIndex].clothes?.threeD){
                state.dresses[dressIndex].clothes?.threeD!.forEach((dress) => {
                    switch (action.payload.itemIndex){
                        case 1:
                            dress.front.url = action.payload.fileName
                            break
                        case 2:
                            dress.side.url = action.payload.fileName
                            break
                        case 3:
                            dress.back.url = action.payload.fileName
                            break
                    }
                })
            }
        }),
        //delete an item 3D Image
        deleteThreeDImageItem : ((state: UserState, action: PayloadAction<{itemIndex:number, imageIndex:number}>) => {
            if(!state.template.itemID) return;    // item has not been saved

            // update template
            if(state.template.clothes.threeD){
                // for now do same for all colors
                state.template.clothes.threeD.forEach((cloth) => {
                    switch (action.payload.imageIndex){
                        case 1: cloth.front.url = "";
                            break;
                        case 2: cloth.side.url = "";
                            break;
                        case 3: cloth.back.url = "";
                    }
                })
            }

            // update dresses
            state.dresses.forEach((dress) => {
                if(dress.clothes?.threeD){
                    dress.clothes.threeD.forEach((cloth) => {
                        switch (action.payload.imageIndex){
                            case 1: cloth.front.url = "";
                                break;
                            case 2: cloth.side.url = "";
                                break;
                            case 3: cloth.back.url = "";
                        }
                    })
                }
            })
        }),

        /** image changes function */
        setTemplateClothChangesState: ((state: UserState, action: PayloadAction<boolean>) => {
            state.template.clothChanges = action.payload;
        }),
        //update templateChanges (set)
        setTemplateClothChange: (<K extends keyof TemplateClothChangeType>(state: UserState, 
                        action: PayloadAction<TemplateClothChangeUpdateType<K>>) => {
            const { key, value } = action.payload;
            state.templateClothChanges[state.template.selectedColorIndex][key] = value
        }),
        setTemplateClothChangeAtIndex: (<K extends keyof TemplateClothChangeType>(state: UserState, 
                        action: PayloadAction<{value:TemplateClothChangeUpdateType<K>, index: number}>) => {
            const { key, value } = action.payload.value;
            const index = action.payload.index;
            state.templateClothChanges[index][key] = value
        }),
        //update templateChanges (remove)
        unsetTemplateClothChange: ((state: UserState, action: PayloadAction<keyof TemplateClothChangeType>) => {
            if(action.payload !== "threeD"){
                state.templateClothChanges[state.template.selectedColorIndex][action.payload] = null;
            }else{
                if(state.template.clothes.colors){
                    state.template.clothes.colors.forEach((_, index) => {
                        state.templateClothChanges[index].threeD = {back: null, front:null, side:null}
                    })
                }
                
                /*Object.keys(state.templateClothChanges[state.template.selectedColorIndex].threeD).forEach((key) => {
                    type keyType = keyof TemplateClothChangeType["threeD"];
                    state.templateClothChanges[state.template.selectedColorIndex].threeD[key as keyType] = null;
                })*/
            }
        }),
        unsetTemplateClothChangeAtIndex: ((state: UserState, 
                    action: PayloadAction<{key: keyof TemplateClothChangeType, index: number}>) => {
            if(action.payload.key !== "threeD"){
                state.templateClothChanges[action.payload.index][action.payload.key] = null;
            }else{
                if(state.template.clothes.colors){
                    state.template.clothes.colors.forEach((_, index) => {
                        state.templateClothChanges[index].threeD = {back: null, front:null, side:null}
                    })
                }
            }
        }),

        //persist template changes to template after saving
        persistSavedChanges : ((state, action: PayloadAction<"saving"|"editing">) => {
            if(state.templateChanges.categories !== null){
                state.template.attributes.categories = state.templateChanges.categories
            }
            if(state.templateChanges.currency !== null){
                state.template.attributes.currency = state.templateChanges.currency;
            }
            if(state.templateChanges.description !== null){
                state.template.attributes.description = state.templateChanges.description;
            }
            if(state.templateChanges.mainMaterial !== null){
                state.template.attributes.mainMaterial = state.templateChanges.mainMaterial;
            }
            if(state.templateChanges.materials !== null){
                state.template.attributes.materials = JSON.parse(JSON.stringify([...state.templateChanges.materials]));
            }
            if(state.templateChanges.name !== null){
                state.template.attributes.name = state.templateChanges.name;
            }
            if(state.templateChanges.price !== null){
                state.template.attributes.price = state.templateChanges.price;
            }
            if(state.templateChanges.tags !== null){
                state.template.attributes.tags = [...state.templateChanges.tags];
            }
            state.template.attributes = JSON.parse(JSON.stringify(state.template.attributes));


            //persist image changes
            state.templateClothChanges.forEach((item, index) => {
                if(item.colorName !== null){
                    state.template.clothes.colors![index].colorName = item.colorName;
                }
                if(item.colorValue !== null){
                    state.template.clothes.colors![index].colorValue = item.colorValue;
                }
                if(item.distanceFromCam !== null){
                    state.template.clothes.colors![index].distanceFromCam = JSON.parse(JSON.stringify(item.distanceFromCam));
                }
                if(item.measurements !== null){
                    state.template.clothes.colors![index].measurements = JSON.parse(JSON.stringify(item.measurements));
                }
                if(item.parameters !== null){
                    state.template.clothes.colors![index].parameters = JSON.parse(JSON.stringify(item.parameters));
                }
                if(item.setSameMeasurements !== null){
                    state.template.clothes.colors![index].setSameMeasurements = item.setSameMeasurements;
                }
                if(item.setSameParameters !== null){
                    state.template.clothes.colors![index].setSameParameters = item.setSameParameters;
                }
                if(item.zoomLevel !== null){
                    state.template.clothes.colors![index].zoomLevel = item.zoomLevel;
                }

                // persist threeD data
                if(item.threeD !== null){
                    type K = keyof typeof item.threeD;
                    for (const [key, value] of Object.entries(item.threeD)) {
                        if(value !== null) {
                            state.template.clothes.threeD![index][key as K] = value;
                        }
                    }
                }
            })
            state.template.clothes = JSON.parse(JSON.stringify(state.template.clothes));

            // add to dresses id its a new item otherwise update dresses
            const newDress: DressType = {
                attributes: state.template.attributes,
                clothes: state.template.clothes,
                itemID: state.template.itemID,
                uuid: state.template.uuid
            }

            if(action.payload === "saving"){
                state.dresses.push(JSON.parse(JSON.stringify(newDress)));
            }else{
                const itemIndex = state.dresses.findIndex((dress) => dress.itemID === newDress.itemID);
                if(itemIndex !== -1){
                    state.dresses = [...state.dresses.slice(0, itemIndex), newDress, ...state.dresses.slice(itemIndex+1)];
                }
            }
            
        }),

        /** Dress Functions */
        deleteDressItem: ((state, action: PayloadAction<string>) => {
            const index  = state.dresses.findIndex(({uuid}) => uuid === action.payload);
            if(index === -1) return;
            state.dresses = [...state.dresses.slice(0, index), ...state.dresses.slice(index+1)];
        }),
        populateDresses: ((state, action: PayloadAction<DressType[]>) => {
            state.dresses = [...action.payload];
        })
    },
});

export const { 
    setUsername, setBrandName, setTemplateCategory, addColors, setSelectedColorTemplateColorIndex, deleteColorItem,
    deleteDressItem, setTemplate3DStatus, resetTemplate, setTemplateSavedState, setTemplateChange, unsetTemplateChange,
    setTemplateClothChange, unsetTemplateClothChange, setTemplateClothChangesState, unsetTemplateClothChangeAtIndex,
    setTemplateClothChangeAtIndex, persistSavedChanges, setTemplateItemID, setTemplateAttributeCreateDate, 
    populateTemplate, populateDresses, addItemImage, deleteItemImage, addThreeDImage, deleteThreeDImageItem
} = userSlice.actions;

export {defaultTemplate}

export type { 
    UnitType1, UnitType2, ParameterType, ClothesType, MeasurementsType, CurrencyType, MaterialType, ClothAttributes,
    TemplateType, TemplateChangeType, TemplateChangeUpdateType, TemplateClothChangeType, DressType
}
export default userSlice.reducer;
