import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";

import ColorPickerDialog, { SelectedColorType, colors } from "./ColorPickerDialog";
import ConfirmDialog, {ConfirmBoxParamType, defaultConfirmBoxParams} from "../../../../components/ConfirmDialog";

import {setSelectedColorTemplateColorIndex} from "../../../../redux/slices/userSlice";

import {addColors} from "../../../../redux/slices/userSlice";

import styles from "../../../../styles/Dashboard/CMS/ClothingForm.module.css";
import { useState } from "react";

const ClothingFormHeader: React.FC = () => {
    // confirm dialog
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmDialogParams] = useState<ConfirmBoxParamType>(defaultConfirmBoxParams);

    const { clothes, selectedColorIndex } = useSelector((state: RootState) => state.users.template);
    const dispatch = useDispatch();

    const [showColorPickerDialog, setShowColorPickerDialog] = useState(false);

    const isActiveIndex = (index: number) => {
        return index === selectedColorIndex;
    }

    // hide  the confirm dialog
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const hideConfirmDialog = () => {
        setShowConfirmDialog(false);
    }

    // showChanges confirm dialog

    const setActiveIndex = (index: number, discardChanges: boolean) => {
        /*if(clothChanges && !discardChanges){
            const params: ConfirmBoxParamType = {
                title: "Unsaved changes",
                message: `You have made some unsaved changes to this color. Save these changes before you continue or discard
                                or you can choose to discard the changes.`,
                negativeText: "Discard changes", positiveText: "Okay",
                onAccept: (msg) => {
                    hideConfirmDialog();
                },
                onDeny: (msg) => {
                    hideConfirmDialog();
                    setActiveIndex(index, true);
                }
            }
            setConfirmDialogParams({...params})
            setShowConfirmDialog(true);
            return;
        }*/
        dispatch(setSelectedColorTemplateColorIndex(index));
    }

    const displayColorDialog = () => {
        setShowColorPickerDialog(true);
    }

    const closeColorPickerDialog = () => {
        setShowColorPickerDialog(false);
    }

    const colorSelected = (selectedColorInfo: SelectedColorType) => {
        let newColors = selectedColorInfo.colors.filter((_, idx) => selectedColorInfo.selectedIndexes?.includes(idx));
        dispatch(addColors(newColors));
        closeColorPickerDialog();
    }

    const getSelectedColorIndexes = () :number[] => {
        if(!clothes.colors || clothes.colors.length === 0) return [];
        const selectedIndexes:number[] = [];
        clothes.colors.forEach(({colorValue}) => {
            const colorIndex = colors.findIndex(({value}) => value === colorValue);
            if(colorIndex >= 0) selectedIndexes.push(colorIndex)
        })
        return selectedIndexes;
    }


    return (
        <>
            {clothes.colors && clothes.colors.length > 0 &&
            <div className={styles.Main}>
                <div className={styles.ColorHeader}>
                    <div className={styles.items}>
                        {clothes.colors.map(({colorName, colorValue}, idx) => (
                            <div 
                                className={`${styles.item} ${isActiveIndex(idx)?styles.active:""}`} 
                                key={`${colorName}${idx}`} onClick={()=>setActiveIndex(idx, false)}
                            >
                                <div className={styles.color} style={{backgroundColor: colorValue}}></div>
                                <div className={styles.text}>{colorName}</div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.btnAdd} onClick={displayColorDialog}></div>
                </div>
            </div>}
            {showColorPickerDialog &&
            <ColorPickerDialog 
                onClose={closeColorPickerDialog} onSubmit={colorSelected} 
                selectedColorIndexes={getSelectedColorIndexes()}/>
            }
            {showConfirmDialog &&
            <ConfirmDialog {...confirmDialogParams}/>}
        </>
    )
}

export default ClothingFormHeader;