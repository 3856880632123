import { useState } from "react";

import styles from "../../../styles/Dashboard/Inputdialog.module.css";

type Props = {
    placeHolder: string,
    required: boolean,
    label: string,
    onCancel: () => void,
    onClose?: () => void,
    onSuccess: (value: string) => void,
    name?: string,
    type?: React.HTMLInputTypeAttribute,
    className?: string
}

const InputDialog = ({
        name = "text", type = "text", required = false, placeHolder, className, label, onCancel, onSuccess,
        onClose
    }: Props) => {

    let [focused, setFocused] = useState<boolean>(false);
    let [value, setValue] = useState<string>("")

    const handleFocusEvent = (focused:boolean) => {
        setFocused(focused);
    }

    const validate = () => {
        onSuccess(value);
    }

    const cancel = () => {
        onCancel();
    }

    const close  = () => {
        onClose ? onClose() : onCancel();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }

    return (
        <div className={styles.container}>
            <div className={styles.dialog}>
                <div className={styles.btnClose} onClick={close}></div>
                <div className={styles.label}>{label}</div>
                <div className={`${styles.inputBox} ${className} ${focused && styles.focus}`}>
                    <input 
                        type={type} name={name} placeholder={placeHolder} required={required}
                        onFocus={() => handleFocusEvent(true)} onBlur={() => handleFocusEvent(false)}
                        value={value} onChange={handleChange}
                    />
                </div>
                <div className={styles.actionBox}>
                    <div className={styles.btnCancel} onClick={cancel}>Cancel</div>
                    <div className={styles.btnContinue} onClick={validate}>Continue</div>
                </div>
            </div>
        </div>
    )
}

export default InputDialog;