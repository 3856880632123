import React from "react"

import CheckBox from "../../../../components/CheckBox";

import styles from "../../../../styles/Dashboard/CMS/UploadInfoDialog.module.css";

type Props = {
    onDoNotShow: (show: boolean) => void;
    onClose: () => void
    onContinue: () => void
}

const UploadInfoDialog: React.FC<Props> = ({onClose, onDoNotShow, onContinue}) => {

    const handleDoNotShow = (show: boolean) => {
        onDoNotShow(show);
    }

    const handleClose = () => {
        onClose();
    }

    const handleContinue = () => {
        onContinue();
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.btnClose} onClick={handleClose}></div>
                <div className={styles.header}>For the best results, please ensure the following:</div>
                <div className={styles.info}>
                    <ol start={1}>
                        <li>
                            <span>Well-Lighted Environment:</span> Ensure that the environment where you're photographing the clothes 
                            is well-lit. Proper lighting enhances the visibility and clarity of the clothing details.
                        </li>
                        <li>
                            <span>Shadow-Free Zone:</span> Avoid any shadows reflecting on the clothes. Shadows can obscure the details 
                            and affect the overall quality of the photos.
                        </li>
                        <li>
                            <span>Plain Background:</span> Opt for a background that is plain and clear. A simple background helps to 
                            focus attention on the clothes without distractions.
                        </li>
                    </ol>
                    <br />
                    <p>
                        By following these guidelines, you'll help our models easily segment the clothes and extract all the 
                        necessary details, ensuring the best presentation of your products.
                    </p>
                </div>
                <div className={styles.actionBox}>
                    <CheckBox 
                        className={styles.checkBox} label="Do not show this again" value="doNotShow"
                        onToggle={handleDoNotShow}
                    />
                    <div className={styles.btnContinue} onClick={handleContinue}>Continue</div>
                </div>
            </div>
        </div>
    )
}

export default  UploadInfoDialog;