import React, { useState } from "react";

import styles from "../../styles/SignUp.module.css";

import SignUpForm from "../../components/SignUpForm"
import AlertDialog from "../../components/AlertDialog";
import { useNavigate } from "react-router-dom";

import { paths } from "../MainRouter"


type DialogProps = {
    onClose: (status:boolean, type:"close"|"back") => void;
    onBack: (status:boolean, type:"close"|"back") => void;
}
const SuccessDialog:React.FC<DialogProps> = ({onBack, onClose}) => {
    const title = "Congratulations";
    const info = `Your info has been sent to our management team. You will receive an email from where we'll proceed with
                    the partnership agreement.`;
    
    return (
        <div className={styles.dialogBox}>
            <div className={styles.dialog}>
                <div className={styles.btnClose} onClick={() => onClose(false, "close")}></div>
                <div className={styles.title}>{title}</div>
                <div className={styles.info}>{info}</div>
                <div className={styles.btnBack2} onClick={() => onClose(false, "back")}>Back to browsing</div>
            </div>
        </div>
    )
}

const SignUp = () => {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dialogTitle, setDialogTitle] = useState("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dialogMessage, setDialogMessage] = useState("")

    const displayDialog = (display: boolean) =>{
        setShowDialog(display);
    }

    /*const showTempDialog = () => {
        setDialogTitle("Welcome Onboard!");
        setDialogMessage("We are tyding up a few things we'll be back soon, please bare with us and check back later");
        setShowAlertDialog(true);
    }*/

    const handleTmpDialogClosed = () => {
        setShowAlertDialog(false);
    }

    // handle closing of success dialog
    const onCloseSuccessDialog = (status: boolean, type: "back"|"close") => {
        displayDialog(false);
    }



    // trigger tmp message
    /*useEffect(() => {
        setTimeout(() => {
            showTempDialog();
        }, 3000)
    }, [])*/

    return (
        <div className={styles.container}>
            <div className={styles.btnBack} onClick={() => navigate(paths.login)}></div>
            <div className={styles.leftBox}>
                <div className={styles.leftImage}></div>
                <div className={styles.appName}>Hologlam</div>
            </div>
            <div className={styles.rightBox}>
                <SignUpForm showDialog={displayDialog} className={styles.form}/>
                {showAlertDialog && 
                    <AlertDialog message={dialogMessage}  title={dialogTitle} onDialogClose={handleTmpDialogClosed}/>
                }
            </div>
            {showDialog &&
                <SuccessDialog onBack={onCloseSuccessDialog} onClose={onCloseSuccessDialog}/>
            }
        </div>
    );
}

export default SignUp;