import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    setTemplateCategory, addColors, deleteColorItem, setTemplate3DStatus, setTemplateChange, unsetTemplateChange,
    setTemplateClothChange, unsetTemplateClothChange, unsetTemplateClothChangeAtIndex, addItemImage, deleteItemImage,
    addThreeDImage as persist3DImage, deleteThreeDImageItem as persistDelete3DImage
} from "../../../../redux/slices/userSlice";
import { 
    ClothesType, UnitType1, UnitType2, ParameterType, MeasurementsType , CurrencyType, MaterialType
} from "../../../../redux/slices/userSlice";
import { 
    notifyUserExitingFromItemForm, setHomeTooltipInfo, triggerTemplateSave, setThreeDAvailable, setShowUploadInstructions
} from "../../../../redux/slices/homeSlice";

import { RootState } from "../../../../redux/store";
import { getLocalStorageItem, scaleImage, imageFetcher } from "../../../../utils";
import axios, {  } from 'axios';

import styles from "../../../../styles/Dashboard/CMS/AddItemForm.module.css";

// components
import CheckBox, { CheckBoxRefType } from "../../../../components/CheckBox";
import { UnitInputBox } from "./CustomInputs";
import ClothingFormHeader from "./ClothingFormHeader";
import UploadInfoDialog from "./UploadInfoDialog";
import AddItemDialog from "../../DashBoard/CMS/AddItemDialog";
import ColorPickerDialog, {SelectedColorType} from "./ColorPickerDialog";
import ConfirmDialog, { ConfirmBoxParamType, defaultConfirmBoxParams } from "../../../../components/ConfirmDialog";
import ImageChooserDialog from "../../../../components/ImageChooserDialog";

// types
import { CategoryDialogResponse } from "../../DashBoard/CMS/AddItemDialog";
import { categoryItems } from "./AddItemDialog";
import { SelectItemType } from "../../../../components/MultiSelect";

import { sortArrayByValue } from "../../../../utils"
import AlertDialog from "../../../../components/AlertDialog";
import LoaderDialog from "../../../../components/LoaderDialog";

interface AddImageResponseType {
    itemId: number, fileName: string
}

interface HeaderProp { categoryData: null|CategoryDialogResponse }

const DELETE_COLOR_ITEM_URL = process.env.REACT_APP_DELETE_COLOR_ITEM_URL;
const UPLOAD_IMAGE_URL = process.env.REACT_APP_UPLOAD_IMAGE_URL;
const DELETE_IMAGE_URL = process.env.REACT_APP_DELETE_IMAGE_URL;
const DELETE_ITEM_TIMEOUT_DURATION = 60 * 1000; // 60 seconds in milliseconds
const UPLOAD_IMAGE_TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

/*---- AddItemForm (Main) ----*/
const AddItemForm: React.FC = () => {
    const {template, templateChanges, templateClothChanges, dresses} = useSelector((state: RootState) => state.users);
    const [sizeBoxParams, setSizeBoxParams] = useState<SizeParamType>(defaultSizeParamsValue);
    const [measurementBoxParams, setMeasurements] = useState<MeasurementBoxType>(defaultSizeBoxValues);
    const [, setNameDescBoxParams] = useState<NameDescBoxValues>(defaultNameDescBoxValues);
    const [, setPriceBoxParams] = useState<PriceBoxValueType>(defaultPriceBoxValues);
    const [, setMaterialBoxParams] = useState<MaterialBoxType>(defaultMaterials);
    const [, setTags] = useState<string[]>([]);

    // track changes for submit buttons
    const [sizesChanged, setSizesChanged] = useState(false);
    const [measurementsChanged, setMeasurementsChanged] = useState(false);
    const [zoomDistChange, setZoomDistChange] = useState(false);

    const dispatch = useDispatch();

    const [showColorPickerDialog, setShowColorPickerDialog] = useState(false);

    // handle colors selected
    const handleColorSelected = (selectedColorInfo: SelectedColorType) => {
        dispatch(addColors(selectedColorInfo.colors.filter((_, idx) => selectedColorInfo.selectedIndexes?.includes(idx))))
        setShowColorPickerDialog(false);
    }

    // check if there is at least one color 
    const colorAvailable = () => {
        if (!template.clothes.colors || template.clothes.colors.length === 0) return false;
        return true;
    }

    // track category changes
    useEffect(() => {
        if(JSON.stringify(template.attributes.categories) === JSON.stringify(templateChanges.categories)){
            dispatch(unsetTemplateChange("categories"));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.attributes.categories, templateChanges.categories])

    // watch saved changes and check all color indexes to ensure if template and template
    // changes are same, nullify the template changes
    useEffect(() => {
        if(template.clothes.colors && template.clothes.colors.length > 0){
            template.clothes.colors.forEach((cloth, index) => {
                if(templateClothChanges[index]){
                    if(templateClothChanges[index].colorName === cloth.colorName){
                        dispatch(unsetTemplateClothChangeAtIndex({key:"colorName", index}));
                    }
                    if(templateClothChanges[index].colorValue === cloth.colorValue){
                        dispatch(unsetTemplateClothChangeAtIndex({key:"colorValue", index}));
                    }
                    if(JSON.stringify(templateClothChanges[index].measurements) === JSON.stringify(cloth.measurements)) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"measurements", index}));
                    }
                    if(JSON.stringify(templateClothChanges[index].parameters) === JSON.stringify(cloth.parameters)) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"parameters", index}));
                    }
                    if(JSON.stringify(templateClothChanges[index].distanceFromCam) === JSON.stringify(cloth.distanceFromCam)) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"distanceFromCam", index}));
                    }
                    if(JSON.stringify(templateClothChanges[index].images) === JSON.stringify(cloth.images)) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"images", index}));
                    }
                    if(templateClothChanges[index].setSameMeasurements === cloth.setSameMeasurements) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"setSameMeasurements", index}));
                    }
                    if(templateClothChanges[index].setSameParameters === cloth.setSameParameters) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"setSameParameters", index}));
                    }
                    if(JSON.stringify(templateClothChanges[index].zoomLevel) === JSON.stringify(cloth.zoomLevel)) {
                        dispatch(unsetTemplateClothChangeAtIndex({key:"zoomLevel", index}));
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dresses])

    // get notified if either distance or zoom is changed for sizing component submit button
    const distanceOrZoomChanged = useCallback((changed: boolean) => {
        setZoomDistChange(changed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.clothes.colors, template.selectedColorIndex])

    // Receive size params update
    const onSizeParamBoxUpdated = useCallback((values: SizeParamType) => {
        setSizeBoxParams(values);
        if(!template.clothes.colors) return;

        let changes = false;
        // parameters
        const templateValues = template.clothes.colors![template.selectedColorIndex];
        if(JSON.stringify(templateValues.parameters) !== JSON.stringify(values.parameters)){
            dispatch(setTemplateClothChange({key:"parameters", value:{...values.parameters}}))
            changes ||= true;
        }else{
            dispatch(unsetTemplateClothChange("parameters"));
            changes ||= false;
        }

        // apply To All clothes
        if(templateValues.setSameParameters !== values.applyToAllColors){
            dispatch(setTemplateClothChange({key:"setSameParameters", value:values.applyToAllColors}));
            changes ||= true;
        }else{
            dispatch(unsetTemplateClothChange("setSameParameters"));   
            changes ||= false;  
        }

        setSizesChanged(changes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.clothes.colors, template.selectedColorIndex])

    // Receive cloth measurements update
    const  onMeasurementsBoxUpdated = useCallback((values: MeasurementBoxType) => {
        setMeasurements(values);
        if(!template.clothes.colors) return;

        let changes = false;
        // measurements
        const templateValues = template.clothes.colors![template.selectedColorIndex];
        if(JSON.stringify(templateValues.measurements) !== JSON.stringify(values.measurements)){
            dispatch(setTemplateClothChange({key:"measurements", value:{...values.measurements}}))
            changes ||= true;  
        }else{
            dispatch(unsetTemplateClothChange("measurements"));
            changes ||= false;  
        }

        // apply To All clothes
        if(templateValues.setSameMeasurements !== values.applyToAllColors){
            dispatch(setTemplateClothChange({key:"setSameMeasurements", value:values.applyToAllColors}));
            changes ||= true;  
        }else{
            dispatch(unsetTemplateClothChange("setSameMeasurements"));     
            changes ||= false;  
        }

        setMeasurementsChanged(changes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.clothes.colors, template.selectedColorIndex])

    // Receive name and description update
    const onDescBoxUpdated = useCallback((value: NameDescBoxValues) => {
        setNameDescBoxParams(value);
        // name
        if(template.attributes.name !== value.name){
            dispatch(setTemplateChange({key:"name", value: value.name}))
        }else{
            dispatch(unsetTemplateChange("name"))
        }

        // description
        if(template.attributes.description !== value.description){
            dispatch(setTemplateChange({key:"description", value: value.description}))
        }else{
            dispatch(unsetTemplateChange("description"))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.attributes])

    // Receive price and currency update
    const onPriceBoxUpdate = useCallback((values: PriceBoxValueType) => {
        setPriceBoxParams(values);
        if(template.attributes.price !== Number(values.price)){
            dispatch(setTemplateChange({key:"price", value:Number(values.price)}))
        }else{
            dispatch(setTemplateChange({key:"price", value: null}))
        }

        if(template.attributes.currency !== values.currency){
            dispatch(setTemplateChange({key:"currency", value:values.currency}))
        }else{
            dispatch(setTemplateChange({key:"currency", value: null}))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.attributes])

    // Receive main material and material details update
    const onMaterialBoxUpdate = useCallback((value: MaterialBoxType) => {
        setMaterialBoxParams(value);
        if(template.attributes.mainMaterial !== value.mainMaterial){
            dispatch(setTemplateChange({key:"mainMaterial", value: value.mainMaterial}))
        }else{
            dispatch(setTemplateChange({key:"mainMaterial", value: null}))
        }

        // other materials
        if(JSON.stringify(value.material) !== JSON.stringify(template.attributes.materials)){
            dispatch(setTemplateChange({key:"materials", value:[...value.material]}));
        }else{
            dispatch(setTemplateChange({key:"materials", value: null}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.attributes]) 

    // Receive tags update
    const onTagsUpdate = useCallback((tags: string[]) => {
        setTags([...tags]);
        if(JSON.stringify(tags) !== JSON.stringify(template.attributes.tags)){
            dispatch(setTemplateChange({key:"tags", value: [...tags]}))
        }else{
            dispatch(setTemplateChange({key:"tags", value: null}))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template.attributes]);

    // handle the bottom save button click
    const handleSaveButtonClicked = () => {
        if(template.saved){
            return dispatch(setHomeTooltipInfo({show:true, status:"info", text:"All changes have already been saved"}))
        }
        dispatch(triggerTemplateSave());
    }

    return (
        <>
            {template.category && 
            <div className={styles.Main}>
                <div className={styles.form}>
                    <Header categoryData={template.category} />
                    
                    {(!template.clothes.colors || (template.clothes.colors.length === 0)) &&
                    <div className={styles.btnSelectColor}>
                        <div className={styles.txt}>Select color variations</div>
                        <div className={styles.icon} onClick={()=>setShowColorPickerDialog(true)}></div>
                    </div>}
                    
                    <ClothingFormHeader />
                    {colorAvailable() && 
                    <ImageBox 
                        sizeParams={sizeBoxParams} measurementParams={measurementBoxParams} 
                        onDistanceAndZoomChanged={distanceOrZoomChanged}
                    />}
                    {colorAvailable() &&
                    <SizeParamsBox onUpdate={onSizeParamBoxUpdated} changes={sizesChanged||zoomDistChange}/>}
                    {colorAvailable() &&
                    <SeizingBox onUpdate={onMeasurementsBoxUpdated} changes={measurementsChanged}/>}
                    <NameDescBox onUpdate={onDescBoxUpdated}/>
                    <PriceBox onUpdate={onPriceBoxUpdate}/>
                    <MaterialBox onUpdate={onMaterialBoxUpdate}/>
                    <TagsBox onUpdate={onTagsUpdate}/>
                    <div className={styles.actionBox}>
                        <div className={styles.btnBack} onClick={()=>dispatch(notifyUserExitingFromItemForm(true))}>Back</div>
                        <div className={`${styles.btnSubmit} ${template.saved?styles.saved:""}`}
                            onClick={handleSaveButtonClicked}>Submit</div>
                    </div>
                </div>
            </div>}
            {showColorPickerDialog &&
            <ColorPickerDialog onClose={()=>setShowColorPickerDialog(false)} onSubmit={handleColorSelected}/>
            }
        </>
        
    )
}


/*---- Header ----*/
const Header: React.FC<HeaderProp> = ({categoryData}) => {
    const [showItemDialog, setShowItemDialog] = useState(false);

    const {template} = useSelector((state: RootState) => state.users);
    const {addingNewItemState, threeDClothAvailable} = useSelector((state: RootState) => state.home);
    const dispatch = useDispatch();

    const onDialogClosed = () => {
        setShowItemDialog(false);
    }

    // track category changes
    useEffect(() => {
        let categoryArray = []

        if(!categoryData || categoryData.category.selectIndex < 0){
            categoryArray.push([]);
        }else if(!categoryData.category.selectStack){
            categoryArray.push([categoryItems[categoryData.category.selectIndex].label]);
        }else{
            let categories = []
            let currentCategoryItem: SelectItemType[] = [];
            categoryData.category.selectStack.forEach((index) => {
                categories.push(categoryItems[index].label);
                currentCategoryItem = categoryItems[index].items as SelectItemType[]
            })
            categories.push(currentCategoryItem[categoryData.category.selectIndex].label)
            categoryArray.push(categories);
        }
        dispatch(setTemplateChange({key:"categories", value:categoryArray}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryData])

    const onCategorySelected = (category: null|CategoryDialogResponse) => {
        if(category === null) return;
        dispatch(setTemplateCategory(category));
        onDialogClosed();
    }

    const getCategoryText = () => {
        if(addingNewItemState.action === "editing"){
            let categoryArray = template.attributes.categories;
            if(!categoryArray || categoryArray.length === 0 || categoryArray[0].length === 0) return "";

            let txt = ""
            categoryArray[0].forEach((label, index) => {
                label = label.charAt(0).toUpperCase() + label.slice(1);
                txt += (index === 0)? label : ` > ${label}`;
            })
            return txt;
        }
        if(!categoryData || categoryData.category.selectIndex < 0) return "";

        if(!categoryData.category.selectStack){
            return categoryItems[categoryData.category.selectIndex].label;
        }

        var txt = "";
        var currentCategoryItem: SelectItemType[] = [];

        categoryData.category.selectStack.forEach((index) => {
            txt += categoryItems[index].label + " > ";
            currentCategoryItem = categoryItems[index].items as SelectItemType[]
        })
        txt += currentCategoryItem[categoryData.category.selectIndex].label;
        return txt;
    }

    // add 3D params
    const add3D = () => {
        dispatch(setTemplate3DStatus("yes"));
    }

    return (
        <>
            <div className={styles.Header}>
                <div className={styles.categoryBox}>
                    {template.category && (!(template.category.threeD === "yes") && !threeDClothAvailable) &&
                    <div className={styles.btnAdd3D} onClick={()=>add3D()}>Add 3D clothing</div>}
                    <div className={styles.bottom}>
                        <div className={styles.category}>Category</div>
                        <div className={styles.categoryPath}>{getCategoryText()}</div>
                        <div className={styles.btnChange} title="Change category" onClick={()=>setShowItemDialog(true)}></div>
                    </div>
                </div>
            </div>
            {showItemDialog &&
                <AddItemDialog 
                    onDialogClose={onDialogClosed} onCategorySelected={onCategorySelected} 
                    categoryInfo={template.category?template.category:undefined}
                /> 
            }       
        </>
    )
}


/*---- ImageBox -----*/
type ImageBoxProps = {
    sizeParams: SizeParamType;
    measurementParams: MeasurementBoxType;
    onDistanceAndZoomChanged?:(changed: boolean) => void
}
type formValuetype = {
    distanceChanges: boolean, zoomLevelChanged: boolean,
    distance: {value: string, unit: UnitType1},
    zoomLevel: string,
    parameters: ParameterType,
    measurements: MeasurementsType,
    sameParamsForAllClothes: boolean,  // true if applying cloth parameters to all color variation
    sameMeasurementsForAllSizes: boolean,  // true if applying same measurements for all sizes
}
const defaultFormValues: formValuetype = {
    distanceChanges: false,
    zoomLevelChanged: false,
    distance: {unit: "cm", value:"0"},
    zoomLevel: "0",
    parameters:{XXXS:0, XXS:0, XS:0, S:0, M:0, L:0, XL:0, XXL:0, XXXL:0, "4XL":0},
    measurements: {shoulder: 0, sleeve: 0, chest: 0, waist: 0, back: 0, unit: "inch"},
    sameParamsForAllClothes: false,
    sameMeasurementsForAllSizes: false,
}

const ImageBox: React.FC<ImageBoxProps> = React.memo(forwardRef(({sizeParams, measurementParams, onDistanceAndZoomChanged}, ref) => {
    // Global state
    const { category, selectedColorIndex, clothes } = useSelector((state: RootState) => state.users.template);
    const { template,templateClothChanges, dresses } = useSelector((state: RootState) => state.users);
    const { threeDClothAvailable, showUploadInstruction } = useSelector((state: RootState) => state.home);

    const dispatch = useDispatch();

    const defaults: ClothesType = clothes;
    const [values] = useState<ClothesType>(defaults);
    const [formValues, setFormValue] = useState<formValuetype>(defaultFormValues);  // manages updates
    const [activeDistUnitIndex, setActiveDistUnitIndex] = useState(0);
    const [isMounted, setIsMounted] = useState(false);

    // focus states
    const [distanceInputFocused, setDistanceInputFocused] = useState(false);
    const [zoomInputFocused, setZoomInputFocused] = useState(false);

    // alert and loader dialog
    const [showAlertDialog, setShowAlertDialog] = useState(false)
    const [alertDialogText, setAlertDialogText] = useState("")
    const [alertDialogTitle, setAlertDialogTitle] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [loaderMsg, setLoaderMsg] = useState("");

    // confirm dialog
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmBoxParamType>(defaultConfirmBoxParams)

    // upload info dialog
    const [doNotShowUploadDialog, setDoNotShowDialog] = useState(false);
    const [showUploadInfoDialog, setShowUploadInfoDialog] = useState(false);
    const [uploadInfoDialogCallback, setUploadInfoDialogCallback] = useState<null|(() => void)>(null)

    // distance and zoom states for submit button
    const [distanceChanged, setDistanceChanged] = useState(false)
    const [zoomChanged, setZoomChanged] = useState(false)

    // image chooser state
    const [showImageChooser, setShowImageChooser] = useState(false);

    // image upload states
    const [imageUploadType, setImageUploadType] = useState<"display"|"threeD">("display");
    const [imageUploadAction, setImageUploadAction] = useState<"Add"|"Edit"|"Delete">("Add");
    const [imageUploadCount, setImageUploadCount] = useState<number>(-1);

    // images
    const [images, setImages] = useState<Map<string, string>>(new Map<string, string>());
    const [threeDURLs] = useState(["", "", ""])


    const MAX_IMAGE_COUNT = 3;  //Max number of images

    const threeDDefaults = useMemo(() => [
        {bgName: "3DFront.png", label:"Front", url:threeDURLs[0], itemIndex: 1}, 
        {bgName: "3DSide.png",  label:"Side", url:threeDURLs[1], itemIndex: 2}, 
        {bgName: "3DBack.png",  label:"Back", url:threeDURLs[2], itemIndex: 3}
    ], [threeDURLs])

    const units: UnitType1[] = useMemo(() => ["cm", "inch", "ft"], []);

    // Expose some actions to the parent component
    useImperativeHandle(ref, () => ({
        getValues: () => values, // method to get all values from this component
    }));

    // update form values
    const updateFormValue = <K extends keyof formValuetype>(key: K, value: formValuetype[K]) => {
        setFormValue((values) => ({...values, [key]:value}));
    }

    // check if there is a cloth template for this index
    const isTemplateDistanceAvailable = () => {
        return templateClothChanges[selectedColorIndex].distanceFromCam !== null;
    }

    // check if there is a cloth template for this index
    const isTemplateZoomLevelAvailable = () => {
        return templateClothChanges[selectedColorIndex].zoomLevel !== null;
    }

    // get template params
    const getTemplateDistanceValues = () => {
        return {
            distance: templateClothChanges[selectedColorIndex].distanceFromCam,
        }
    }

    const getTemplateZoomLevelValues = () => {
        return {
            zoomLevel: templateClothChanges[selectedColorIndex].zoomLevel
        }
    }

    // check if 3D cloth is available and set the global state
    useEffect(() => {
        if(template.clothes.threeD && template.clothes.threeD.some((cloth,index) => 
            (index === selectedColorIndex ) && (cloth.front.url || cloth.side.url || cloth.back.url)
        )){
            dispatch(setThreeDAvailable(true));
        }else{
            dispatch(setThreeDAvailable(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // track component mounted state
    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    // track distance unit index changes (this line should always appear before the line below)
    useEffect(() => {
        updateFormValue("distance", {...formValues.distance, unit:units[activeDistUnitIndex]});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDistUnitIndex])

    // set up initial value
    useEffect(() => {
        const clothes = getClothes();
        const dist = clothes.distanceFromCam;
        let clothUnit = clothes.distanceFromCam.unit;

        if(isTemplateDistanceAvailable()){
            // update distance values from template
            updateFormValue("distance", {...getTemplateDistanceValues().distance!, 
                                        value:String(getTemplateDistanceValues().distance!.value)});
            clothUnit = getTemplateDistanceValues().distance!.unit;
        }else{
            updateFormValue("distance", {unit:dist.unit, value:String(dist.value)})
        }

        if(isTemplateZoomLevelAvailable()){
            // update zoom values from template
            updateFormValue("zoomLevel", String(getTemplateZoomLevelValues().zoomLevel));
        }else{
            updateFormValue("zoomLevel", String(clothes.zoomLevel));
        }

        units.forEach((unit, idx) => {
            if(unit === clothUnit) setActiveDistUnitIndex(idx);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColorIndex])

    // watch threeD changes - set to null any time dresses are updated
    useEffect(() => {
        if(template.clothes.colors){
            dispatch(unsetTemplateClothChange("threeD"))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dresses])

    // watch for distance and zoom level change
    useEffect(() => {
        if(!template.clothes.colors) return;

        // distance from cam
        const templateValues = template.clothes.colors![template.selectedColorIndex];
        if(JSON.stringify(templateValues.distanceFromCam) !== 
                        JSON.stringify({...formValues.distance, value:Number(formValues.distance.value)})){
            dispatch(setTemplateClothChange({key:"distanceFromCam", 
                        value:{...formValues.distance, value:Number(formValues.distance.value)}}))
            setDistanceChanged(true)
        }else{
            dispatch(unsetTemplateClothChange("distanceFromCam"))
            setDistanceChanged(false)
        }

        // zoom level
        if(templateValues.zoomLevel !== Number(formValues.zoomLevel)){
            dispatch(setTemplateClothChange({key:"zoomLevel", value:Number(formValues.zoomLevel)}));
            setZoomChanged(true);
        }else{
            dispatch(unsetTemplateClothChange("zoomLevel"));    
            setZoomChanged(false) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.distance, formValues.zoomLevel, dresses])

    // update parent if distance and or zoom have changes (for submit button)
    useEffect(() => {
        if(onDistanceAndZoomChanged) onDistanceAndZoomChanged(distanceChanged||zoomChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distanceChanged, zoomChanged])

    // set size params changes
    useEffect(() => {
        updateFormValue("parameters", {...sizeParams.parameters});
        updateFormValue("sameParamsForAllClothes", sizeParams.applyToAllColors);
    }, [sizeParams])

    // set measurement changes
    useEffect(() => {
        updateFormValue("measurements", {...measurementParams.measurements});
        updateFormValue("sameMeasurementsForAllSizes", measurementParams.applyToAllColors);
    }, [measurementParams])

    // set up 3D
    useEffect(() => {
        let threeDClothes = clothes.threeD![selectedColorIndex];
        threeDDefaults[0].url = threeDClothes.front.url;  // front
        threeDDefaults[1].url = threeDClothes.side.url;  // side
        threeDDefaults[2].url = threeDClothes.back.url;  // back
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clothes.threeD, selectedColorIndex]);

    // show confirm dialog when a user tries to delete a color
    const showColorDeleteConfirmBox = (colorName: string, colorValue: string) => {
        setConfirmDialogProps({
            title: `Confirm action`,
            message: `Are you sure you want to delete the color ${colorName.toUpperCase()} and all 
                images associated with it?`,
            negativeText: "No",
            positiveText: "Yes",
            onAccept: () => {
                deleteColor(colorValue);
                setShowConfirmDialog(false)
            },
            onDeny: () => {
                setShowConfirmDialog(false)
            }
        })
        setShowConfirmDialog(true);
    }

    // hide image chooser
    const hideImageChooser = () => {
        setShowImageChooser(false);
    }

    // display upload info dialog
    const displayUploadInfoDialog = () => {
        setShowUploadInfoDialog(true);
    }

    // hide upload info dialog
    const hideUploadInfoDialog = (cont = false) => {
        setShowUploadInfoDialog(false)
        if(cont) {
            uploadInfoDialogCallback && uploadInfoDialogCallback();
        }
    }

    // check if upload info dialog should be displayed
    const shouldDisplayUploadInfoDialog = () => {
        if(!showUploadInstruction) return false;
        return !doNotShowUploadDialog
    }
    
    // show confirm dialog when user tries to remove a #D option
    const showRemove3DConfirmBox = () => {
        setConfirmDialogProps({
            title: `Confirm action`,
            message: `Are you sure you want to delete all 3D images associated with this cloth? Action cannot be undone!`,
            negativeText: "No",
            positiveText: "Yes",
            onAccept: () => {
                handleRemove3DClothing(true);
                setShowConfirmDialog(false)
            },
            onDeny: () => {
                setShowConfirmDialog(false)
            }
        })
        setShowConfirmDialog(true);
    }

    // hide Alert Dialog
    const hideAlertDialog = () => {
        setShowAlertDialog(false);
    }

    // session error alert
    const showSessionExpiredAlert = () => {
        setAlertDialogTitle("Session Expired");
        setAlertDialogText("Sorry your session has expired, refresh your browser and try again.");
        setShowAlertDialog(true);
    }

    // timed out alert
    const showTimedOutAlert = () => {
        setAlertDialogTitle("Request timed out");
        setAlertDialogText("This request is taking too long. please check your internet connection and try again.");
        setShowAlertDialog(true);
    }

    // request error alert
    const showRequestErrorAlert = () => {
        setAlertDialogTitle("");
        setAlertDialogText("We encountered an error while processing this request. Please try again.");
        setShowAlertDialog(true);
    }

    // internal error alert
    const showInternalErrorAlert = () => {
        setAlertDialogTitle("Internal error");
        setAlertDialogText(`We encountered an internal error processing this request 
                Please try again later or contact us if error persists.`);
        setShowAlertDialog(true);
    }

    // not found alert
    const showNotFoundErrorAlert = () => {
        setAlertDialogTitle("Not found");
        setAlertDialogText(`Sorry we couldn't find the item you are trying to delete this color item, 
                please refresh this page and try again.`);
        setShowAlertDialog(true);
    }

    // image not recognized alert
    const showInvalidFileErrorAlert = () => {
        setAlertDialogTitle("Invalid file");
        setAlertDialogText(`Sorry we don't seem to recognize the file you are trying to upload. Ensure that the file is a , 
                valid image file (PNG or JPEG).`);
        setShowAlertDialog(true);
    }

    // image not found alert
    const showImageItemNotFoundErrorAlert = () => {
        setAlertDialogTitle("Item Not found");
        setAlertDialogText(`Sorry we couldn't find the item for which you are trying to add this image to, 
                please refresh this page and try again.`);
        setShowAlertDialog(true);
    }

    // param validation error
    const showParamValidationErrorAlert = () => {
        setAlertDialogTitle("Request Error");
        setAlertDialogText(`You supplied invalid parameters for this request, please check to ensure your connection is secure, 
                reload your browser and try again.`);
        setShowAlertDialog(true);
    }

    // image limit reached
    const showMaxDisplayImageErrorAlert = () => {
        setAlertDialogTitle("Display image limit reached");
        setAlertDialogText(`Sorry you can only upload a maximum of 3 display images for this color.`);
        setShowAlertDialog(true);
    }

    // unsaved changes dialog
    const showUnsavedChangesDialog = () => {
        setAlertDialogTitle("Unsaved changes spotted!");
        setAlertDialogText(`You have some unsaved changes, you'll need to save these changes before 
                                adding a new display image.`);
        setShowAlertDialog(true);
    }

    // hide loader
    const hideLoader = () => {
        setShowLoader(false);
    }

    // display loader
    const displayLoader = (msg: string) => {
        setLoaderMsg(msg);
        setShowLoader(true);
    }

    // Get number of image uploaded by for this item
    const getNumberOfImages = () => {
        return getClothes().images.length;
    }

    // get the active unit for distance parameter
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getActiveUnit = () => {
        return units[activeDistUnitIndex];
    }

    // cont swipe to the next unit
    const swipeUnit = () => {
        setActiveDistUnitIndex((idx) => (idx+1)%units.length)
    }

    // return an image for image names in public/image directory
    const getImageURL = (name: string, index: number) => {
        let fileName = "";
        fileName = getImageBackGround(name, true);
        const path = `url(${fileName})`;
        //const path = `url(${process.env.PUBLIC_URL}/images/${name})`;
        return fileName? path : "";
    }

    // get threeD background
    const getThreeDBackground = (url: string, imageAvailable: boolean, index: number) => {
        return imageAvailable? getImageURL(url, index): `url(${require(`../../../../images/${url}`)})`
        //getImageBackGround()
    }

    const handleDoNotShowDialog = (show: boolean) => {
        if(!show) {
            setShowUploadInfoDialog(false);
            dispatch(setShowUploadInstructions(false));
        }
        setDoNotShowDialog(show)
    }

    // handle color removal
    const handleRemove = () => {
        if(!clothes.colors) return;
        const {colorName, colorValue} = clothes.colors[selectedColorIndex];
        showColorDeleteConfirmBox(colorName, colorValue);
    }

    // handle 3D clothing removal option
    const handleRemove3DClothing = (confirmed: boolean) => {
        if(!confirmed) return showRemove3DConfirmBox();

        if(category?.threeD){
            dispatch(setTemplate3DStatus("no"));
        }
    }

    // check if there is at least one color 
    const colorAvailable = () => {
        if (!clothes.colors || clothes.colors.length === 0) return false;
        return true;
    }

    // return the active color item
    const getActiveColorItem = () => {
        if(!colorAvailable()) return null;
        if(clothes.colors === null) return null;
        return clothes.colors[selectedColorIndex]
    }

    // return clothes
    const getClothes = () => {
        return clothes.colors![selectedColorIndex];
    }

    // handle distance changes
    const handleDistanceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(value)) {
            // Remove leading zeros if the number is not just a zero or decimal like 0.x
            value = value.replace(/^(-?)0+(0\.|\d)/, '$1$2');
            value = value || "0";
            updateFormValue("distance", {unit:formValues.distance.unit, value})
        }
    }

    // handle zoom level changed
    const handleZoomLevelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (/^-?\d*\.?\d*$/.test(value)) {
            // Remove leading zeros if the number is not just a zero or decimal like 0.x
            value = value.replace(/^(-?)0+(0\.|\d)/, '$1$2');
            value = value || "0";
            updateFormValue("zoomLevel", value)
        }
    }

    // handle delete color item
    const deleteColor = async (colorValue: string) => {
        // find index
        if(!(template.clothes.colors) || !(template.clothes.colors.length > 0)) return;
        const index = template.clothes.colors.findIndex((cloth) => 
                            cloth.colorValue.toLowerCase() === colorValue.toLowerCase());
        if(index === -1) return;

        //imageIndex: number, modelIndex: number
        const accessToken = getLocalStorageItem("refreshToken")

        const headers = {
            'Content-Type': 'application/json', Authorization: `Bearer ${ accessToken }`
        };

        // set request url
        let requestURL = `${DELETE_COLOR_ITEM_URL}/${template.itemID}`;
    
        displayLoader("please wait...");
        try {
            const response = await axios.post(`${requestURL}`, 
                {
                    imageIndex:index, modelIndex:index
                } , { 
                headers,
                timeout: DELETE_ITEM_TIMEOUT_DURATION
            });

            /*---- Item has been deleted ---*/
            hideLoader();
            dispatch(setHomeTooltipInfo({show: true, status:"info", text:"Color Item successfully deleted"}));
            const responseData = response.data
            handlePostDelete(colorValue, responseData)
        } catch (error) {
            hideLoader();
            if (axios.isAxiosError(error)) {
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    // Handle timeout specific error
                    return showTimedOutAlert();
                }else{
                    const status = error.response?.status;
                    console.log(error);
        
                    switch (status) {
                        case 404:
                        case 410:
                            showNotFoundErrorAlert();
                            break;
                        case 401:
                        case 403:
                            showSessionExpiredAlert();
                            break;
                        case 500:
                            showInternalErrorAlert();
                            break;
                        default:
                            showRequestErrorAlert();
                    }
                }
            } else {
                showInternalErrorAlert();
            }
        }
    }

    // task to perform after item has been successfully deleted
    const handlePostDelete = (colorValue: string, responseData: any) => {
        dispatch(deleteColorItem(colorValue))
    }

    // handle adding display image
    const addDisplayImage = (index: number, showInstructions = true) => {
        if(!template.clothes.colors || template.clothes.colors.length === 0) return;

        if(!template.saved) return showUnsavedChangesDialog();
        
        if(template.clothes.colors[selectedColorIndex].images.length >= MAX_IMAGE_COUNT){
            return showMaxDisplayImageErrorAlert();
        }

        if(showInstructions && shouldDisplayUploadInfoDialog()){
            setUploadInfoDialogCallback(() => () => {
                addDisplayImage(index, false);
            })
            return displayUploadInfoDialog();
        }
        setImageUploadType("display");
        setImageUploadAction("Add");
        setImageUploadCount(index);
        setShowImageChooser(true);
    }

    // handle add 3D Image
    const addThreeDImage = (positionIndex: number, showInstructions = true) => {
        if(!template.clothes.threeD || template.clothes.threeD.length === 0) return;

        if(!template.saved) return showUnsavedChangesDialog(); 

        if(showInstructions && shouldDisplayUploadInfoDialog()){
            setUploadInfoDialogCallback(() => () => {
                addThreeDImage(positionIndex, false);
            })
            return displayUploadInfoDialog();
        }

        setImageUploadType("threeD");
        setImageUploadAction("Add");
        setImageUploadCount(positionIndex);
        setShowImageChooser(true);
    }

    // user selected file for upload
    const onFileSelected = (file: File) => {
        if(imageUploadType === "display"){
            // upload display image
            if(!template.clothes.colors || template.clothes.colors.length === 0) return;
            const imageIndex = selectedColorIndex;
            const imageCount = (imageUploadAction === "Add")? -1 : imageUploadCount;
            sendUploadRequest(file, imageIndex, imageCount);
        }else{
            // upload 3D Image
            const imageIndex = selectedColorIndex;
            const imageCount = (imageUploadAction === "Add")? imageUploadCount : -(imageUploadCount);
            sendUploadRequest(file, imageIndex, imageCount, true);
        }
    }

    // upload image to server (can add, replace image)
    const sendUploadRequest = async (
        file: File,
        imageIndex: number,
        imageCount: number,
        isThreeDModel: boolean = false
    ) => {
        // scale down image to 800px
        displayLoader("Processing Image...");
        try {
            const scaledImage = await scaleImage(file);
            hideLoader();
            file = scaledImage;
        }catch(error: any){
            // display error processing file dialog
            hideLoader();
            console.error('Error scaling image', error);
            setAlertDialogTitle("Error processing image");
            setAlertDialogText(`We encountered an error while processing the selected file, please check that the image file is a 
                valid PNG or JPEG file and try again.`);
            return setShowAlertDialog(true);
        }
        
        // find index
        const formData = new FormData();

        // Append the file and other data to the form data
        formData.append(isThreeDModel ? 'threeDModel' : 'image', file);
        formData.append('imageIndex', imageIndex.toString());
        formData.append('imageCount', imageCount.toString());

        //imageIndex: number, modelIndex: number
        const accessToken = getLocalStorageItem("refreshToken")

        const headers = {
            'Content-Type': 'multipart/form-data', Authorization: `Bearer ${ accessToken }`
        };

        // set request url
        let requestURL = `${UPLOAD_IMAGE_URL}/${template.itemID}`;
    
        displayLoader("please wait...");
        try {
            const response = await axios.post(`${requestURL}`, formData, { 
                headers,
                timeout: UPLOAD_IMAGE_TIMEOUT_DURATION,
                onUploadProgress: (progressEvent) => {
                    if(progressEvent.total !== undefined){
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setLoaderMsg(`Uploading ${percentCompleted}%`)
                    }
                }
            });

            hideLoader();
            const responseData = response.data as AddImageResponseType
            if(imageUploadType === "display"){
                // display image uploaded
                switch (imageUploadAction) {
                    case "Add":
                        handlePostAddImage(responseData);
                }
            }else{
                // threeD image uploaded
                switch (imageUploadAction) {
                    case "Add":
                        handlePostAddImage(responseData);
                }
            }
            
        } catch (error) {
            hideLoader();
            if (axios.isAxiosError(error)) {
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    // Handle timeout specific error
                    return showTimedOutAlert();
                }else{
                    const status = error.response?.status;
                    console.log(error);
        
                    switch (status) {
                        case 400:
                            showInvalidFileErrorAlert();
                            break;
                        case 404:
                        case 410:
                            showImageItemNotFoundErrorAlert();
                            break;
                        case 415:
                            showMaxDisplayImageErrorAlert();
                            break;
                        case 401:
                        case 403:
                            showSessionExpiredAlert();
                            break;
                        case 430:
                            showParamValidationErrorAlert()
                            break;
                        case 500:
                            showInternalErrorAlert();
                            break;
                        default:
                            showRequestErrorAlert();
                    }
                }
            } else {
                showInternalErrorAlert();
            }
        }
    }

    // post image upload trigger
    const handlePostAddImage = (responseData: AddImageResponseType) => {
        dispatch(setHomeTooltipInfo({show: true, status:"info", text:"Image uploaded successfully!"}));
        if(imageUploadType === "display" && imageUploadAction === "Add"){
            dispatch(addItemImage({itemIndex:selectedColorIndex, fileName:responseData.fileName}));
        }else if (imageUploadType === "threeD" && imageUploadAction === "Add"){
            threeDDefaults[imageUploadCount-1].url = responseData.fileName;
            dispatch(persist3DImage({itemIndex:imageUploadCount, fileName:responseData.fileName}))
        }
        //console.log(responseData)
    }

    // handle delete display image
    const deleteDisplayImageItem = (imageCount: number, confirmed:boolean = false) => {
        if(!confirmed) {
            const params: ConfirmBoxParamType = {
                message: "Are you sure you want to delete this image? This action cannot be reversed.",
                title: "Confirm delete",
                negativeText: "No",
                positiveText: "Yes",
                onAccept: (msg) => {
                    setShowConfirmDialog(false);
                    deleteDisplayImageItem(imageCount, true);
                },
                onDeny: (msg) => {setShowConfirmDialog(false);}
            }
            setConfirmDialogProps({...params});
            return setShowConfirmDialog(true)
        }

        // confirmed
        sendDeleteRequest(selectedColorIndex, imageCount, false);
    }

    // handle delete 3D image
    const delete3DImageItem = (imageCount: number, confirmed:boolean = false) => {
        if(!confirmed) {
            const params: ConfirmBoxParamType = {
                message: "Are you sure you want to delete this image? This action cannot be reversed.",
                title: "Confirm delete",
                negativeText: "No",
                positiveText: "Yes",
                onAccept: (msg) => {
                    setShowConfirmDialog(false);
                    delete3DImageItem(imageCount, true);
                },
                onDeny: (msg) => {setShowConfirmDialog(false);}
            }
            setConfirmDialogProps({...params});
            return setShowConfirmDialog(true)
        }

        // confirmed
        sendDeleteRequest(selectedColorIndex, (imageCount+1), true);
    }

    // delete color item image from server 
    const sendDeleteRequest = async (
        imageIndex: number,
        imageCount: number,
        isThreeDModel: boolean = false
    ) => {
        //console.log({imageCount, imageIndex})

        const type = isThreeDModel ? 'threeDModel' : 'image';

        //imageIndex: number, modelIndex: number
        const accessToken = getLocalStorageItem("refreshToken")

        const headers = {
            'Content-Type': 'application/json', Authorization: `Bearer ${ accessToken }`
        };

        // set request url
        let requestURL = `${DELETE_IMAGE_URL}/${template.itemID}`;
    
        displayLoader("please wait...");
        try {
            const response = await axios.post(`${requestURL}`, 
                {
                    imageIndex, imageCount, type
                } , { 
                headers,
                timeout: DELETE_ITEM_TIMEOUT_DURATION
            });

            /*---- Item has been deleted ---*/
            hideLoader();
            const responseData = response.data as {}
            onImageItemDeleted({itemIndex:imageIndex, imageIndex:imageCount}, isThreeDModel,  responseData)
            console.log(responseData)
        } catch (error) {
            hideLoader();
            if (axios.isAxiosError(error)) {
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    // Handle timeout specific error
                    return showTimedOutAlert();
                }else{
                    const status = error.response?.status;
                    console.log(error);
        
                    switch (status) {
                        case 404:
                        case 410:
                            showImageItemNotFoundErrorAlert();
                            break;
                        case 401:
                        case 403:
                            showSessionExpiredAlert();
                            break;
                        case 430:
                            showParamValidationErrorAlert()
                            break;
                        case 500:
                            showInternalErrorAlert();
                            break;
                        default:
                            showRequestErrorAlert();
                    }
                }
            } else {
                showInternalErrorAlert();
            }
        }
    }

    // handle post delete image item
    const onImageItemDeleted = (params:{itemIndex: number, imageIndex: number}, isThreeDModel: boolean, responseData: any) => {
        dispatch(setHomeTooltipInfo({show: true, status:"info", text:"Image deleted successfully!"}))
        if(!isThreeDModel){
            // display Image
            dispatch(deleteItemImage({imageIndex:params.imageIndex, itemIndex:params.itemIndex}))
        }else{
            // 3D Image
            dispatch(persistDelete3DImage({imageIndex:params.imageIndex, itemIndex:params.itemIndex}))
        }
    }

    // fetch background image from ImageFetcher
    const getImageBackGround = (fileName: string, isThreeD: boolean): string => {
        if(!isMounted) return "";
        if(images.has(fileName)){
            return images.get(fileName) ?? "";
        }
        
        setImages((map) => new Map<string, string>(map).set(fileName, ""))

        imageFetcher.fetchImage(fileName, isThreeD ? "threeDModel" : "image", (imagePath) => {
            const newImages = new Map<string, string>(images).set(fileName, imagePath);
            setImages(newImages);
        });

        return images.get(fileName) ?? "";
    };

    return (
        <>
            <div className={styles.ImageBox}>
                <div className={styles.header}>
                    <div className={styles.color} style={{backgroundColor: getActiveColorItem()?.colorValue}}></div>
                    <div className={styles.colorName}>{getActiveColorItem()?.colorName}</div>
                    <div className={styles.btnRemove} onClick={handleRemove}>Remove color</div>
                </div>
                {/*---- Normal Clothes ----*/}
                <div className={styles.plainImageBox}>
                    <div className={styles.head}>
                        <div className={styles.title}>Display photos</div>
                        <div className={styles.info}>
                            Upload clothing items for display on the Hologlam app. Maximum of 3 photos available per color variation
                        </div>
                    </div>
                    <div className={styles.images}>
                        {getClothes().images.map(({url},idx) => (
                            <div className={styles.image} key={idx} 
                                style={{backgroundImage: `url(${getImageBackGround(url, false)}`}}
                            >
                                {!(getImageBackGround(url, false)) && 
                                <div className={styles.loader}></div>}
                                <div className={styles.btnDel} onClick={() => deleteDisplayImageItem(idx)}></div>
                            </div>
                        ))}
                        {getNumberOfImages() === 0 && 
                            <div className={styles.btnAdd} onClick={() => addDisplayImage(-1)}>Upload photos</div>
                        }
                        {(getNumberOfImages() > 0) && (getNumberOfImages() < MAX_IMAGE_COUNT) && 
                            <div className={styles.btnAddSmall} onClick={() => addDisplayImage(-1)}></div>
                        }
                    </div>
                </div>
                {/*---- 3D Clothes ----*/}
                {(category?.threeD === "yes" || threeDClothAvailable) &&
                <div className={`${styles.plainImageBox} ${styles.threeDImage}`}>
                    <div className={styles.head}>
                        <div className={styles.head1}>
                            <div className={styles.title}>3D clothing</div>
                            <div className={styles.btnRemove} onClick={()=>handleRemove3DClothing(false)}>Remove 3D clothing</div>
                        </div>
                        <div className={styles.info}>
                            Upload clothing photos from all visible sides and provide detailed information, such as distance 
                            and zoom levels, for each photo. This will help ensure accurate representation of the clothing
                        </div>
                    </div>
                    <div className={`${styles.images} ${styles.threeD}`}>
                        {threeDDefaults.map(({bgName, label, url, itemIndex},idx) => (
                            <div className={`${styles.image} ${!url?styles.empty:""}`} key={idx} 
                                style={{backgroundImage: getThreeDBackground(url?url:bgName, url?true:false, idx)}}
                            >
                                {url && !(getThreeDBackground(url, true, idx)) &&
                                <div className={styles.loader}></div>}
                                <div className={styles.label}>{label}</div>
                                <div 
                                    className={`${styles.btnAddDel} ${url?styles.added:""}`}
                                    onClick={()=>url? delete3DImageItem(idx, false) : addThreeDImage(itemIndex)}>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}
                {/*---- Distance ----*/}
                <div className={`${styles.d1} ${styles.distance}`}>
                    <div className={`${styles.l1} ${styles.label}`}>Distance</div>
                    <div 
                        className={`${styles.ib1} ${styles.inputBox} ${distanceInputFocused && styles.focused}`}
                        onFocus={()=>setDistanceInputFocused(true)} onBlur={()=>setDistanceInputFocused(false)}
                    >
                        <input type="text" className={`${styles.i1} ${styles.input}`} placeholder="Distance from camera"
                            maxLength={20} value={formValues.distance.value} onChange={handleDistanceChanged}
                            onBlur={(e)=>updateFormValue("distance",{unit:formValues.distance.unit, value:String(parseFloat(e.target.value))})}/>
                        <div className={styles.unit}>{formValues.distance.unit}</div>
                        <div className={styles.btnChangeUnit} onClick={swipeUnit}></div>
                    </div>
                </div>
                {/*---- zoom level ----*/}
                <div className={`${styles.d1} ${styles.zoom}`}>
                    <div className={`${styles.l1} ${styles.label}`}>Zoom level</div>
                    <div 
                        className={`${styles.ib1} ${styles.inputBox} ${zoomInputFocused && styles.focused}`}
                        onFocus={()=>setZoomInputFocused(true)} onBlur={()=>setZoomInputFocused(false)}
                    >
                        <input type="text" className={`${styles.i1} ${styles.input}`} 
                            placeholder="For example: 0% implies no zoom" maxLength={20} value={formValues.zoomLevel}
                            onChange={handleZoomLevelChanged} onBlur={(e)=>updateFormValue("zoomLevel", String(parseFloat(e.target.value)))}/>
                        <div className={styles.unit}>%</div>
                    </div>
                </div>
                {showUploadInfoDialog && 
                <UploadInfoDialog 
                    onClose={()=>hideUploadInfoDialog(false)} onDoNotShow={(state)=>handleDoNotShowDialog(state)}
                    onContinue={()=>hideUploadInfoDialog(true)}
                />}
                {showConfirmDialog &&
                <ConfirmDialog {...confirmDialogProps} />}
            </div>
            {showAlertDialog &&
                <AlertDialog message={alertDialogText} title={alertDialogTitle} onDialogClose={hideAlertDialog}/>
            }
            {showLoader &&
                <LoaderDialog message={loaderMsg}/>
            }
            {showImageChooser &&
                <ImageChooserDialog onClose={hideImageChooser} onFileSelected={onFileSelected}/>    
            }
        </>
    );
}));


/*---- SizeInput -----*/
type SizeInputProps = {
    label: string;
    onChange: (value: number) => void;
    initValue?: number;
    className?: string;
}
const SizeInput: React.FC<SizeInputProps> = ({className, label, onChange, initValue }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if(onChange) onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        setValue(initValue ?? 0)
    }, [initValue])

    const addValue = () => {
        setValue((value) => Math.min(500, value+1));
    }

    // reduce one from value
    const reduceValue = () => {
        setValue((value) => Math.max(0, value-1))
    }

    return (
        <div className={`${styles.SizeInput} ${className?className:""}`}>
            <div className={styles.label}>{label}</div>
            <div className={`${styles.btnMinus} ${styles.btn1}`} onClick={reduceValue}></div>
            <div className={styles.value}>{value}</div>
            <div className={`${styles.btnAdd} ${styles.btn1}`} onClick={addValue}></div>
        </div>
    )
}


/*---- SizeParamsBox ----*/
type SizeParamType = {
    applyToAllColors: boolean,
    parameters: ParameterType,
}
const defaultSizeParamsValue:SizeParamType = {
    applyToAllColors: false,
    parameters: {
        "XXXS": 0, "XXS": 0, "XS": 0, "S": 0, "M": 0, "L": 0, 
        "XL": 0, "XXL": 0, "XXXL": 0, "4XL": 0
    }
}

type Props = {
    onUpdate: (values: SizeParamType) => void;
    changes?: boolean
}

const SizeParamsBox:React.FC<Props> = React.memo(forwardRef(({onUpdate, changes}, ref) => {
    const {template:{clothes, selectedColorIndex}} = useSelector((state: RootState) => state.users)
    const {templateClothChanges,dresses} = useSelector((state: RootState) => state.users)
    const [values, setValues] = useState<SizeParamType>(defaultSizeParamsValue);

    const checkBoxRef = useRef<CheckBoxRefType>(null);
    const dispatch = useDispatch();

    // Expose some actions to the parent component
    useImperativeHandle(ref, () => ({
        getValues: () => values, // method to get all values from this component
    }));

    // get the active cloth item
    const getActiveCloth = () => {
        return clothes.colors![selectedColorIndex];
    }

    // check if there is a cloth template for this index
    const isTemplateAvailable = () => {
        return templateClothChanges[selectedColorIndex].parameters !== null;
    }

    // get template params
    const getTemplateParameters = () => {
        return {
            parameters: templateClothChanges[selectedColorIndex].parameters,
            setSameParameters: templateClothChanges[selectedColorIndex].setSameParameters
        }
    }

    // setValues
    useEffect(() => {
        //check if there is already a template for this:
        if(isTemplateAvailable()){
            updateValue("parameters", {...getTemplateParameters().parameters!});
            updateValue("applyToAllColors", getTemplateParameters().setSameParameters!)
        }else{
            updateValue("parameters", {...getActiveCloth().parameters});
            updateValue("applyToAllColors", getActiveCloth().setSameParameters)
        }

        const checked = isTemplateAvailable()? 
            getTemplateParameters().setSameParameters! : getActiveCloth().setSameParameters

        if(checkBoxRef.current && checked){
            checkBoxRef.current.check();
        }else{
            checkBoxRef.current?.uncheck();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColorIndex, checkBoxRef])

    // watch value changes
    useEffect(() => {
        if(onUpdate) onUpdate(values);
    }, [values, onUpdate, dresses])

    const updateValue = <K extends keyof SizeParamType>(key: K, value: SizeParamType[K]) => {
        setValues((values) =>( {...values, [key]:value}));
    }

    const updateParam = <K extends keyof ParameterType>(key: K, value: ParameterType[K]) => {
        const newParams = {...values.parameters, [key]:value}
        setValues({...values, parameters:newParams})
    }

    // check if this seize component has changes
    const componentHasChanges = () => {
        return changes;
    }

    // handle submit for this component
    const onSubmit = () => {
        if(!componentHasChanges()){
            return dispatch(setHomeTooltipInfo({show: true, status:"info", text:"All size parameters are up to date!"}))
        }
        dispatch(triggerTemplateSave())
    }

    return (
        <div className={styles.SizeParamsBox}>
            <div className={styles.title}>Available size</div>
            <div className={styles.info}>Add the available quantity of clothes for each size.</div>
            <div className={styles.sizeInfo}>
                <div className={styles.t1}>Internationals</div>
                <div className={styles.t2}>EU size</div>
            </div>
            <div className={styles.params}>
                {Object.keys(values.parameters).map((label , idx) => 
                    <SizeInput
                        label={label} className={styles.item} key={idx}
                        onChange={(value)=>updateParam(label as keyof ParameterType, value)}
                        initValue={values.parameters[label as keyof ParameterType]}
                    />
                )}
                
            </div>
            <div className={styles.applyToAll}>
                <CheckBox 
                    label="Apply size and quantity for other color variations" ref={checkBoxRef}
                    value="apply" onToggle={(checked)=>updateValue("applyToAllColors", checked)}
                    iconStyle={styles.checkBox} 
                /> 
            </div>
            <div className={styles.actionBox_1}>
                <div className={`${styles.btnSubmit} ${componentHasChanges()?"":styles.saved}`}
                    onClick={onSubmit}>Submit</div>
                {false && <div className={styles.btnRemove}>Remove color variation</div>}
            </div>
        </div>
    );
}));


/*---- SeizingBox (Measurements)----*/
type SizingBoxProps = {
    onUpdate: (values: MeasurementBoxType) => void;
    changes?: boolean
}

type MeasurementBoxType = {
    applyToAllColors: boolean
    measurements: MeasurementsType,
}
const defaultSizeBoxValues: MeasurementBoxType = {
    applyToAllColors: false,
    measurements: {shoulder: 0, sleeve: 0, chest: 0, waist: 0, back: 0, unit:"inch"},
}

// Component
const SeizingBox: React.FC<SizingBoxProps> = ({onUpdate, changes}) => {
    const displayImageRef = useRef<HTMLDivElement>(null);
    const [displayImageHeight, setDisplayImageHeight] = useState<string>("48%");

    const {clothes, selectedColorIndex} = useSelector((state: RootState) => state.users.template)
    const {templateClothChanges, dresses} = useSelector((state: RootState) => state.users)
    const [values, setValues] = useState<MeasurementBoxType>(defaultSizeBoxValues);

    const title = "Specify the cloth size";
    const subtitle = `This size parameters help us to fit the clothes properly on our users, hence its very important 
                        you provide these parameters, in other to get potential customers to buy the clothes`

    const checkBoxRef = useRef<CheckBoxRefType>(null);
    const dispatch = useDispatch();

    // Change height of display image based on width
    const updateDisplayImageWidth = useCallback(() => {
        const width = getComputedStyle(displayImageRef.current as HTMLDivElement).width;
        const height = `${0.8 * parseFloat(width)}px`;
        setDisplayImageHeight(height)
    },[])

    // handles window size change event
    const handleWindowResized = useCallback(() => {
        updateDisplayImageWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(() => {
        handleWindowResized();
        window.addEventListener("resize", handleWindowResized)
        return () => {
            window.removeEventListener('resize', handleWindowResized);
        }
    }, [handleWindowResized])

    // update values with specified key/value pair
    const updateValue = <K extends keyof MeasurementBoxType>(key:K, value: MeasurementBoxType[K]) => {
        setValues((values) => ({...values, [key]:value}))
    }

    // get the active color item
    const getActiveCloth = () => {
        return clothes.colors![selectedColorIndex];
    }

    // check if there is a cloth template for this index
    const isTemplateAvailable = () => {
        return templateClothChanges[selectedColorIndex].measurements !== null;
    }

    // get template params
    const getTemplateMeasurements = () => {
        return {
            measurements: templateClothChanges[selectedColorIndex].measurements,
            setSameMeasurements: templateClothChanges[selectedColorIndex].setSameMeasurements
        }
    }

    // update parent
    useEffect(() => {
        if(onUpdate) onUpdate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, dresses])

    // initialize values
    useEffect(() => {
        //check if there is already a template for this:
        if(isTemplateAvailable()){
            updateValue("measurements", {...getTemplateMeasurements().measurements!});
            updateValue("applyToAllColors", getTemplateMeasurements().setSameMeasurements!)
        }else{
            updateValue("measurements", {...getActiveCloth().measurements});
            updateValue("applyToAllColors", getActiveCloth().setSameMeasurements);
        }

        const checked = isTemplateAvailable()? 
            getTemplateMeasurements().setSameMeasurements! : getActiveCloth().setSameMeasurements
        
        
        if(checked){
            checkBoxRef.current?.check()
        }else{
            checkBoxRef.current?.uncheck();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColorIndex])
    
    // handle unit measurement unit select
    const unitSelected = (unit: UnitType2) => {
        updateValue("measurements", {...values.measurements, unit:unit})
    }

    // checks if a unit is selected
    const isUnitSelected  = (unit: UnitType2) => {
        return values.measurements.unit === unit;
    }

    // returns the active unit
    const getUnit = () => {
        return values.measurements.unit;
    }

    // handle unit input changes
    const handleUnitInputChange = <K extends keyof MeasurementsType>(key: K, value: MeasurementsType[K]) => {
        const newValue:MeasurementsType = {...values.measurements, [key]:value}
        updateValue("measurements", newValue);
    }

    const handleApplyToAllToggle = (apply: boolean) => {
        updateValue("applyToAllColors", apply);
    }

    // check if this measurement component has changes
    const componentHasChanges = () => {
        return changes;
    }

    // handle submit for this component
    const onSubmit = () => {
        if(!componentHasChanges()){
            return dispatch(setHomeTooltipInfo({show: true, status:"info", text:"All measurement parameters are up to date!"}))
        }
        dispatch(triggerTemplateSave())
    }

    return (
        <div className={styles.SeizingBox}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
            <div className={styles.inputBox}>
                <div className={styles.displayImage} ref={displayImageRef} style={{height: displayImageHeight}}></div>
                <div className={styles.seizeForm}>
                    <div className={styles.units}>
                        <div 
                            className={`${styles.cm} ${styles.unit_btn} ${isUnitSelected("cm") && styles.selected}`} 
                            onClick={()=>unitSelected("cm")}>
                            cm
                        </div>
                        <div 
                            className={`${styles.inch} ${styles.unit_btn} ${isUnitSelected("inch") && styles.selected}`} 
                            onClick={()=>unitSelected("inch")}>
                            inch
                        </div>
                    </div>
                    <div className={styles.paramsBox}>
                        <UnitInputBox label="Shoulders" name="shoulder" required unit={getUnit()} defaultValue={values.measurements.shoulder}
                            onChange={handleUnitInputChange}/>
                        <UnitInputBox label="Sleeve" name="sleeve" required unit={getUnit()} defaultValue={values.measurements.sleeve}
                            onChange={handleUnitInputChange}/>
                        <UnitInputBox label="Chest" name="chest" required unit={getUnit()} defaultValue={values.measurements.chest}
                            onChange={handleUnitInputChange}/>
                        <UnitInputBox label="Waist" name="waist" required unit={getUnit()} defaultValue={values.measurements.waist}
                            onChange={handleUnitInputChange}/>
                        <UnitInputBox label="Center back" name="back" required unit={getUnit()} defaultValue={values.measurements.back}
                            onChange={handleUnitInputChange}/>
                    </div>
                </div>
            </div>
            <div className={styles.applyToAll}>
                <CheckBox 
                    label="Apply clothing size for all color variations" ref={checkBoxRef}
                    value="apply" onToggle={(checked)=>handleApplyToAllToggle(checked)}
                    iconStyle={styles.checkBox} 
                /> 
            </div>
            <div className={styles.actionBox_2}>
                <div className={`${styles.btnSubmit} ${componentHasChanges()?"":styles.saved}`}
                    onClick={onSubmit}>Submit</div>
            </div>
        </div>
    )
}


/*---- NameDescBox ----*/
type NameDescBoxProps = {
    onUpdate: (values: NameDescBoxValues) => void
}
type NameDescBoxValues = {
    name: string,
    description: string,
}
const defaultNameDescBoxValues: NameDescBoxValues = {
    name: "", description: "", 
}

const NameDescBox:React.FC<NameDescBoxProps> = ({onUpdate}) => {
    enum names {NAME="name", DESC="desc"}

    const {attributes} = useSelector((state: RootState) => state.users.template)
    const [values, setValues] = useState<NameDescBoxValues>(defaultNameDescBoxValues);

    const [nameFocused, setNameFocused] = useState(false);
    const [descFocused, setDescFocused] = useState(false);

    // update values 
    const updateValues = <K extends keyof NameDescBoxValues>(key: K, value: NameDescBoxValues[K]) => {
        setValues((values) => ({...values, [key]:value}));
    }

    // handle name update
    const handleNameUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        value = value.trimStart();
        value = value.replace(/\s+/g, " ");
        updateValues("name", value);
    }

    // handle name update
    const handleDescUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let value = e.target.value;
        value = value.trimStart();
        value = value.replace(/\n{3,}/g, "\n\n");
        updateValues("description", value);
    }

    // update initial states
    useEffect(() => {
        updateValues("name", attributes.name);
        updateValues("description", attributes.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes.name, attributes.description])

    // update parent
    useEffect(() => {
        if(onUpdate) onUpdate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    // handle name input focus
    const handleNameFocusChanged = (focused: boolean) => {
        setNameFocused(focused);
    }

    // handle description input focus
    const handleDescFocusChanged = (focused: boolean) => {
        setDescFocused(focused);
    }

    return (
        <div className={`${styles.NameDescBox}`}>
            <div className={styles.nameBox}>
                <div className={styles.name}>Name* <span>(4 characters min)</span></div>
                <div className={`${styles.nameValue} ${nameFocused? styles.focused:""}`}>
                    <input 
                        type="text" name={names.NAME} placeholder="For example:  T-shirt 2024 collection..."
                        onChange={handleNameUpdate} maxLength={60}
                        onFocus={()=>handleNameFocusChanged(true)} onBlur={()=>handleNameFocusChanged(false)}
                        value={values.name}
                    />
                </div>
            </div>
            <div className={styles.descBox}>
                <div className={styles.desc}>Short description</div>
                <div className={`${styles.descValue} ${descFocused? styles.focused:""}`}>
                    <textarea 
                        name={names.DESC} placeholder="For example: oversized, comfy T-shirt for everyday use"
                        onChange={handleDescUpdate} value={values.description} maxLength={200}
                        onFocus={()=>handleDescFocusChanged(true)} onBlur={()=>handleDescFocusChanged(false)}>
                    </textarea>
                </div>
            </div>
        </div>
    )
}


/*---- PriceBox ----*/
type PriceBoxProps = {
    onUpdate: (values: PriceBoxValueType) => void;
}

type PriceBoxValueType = {price: string, currency: CurrencyType}
const defaultPriceBoxValues: PriceBoxValueType = {price:"",currency:"$"}

const PriceBox: React.FC<PriceBoxProps> = ({onUpdate}) => {
    const {attributes} = useSelector((state: RootState) => state.users.template)
    const [currencies] = useState<Array<CurrencyType>>(["$", "€", "£"]);
    const [values, setValue] = useState<PriceBoxValueType>(defaultPriceBoxValues);
    const [focused, setFocused] = useState(false);
    const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);

    // update value
    const updateValues = <K extends keyof PriceBoxValueType>(key:K, value: PriceBoxValueType[K]) => {
        setValue((values) => ({...values, [key]:value}))
    }

    // update initial values
    useEffect(() => {
        updateValues("price", String(attributes.price));
        updateValues("currency", attributes.currency);
        const currencyIndex = currencies.findIndex((currency) => currency === attributes.currency);
        if(currencyIndex !== -1) setSelectedCurrencyIndex(currencyIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes.price, attributes.currency])

    // update Parent
    useEffect(() => {
        if(onUpdate) onUpdate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const handleInputChange = (value: string) => {
        if (/^-?\d*\.?\d*$/.test(value)) {
            // Remove leading zeros if the number is not just a zero or decimal like 0.x
            value = value.replace(/^(-?)0+(0\.|\d)/, '$1$2');
            value = value || "0";
            updateValues("price",value)
        }
    }

    // handle name input focus
    const handleFocusChanged = (focused: boolean) => {
        setFocused(focused);
        handleInputChange(String(Number(values.price)));
    }

    // returns the selected currency value
    const getSelectedCurrency = () => {
        return currencies[selectedCurrencyIndex];
    }

    const changeCurrency = () => {
        const newIndex = (selectedCurrencyIndex + 1) % currencies.length;
        setSelectedCurrencyIndex(newIndex)
        updateValues("currency", currencies[newIndex]);
    }

    return (
        <div className={styles.PriceBox}>
            <div className={styles.content}>
                <div className={styles.price}>Price</div>
                <div className={`${styles.priceValue} ${focused? styles.focused:""}`}>
                    <input 
                        type="text" name="price" placeholder="Clothing price"
                        onChange={(e)=>handleInputChange(e.target.value)}
                        onFocus={()=>handleFocusChanged(true)} onBlur={()=>handleFocusChanged(false)}
                        value={values.price}
                    />
                    <div className={styles.currency}>{getSelectedCurrency()}</div>
                    <div className={styles.btnChange} title="change currency" onClick={changeCurrency}></div>
                </div>
            </div>
        </div>
    )
}


/*---- MaterialBox ----*/
type MaterialBoxProps = {
    onUpdate: (values: MaterialBoxType) => void;
}
const allMaterials = {
    cotton: { name:"Cotton", value:"cotton" },
    wool: { name:"Wool", value:"wool" },
    silk: { name:"Silk", value:"silk" }
}
type allMaterialType = typeof allMaterials;
type allMaterialTypeKey = keyof allMaterialType;

type MaterialBoxType = {
    material: MaterialType[];
    mainMaterial: ""|allMaterialTypeKey;
}
const defaultMaterials: MaterialBoxType = {
    material: [],
    mainMaterial: ""
}

const MaterialBox: React.FC<MaterialBoxProps> = ({onUpdate}) => {
    const {attributes} = useSelector((state: RootState) => state.users.template)
    const {dresses} = useSelector((state: RootState) => state.users)
    const [values, setValues] = useState<MaterialBoxType>(defaultMaterials);
    const [stringValues, setStringValues] = useState<string[]>([])
    const [focused, setFocused] = useState(false);
    const [listOpen, setListOpen] = useState(false)
    const [focusIndexes, setFocusIndexes] = useState<Array<number>>([])

    const selectRef = useRef<HTMLDivElement>(null);

    // update values
    const updateValue = <K extends keyof MaterialBoxType>(key: K, value: MaterialBoxType[K]) => {
        setValues((values) => ({...values, [key]:value}))
    }

    // handle clicking outside dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setListOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [selectRef]);

    // update parent
    useEffect(() => {
        if(onUpdate) onUpdate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, dresses])

    // set up initial values
    useEffect(() => {
        updateValue("material", [...attributes.materials]);
        updateValue("mainMaterial", attributes.mainMaterial)
        setStringValues([...attributes.materials.map(({percentage}) => String(percentage))])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes.mainMaterial])

    // toggle list
    const toggleList = () => {
        setListOpen((open) => !open);
    }

    // handle list item clicked
    const handleListItemClicked = (name: ""|allMaterialTypeKey) => {
        updateValue("mainMaterial", name);
        setListOpen(false);
    }

    // handle other material input change
    const handleMaterialInputChange = (value: string, index: number) => {
        if (/^-?\d*\.?\d*$/.test(value)) {
            // Remove leading zeros if the number is not just a zero or decimal like 0.x
            value = value.replace(/^(-?)0+(0\.|\d)/, '$1$2');
            if(!value) value = "0";
            updateValue("material", [...values.material.slice(0, index), 
                {...values.material[index],percentage:parseFloat(value)}, ...values.material.slice(index+1)])
            setStringValues((values) => [...values.slice(0, index), value, ...values.slice(index+1)])
        }
    }

    // handle other material input change
    const clearInput = (index: number) => {
        updateValue("material", [...values.material.slice(0, index), 
            {...values.material[index],percentage:0}, ...values.material.slice(index+1)])
        setStringValues((values) => [...values.slice(0, index), "0", ...values.slice(index+1)])
    }


    // handle name input focus
    const handleFocusChanged = (focused: boolean) => {
        setFocused(focused);
    }

    // handle material item focus
    const handleItemFocusChanged = (focused: boolean, index: number) => {
        if(focused){
            if(!focusIndexes.includes(index)){
                setFocusIndexes([...focusIndexes, index])
            }
        }else {
            if(focusIndexes.includes(index)){
                const _idx = focusIndexes.indexOf(index)
                setFocusIndexes([...focusIndexes.slice(0, _idx), ...focusIndexes.slice(_idx+1)])
            }
        }
    }

    // returns material data for list
    const getMaterialData = () => {
        return values.material.map(({name, percentage}) => {
            return{name, label:allMaterials[name].name, value:percentage}
        })
    }

    // returns material name
    const getMaterialName = (name: ""|allMaterialTypeKey) => {
        if(name === "") return "";
        return allMaterials[name].name;
    }

    return (
        <div className={styles.MaterialBox}>
            <div className={styles.mainMaterial}>
                <div className={styles.label}>Main material</div>
                <div className={`${styles.valueBox} ${focused? styles.focused:""}`} ref={selectRef}>
                    <input 
                        type="text" name="material" placeholder="Choose the main clothing material" 
                        className={styles.mainInput} onClick={()=>setListOpen(true)} readOnly
                        onFocus={()=>handleFocusChanged(true)} onBlur={()=>handleFocusChanged(false)}
                        value={getMaterialName(values.mainMaterial)} 
                    />
                    <div className={`${styles.btnList} ${listOpen?styles.opened:""}`} onClick={toggleList}></div>
                    {listOpen && <div className={styles.list}>
                        <div className={styles.item} onClick={()=>handleListItemClicked("")}>None</div>
                        {getMaterialData().map(({label, name}) => (
                            <div 
                                className={styles.item} onClick={()=>handleListItemClicked(name)} key={name}>{label} 
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
            <div className={styles.detailsBox}>
                <div className={styles.left}>
                    <div className={styles.h1}>Material details</div>
                    <div className={styles.h2}>Add material percentage. Leave at 0% if not applicable</div>
                </div>
                <div className={styles.itemsBox}>
                    {getMaterialData().map(({label, name, value}, idx) => (
                        <div className={`${styles.item} ${focusIndexes.includes(idx) && styles.focused}`} key={label}>
                            <div className={styles.inputLabel}>{label}</div>
                            <input 
                                type="text" name={name} placeholder="0" 
                                className={styles.itemInput} onChange={(e)=>handleMaterialInputChange(e.target.value, idx)}
                                onFocus={()=>handleItemFocusChanged(true, idx)} onBlur={()=>handleItemFocusChanged(false, idx)}
                                value={stringValues[idx]}
                            />
                            <div className={styles.percent}>%</div>
                            <div className={styles.btnRemove} onClick={()=>clearInput(idx)}></div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.btnAddMaterial}></div>
        </div>
    )
}



/*---- TagsBox ----*/ 
type TagsBoxProps = {
    onUpdate: (tags: string[]) => void
}
type TagType = {value: string, selected: boolean}

const defaultTags: Array<TagType> = [
    {value: "Plain", selected: false},
    {value: "Street", selected: false},
    {value: "Corporate", selected: false},
    {value: "Office", selected: false},
    {value: "Party", selected: false},
    {value: "Flirty", selected: false},
    {value: "Sports", selected: false},
    {value: "Casual", selected: false},
    {value: "Luxury", selected: false},
]
const TagsBox: React.FC<TagsBoxProps> = ({onUpdate}) => {
    const { attributes } = useSelector((state: RootState) => state.users.template)
    const { dresses } = useSelector((state: RootState) => state.users)
    const [maxTagCount] = useState(5);
    const [maxTagsLength] = useState(70);
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState("");
    const [tags, setTags] = useState(defaultTags)
    const [listOpen, setListOpen] = useState(false);

    const selectRef = useRef<HTMLDivElement>(null);

    // handle name input focus
    const handleFocusChanged = (focused: boolean) => {
        setFocused(focused);
    }

    // handle tag input change or tag selected
    const handleInputChange = (event: null|React.ChangeEvent<HTMLInputElement>, _tag = "", clear = false) => {
        let inputValue = event? event.target.value : clear? _tag : `${value} ${_tag} `;

        // Regular expression to match only letters, numbers, comma, or space
        if (!/^[a-zA-Z0-9, ]*$/.test(inputValue)) {
            return; // Return immediately if input contains invalid characters
        }

        // Check for max tags length
        if (inputValue.length > maxTagsLength) return;
    
        // Determine if the last character is a comma or space
        const lastChar = inputValue[inputValue.length - 1];
        if (lastChar === ' ' || lastChar === ',') {
            // Split input by space or ", " to form tags, and filter out empty tags
            let tags = inputValue.split(/,\s*|\s+/).filter(tag => tag !== '');

            // If the last tag is not up to 3 characters long, ignore the new character input
            if (tags.length > 0 && tags[tags.length - 1].length < 3) {
                return;
            }

            // if this is a checkbox tag, check if it's already checked and uncheck it
            if(_tag) {
                if([...tags].slice(0, -1).includes(_tag.toLowerCase())){
                    let t_idx = tags.indexOf(_tag.toLowerCase());
                    tags = [...tags.slice(0, t_idx), ...tags.slice(t_idx+1, -1)];
                }
            }

            // Append ", " to each tag, including the last one if it's been broken by a space or comma
            tags = tags.map(tag => `${tag}, `);

            // check for tag count limit
            if(_tag && tags.length > maxTagCount){
                tags.pop();
            }else if(!_tag && tags.length >= maxTagCount){
                tags[tags.length-1] = tags[tags.length-1].replace(/, /g, "");
            }

            // if this was a tag click and its the last item remove the ", " at the end
            if(_tag && tags.length === maxTagCount){
                tags[tags.length-1] = tags[tags.length-1].replace(/, /g, "");
            }

            // Join back the tags and update the input with the resulting string
            setValue(tags.join('').toLocaleLowerCase());
        } else {
            // Update the value normally if the last character is not a comma or space
            setValue(inputValue.toLocaleLowerCase());
        }
    };

    // track tag input changes
    useEffect(() => {
        let tags = value.replace(/,|\s/g," ");
        tags = tags.replace(/\s+/g, " ");
        let tagsArray = tags.split(" ").filter((value) => value);
        if(onUpdate) onUpdate(tagsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, dresses])

    // set up initial tags
    useEffect(() => {
        let tags = attributes.tags;
        handleInputChange(null, tags.join(", "), true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes.tags])

    // handle clicking outside for list
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setListOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [selectRef]);

    // watch changes in the input value
    useEffect(() => {
        let inputTags = value.split(/,\s*|\s+/).filter(tag => tag !== '');
        setTags(tags.map(({selected, value}) => {
            if(inputTags.includes(value.toLocaleLowerCase())){
                return {value, selected:true}
            }else{
                return {value, selected:false}
            }
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    // get sorted items
    const getItems = () => {
        return sortArrayByValue(tags) as TagType[];
    }

    // handle tag item selected
    const handleSelect = (index: number) => {
        let item = getItems().find(({selected, value}, idx) => idx === index)
        if(!item) return;

        handleInputChange(null, item.value.toLocaleLowerCase());

        /*let newTags = getItems().map(({value, selected}, idx) => {
            if (idx === index) {
                return {value, selected:!selected}
            }
            return {value, selected}
        })
        setTags(newTags);*/
    }

    // open or close list
    const toggleListState = (show?:boolean) => {
        if(show) setListOpen(show);
        else setListOpen(!listOpen);
    }

    return (
        <div className={styles.TagsBox}>
            <div className={styles.content}>
                <div className={styles.tag}>Tags <span>(max {maxTagCount})</span></div>
                <div className={`${styles.tagValue} ${focused? styles.focused:""}`} ref={selectRef}>
                    <input 
                        type="text" name="tags" placeholder="e.g: party, corporate, beach, classic"
                        onChange={(e) => handleInputChange(e, "")}
                        onFocus={()=>handleFocusChanged(true)} onBlur={()=>handleFocusChanged(false)}
                        value={value}
                    />
                    <div className={`${styles.handle} ${listOpen && styles.open}`} onClick={()=>toggleListState()}></div>
                    {listOpen && 
                    <div className={styles.dropDown}>
                        {getItems().map(({value, selected}, idx) => (
                            <div className={styles.dropDownItem} key={value} onClick={()=>handleSelect(idx)}>
                                <div className={styles.txt}>{value}</div>
                                <div className={`${styles.checkBox} ${selected && styles.checked}`}></div>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
        </div>
    )
}


export default AddItemForm;