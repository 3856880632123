const IMAGE_URL = process.env.REACT_APP_IMAGE_FETCHER_URL;

type FetchCallback = (imagePath: string) => void;

class ImageFetcher {
    private imageCache: Map<string, string>;
    private failedDownloads: Set<string>;
    private retryInterval: number;

    constructor() {
        this.imageCache = new Map<string, string>();
        this.failedDownloads = new Set<string>();
        this.retryInterval = 30000; // 30 seconds
    }

    fetchImage(fileName: string, type: 'image' | 'threeDModel', callback: FetchCallback): void {
        const cachedUrl = this.imageCache.get(fileName);

        if (cachedUrl) {
            callback(cachedUrl);
            return;
        }

        const imageUrl = `${IMAGE_URL}/${type}/${fileName}`;
        fetch(imageUrl)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 404) {
                        // For 404 errors, cache an empty string to avoid refetching
                        this.imageCache.set(fileName, '');
                        callback('');
                    } else {
                        throw new Error(`Network response was not ok, status: ${response.status}`);
                    }
                }
                return response.blob();
            })
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob);
                this.imageCache.set(fileName, blobUrl);
                callback(blobUrl);
            })
            .catch(error => {
                if (!this.imageCache.has(fileName)) {
                    // Only log and retry if it's not a 404 error handled above
                    console.error('Failed to fetch image:', error);
                    this.failedDownloads.add(fileName);
                    setTimeout(() => this.retryDownload(fileName, type, callback), this.retryInterval);
                }
            });
    }

    private retryDownload(fileName: string, type: 'image' | 'threeDModel', callback: FetchCallback): void {
        if (this.failedDownloads.has(fileName)) {
            console.log(`Retrying download for ${fileName}`);
            this.fetchImage(fileName, type, callback);
        }
    }
}

// Usage example
const imageFetcher = new ImageFetcher();
export {imageFetcher}
