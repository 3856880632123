const MAX_DIMEN: number = Number(process.env.REACT_APP_MAX_FILE_DIMEN);

const scaleImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
        const MAX_DIMENSION = MAX_DIMEN || 860;
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
            const img = new Image();
            img.src = event.target? event.target.result as string : ""

            img.onload = () => {
                // Check if the image needs scaling
                const scaleFactor = Math.min(1, MAX_DIMENSION / img.width, MAX_DIMENSION / img.height);
                const width = img.width * scaleFactor;
                const height = img.height * scaleFactor;

                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error('Failed to get canvas context'));
                    return;
                }

                ctx.drawImage(img, 0, 0, width, height);
                const mimeType = file.type || 'image/jpeg'; // Default to 'image/jpeg'

                canvas.toBlob(blob => {
                    if (blob) {
                        const scaledImageFile = new File([blob], file.name, { type: mimeType });
                        resolve(scaledImageFile);
                    } else {
                        reject(new Error('Canvas to Blob conversion failed'));
                    }
                }, mimeType);
            };

            img.onerror = () => {
                reject(new Error('Image loading failed'));
            };
        };

        reader.onerror = () => {
            reject(new Error('File reading failed'));
        };

        reader.readAsDataURL(file);
    });
};

export default scaleImage