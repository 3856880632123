import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import AlertDialog from "./AlertDialog";

import styles from "../styles/components/ImageChooserDialog.module.css"

const MAX_IMAGE_FILE_SIZE = 1024 * 1024 * 10;

type Props = {
    onFileSelected: (file: File) => void
    onClose: () => void;
}

const ImageChooserDialog:React.FC<Props> = ({onClose, onFileSelected}) => {
    const [file, setFile] = useState<null|File>(null);
    const [fileInputAvailable, setFileInputAvailable] = useState(true);
    const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogTitle, setAlertDialogTitle] = useState("");
    const [alertDialogText, setAlertDialogText] = useState("");

    const fileInputRef = useRef<HTMLInputElement>(null);

    // display Alert dialog
    const displayAlertDialog = (title: string, text: string) => {
        setAlertDialogTitle(title);
        setAlertDialogText(text);
        setShowAlertDialog(true);
    }

    // const hide Alert dialog
    const hideAlertDialog = () => {
        setShowAlertDialog(false);
    }

    // display invalid file dialog
    const displayInvalidFileDialog = () => {
        displayAlertDialog("Invalid file", "You can only choose an image file (PNG or JPEG) here, no other image type nor folder.");
    }

    // display file too large
    const displayFileTooLargeDialog = () => {
        const maxSize = `${(MAX_IMAGE_FILE_SIZE/(1024*1024))}Mb`
        displayAlertDialog(
            "File too large!", 
            `You cannot upload a file larger than ${maxSize}. Please scale down the image before uploading.`
        );
    }

    // check if type is valid
    const isValidFile = (type: string) => {
        return /image\/(png|jpeg|jpg)/ig.test(type);
    }

    // check if file is within range
    const isFileSizeValid = () => {
        if(!file) return false;
        return file.size <= MAX_IMAGE_FILE_SIZE;
    }

    // clear file
    const clearFile = () => {
        setFile(null);
        setFileInputAvailable(false);
        setTimeout(() => {
            setFileInputAvailable(true);
        }, 1000)
    }

    // check file availability
    const isFileAvailable = () => {
        return !!file
    }

    // handle close
    const handleClose = (clear: boolean = true) => {
        if(clear) clearFile();
        if(onClose) onClose();
    }

    // listen for file changes and set background image for image box
    useEffect(() => {
        if(!isFileAvailable()) {
            return setBackgroundImage(null);
        }

        if(!isValidFile(file!.type)){
            clearFile();
            return displayInvalidFileDialog();
        }

        if(!isFileSizeValid()) {
            clearFile();
            return displayFileTooLargeDialog();
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            setBackgroundImage(e.target?.result as string);
        };
        reader.readAsDataURL(file!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file])

    // handle select file
    const selectFile = () => {
        if(fileInputRef.current) fileInputRef.current.click();
    }

    // handle file selected
    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        setFile(file);
    }

    // handle user dragging file over image box
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault(); // Necessary to allow drop
    };

    // allow user to be able to drop file
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const items = e.dataTransfer.items;
        if (items.length) {
            const item = items[0];
            if (item.type.startsWith("image")) {
                const droppedFile = item.getAsFile();
                console.log(droppedFile)
                if (droppedFile) {
                    setFile(droppedFile);
                }
            } else {
                clearFile();
                displayInvalidFileDialog();
            }
        }
    };

    // handle change
    const changeFile = () => {
        if(fileInputRef.current) fileInputRef.current.click()
    }

    // handle save
    const saveFile = () => {
        if(!file) return;
        if(onFileSelected) onFileSelected(file);
        if(onClose) onClose();
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.btnClose} onClick={() => handleClose()}></div>
                <div 
                    className={styles.imageBox} 
                    style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none' }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    {!isFileAvailable() && 
                    <div className={`${styles.btnChoose} ${styles.btn}`} onClick={selectFile}>Select or Drop File</div>}
                    {fileInputAvailable &&
                    <input type="file" className={styles.file} ref={fileInputRef} onChange={handleFileSelected}/>}
                </div>
                {isFileAvailable() &&
                <div className={styles.actionBox}>
                    <div className={`${styles.btnChange} ${styles.btn}`} onClick={changeFile}>Change</div>
                    <div className={`${styles.btnSave} ${styles.btn}`} onClick={saveFile}>Save</div>
                </div>}
            </div>
            {showAlertDialog &&
                <AlertDialog message={alertDialogText} title={alertDialogTitle} onDialogClose={hideAlertDialog}/>
            }
        </>
    )
}

export default ImageChooserDialog