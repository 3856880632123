import React, { useEffect, useState } from "react"
import styles from "../styles/components/InputBox.module.css"

type Props = {
    placeHolder: string,
    required: boolean,
    name: string,
    onChange?: (value: string) => void;
    maxLength?: number;
    type?: React.HTMLInputTypeAttribute,
    pattern?: {pattern:RegExp, errorMsg: string;}  // Regex that input must match before it is accepted
    formatter?: (value: string) => string
    className?: string
}

const InputBox: React.FC<Props> = ({ 
        type="text", placeHolder="", name, required, className, pattern, formatter, maxLength, onChange
}) => {
    let [focused, setFocused] = useState<boolean>(false);
    let [value, setValue] = useState("");
    let [showInputError, setShowInputError] = useState(false);

    // watch value changes
    useEffect(() => {
        if(onChange) onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    // handle input focus
    const handleFocusEvent = (focused:boolean) => {
        setFocused(focused);
    }

    // handle input event
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let _value = e.target.value;
        if(formatter) _value = formatter(_value);
        if(pattern){
            if(!pattern.pattern.test(_value)){
                setShowInputError(true)
            }else{
                setShowInputError(false)
            }
        }
        setValue(_value);
    }

    return (
        <div className={`${styles.inputBox} ${className} ${focused && styles.focus} ${showInputError && styles.error}`} 
            style={{marginBottom:showInputError?"8px":undefined}}>
            <input 
                type={type} name={name} placeholder={placeHolder} required={required} maxLength={maxLength}
                onFocus={() => handleFocusEvent(true)} onBlur={() => handleFocusEvent(false)}
                value={value} onChange={handleInput}
            />
            {showInputError && <div className={styles.error}>{pattern?.errorMsg}</div>}
        </div>
    )
}

export default InputBox;