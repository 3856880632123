import React, { useState } from "react"
import styles from "../styles/components/SearchBox.module.css"

type Props = {
    placeHolder: string,
    name: string,
    onChange?: (value: string) => void
    type?: React.HTMLInputTypeAttribute,
    className?: string,
    focusedBorder?: string,
}

const SearchBox: React.FC<Props> = ({ type="text", placeHolder="", name, className, focusedBorder, onChange}) => {
    let [focused, setFocused] = useState<boolean>(false);

    const activeBorder = focusedBorder? focusedBorder : "1px solid #0064F9";
    const normalBorder = "1px solid #3D3D3D";

    const handleFocusEvent = (focused:boolean) => {
        setFocused(focused);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(onChange) onChange(e.target.value);
    }

    return (
        <div className={`${styles.inputBox} ${className} ${focused && styles.focus}`} 
            style={{border: focused? activeBorder : normalBorder}}
        >
            <input 
                type={type} name={name} placeholder={placeHolder} onChange={handleChange}
                onFocus={() => handleFocusEvent(true)} onBlur={() => handleFocusEvent(false)}
            />
            <div className={styles.btnSearch}></div>
        </div>
    )
}

export default SearchBox;