import styles from "../../styles/Login.module.css";
import InputBox from "../../components/InputBox";
import PassWordInput from "../../components/PasswordInput";
import LoaderDialog from "../../components/LoaderDialog";
import AlertDialog from "../../components/AlertDialog";
import axios, { /*AxiosResponse*/ } from 'axios'; // Import Axios and AxiosResponse types
import { v4 as uuid } from 'uuid';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { UserInfoType, setUserInfo } from "../../redux/slices/homeSlice";
import { DressType, defaultTemplate, populateDresses } from "../../redux/slices/userSlice"

import { setLocalStorage, clearLocalStorageItem, getLocalStorageItem } from "../../utils";

import { paths } from "../MainRouter"
import { useEffect, useState } from "react";

const title = "Hologlam"

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/users/login`;
//const LOGIN_URL = `https://brands.modamate.com/api/users/login`

type ResponseDataType = {
    message: string,
    accessToken: string,
    refreshToken: string,
    userInfo : UserInfoType,
    dresses: DressResponseType[]
}

type DressResponseType = {
    name: string,
    description: string,
    categories: string[],
    images: null|[],
    threeDModel: null|[],
    price: number,
    currency: string,
    mainMaterial: string,
    tags: string[],
    materials: any[],
    ratings: {rating: number, count: number},
    dateCreated: string,
    published: boolean,
    _id: string,
}

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    //loader states
    const [showLoader, setShowLoader] = useState(false)
    const [loaderMsg, setLoaderMsg] = useState("")

    // alert dialog
    const [showAlertDialog, setShowAlertDialog] = useState(false)
    const [alertDialogMsg, setAlertDialogMsg] = useState("")
    const [alertDialogTitle, setAlertDialogTitle] = useState("")

    const dispatch = useDispatch();

    // close Alert Dialog
    const closeAlertDialog = () => {
        setShowAlertDialog(false);
    }

    // close loader
    const hideLoader = () => {
        setShowLoader(false);
    }

    // show loader
    const displayLoader = () => {
        setLoaderMsg("Please wait...")
        setShowLoader(true)
    }

    // check if a user session already exists and use it to login
    useEffect(() => {
        setTimeout(() => {
            const refreshToken = getLocalStorageItem("refreshToken");
            if(refreshToken && refreshToken.length > 5) handleLogin(true);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // display alert dialog
    const displayAlertDialog = (title: string, message: string) => {
        setAlertDialogMsg(message);
        setAlertDialogTitle(title);
        setShowAlertDialog(true);
    }

    const handleLogin = async (session: boolean = false) => {
        const uname = username.trim();
        const pass = password;

        if(!session && (!uname || uname.length < 6 || !pass || pass.length <= 6)){
            return displayAlertDialog(
                    "Authentication failure",
                    "Invalid email / password combination. Please verify your credentials and try again!");
        }
    
        displayLoader();
        try {
            const refreshToken = getLocalStorageItem("refreshToken");  // Retrieve the refresh token from storage
            const headers = refreshToken ? { Authorization: `Bearer ${refreshToken}` } : {};

            const response = await axios.post(`${LOGIN_URL}`, {
                email: uname,
                password: pass,
            }, { headers });

            hideLoader();
    
            // If you need to specifically format it in your client-side code:
            const responseData = response.data as ResponseDataType
    
            setLocalStorage("accessToken", responseData.accessToken);
            setLocalStorage("refreshToken", responseData.refreshToken); 
            dispatch(setUserInfo(responseData.userInfo));
            loadDresses(responseData.dresses);
            navigate(paths.dashboard);
        } catch (error) {
            hideLoader();
            if (axios.isAxiosError(error)) {
                const status = error.response?.status;
                const message = error.response?.data?.message || "Login failed. Please try again.";
    
                if (status === 450) {
                    // Invalid token clear the tokens
                    clearLocalStorageItem("accessToken");
                    clearLocalStorageItem("refreshToken");
                    displayAlertDialog(
                        "Session expired",
                        "Sorry your login session has expired. You will need to login again to continue."
                    );
                } else if (status === 401) {
                    // Invalid email or password
                    displayAlertDialog(
                        "Authentication failure",
                        "Invalid email / password combination. Please check your credentials and try again!"
                    );
                } else if (status === 403) {
                    // Email not verified
                    displayAlertDialog("Email Verification Required", message);
                } else {
                    // Other types of errors
                    displayAlertDialog("Login failed", "We encountered an error while processing your request, please try again later.");
                }
            } else {
                // Non-Axios error (network issue, etc.)
                console.error('An unexpected error occurred:', error);
                displayAlertDialog("Error", "An unexpected error occurred. Please check your network connection and try again.");
            }
        }
    };

    //convert ISO 8601 date string to timestamp
    const convertDateToTimestamp = (dateString: string) => {
        const date = new Date(dateString);
        return date.getTime();
    }

    // populate users dresses from database
    const loadDresses = (responseData: ResponseDataType["dresses"]) => {
        const data = [...responseData];
        if(!data || data.length === 0) return;

        type dressInitType = {
            clothes: {
                colors: null | any[],
                threeD: null | any[],
            }
            attributes: any
            uuid: string
            itemID: string
            //[key: string]: any
        }

        const untypedDressArray: any[] = [];
        
        data.forEach((d) => {
            // fix attributes default formats
            if(d.materials.length === 0){
                d.materials = [...defaultTemplate.attributes.materials];
            }
            if(d.images && d.images.length === 0) d.images = null;
            if(d.threeDModel && d.threeDModel.length === 0) d.threeDModel = null;

            // match to dress structure
            const dress: dressInitType = {
                attributes:{
                    name: d.name, 
                    description: d.description, 
                    price: d.price,
                    currency: d.currency,
                    materials: d.materials,
                    mainMaterial: d.mainMaterial,
                    tags: d.tags,
                    categories: [d.categories],
                    dateCreated: convertDateToTimestamp(d.dateCreated),
                    ratings: d.ratings,
                    published: d.published
                },
                clothes:{
                    colors: d.images,
                    threeD: d.threeDModel
                },
                uuid: uuid(),
                itemID: d["_id"]
            }

            // clear _id returned by mongoDB
            if(dress.clothes.colors){
                dress.clothes.colors = dress.clothes.colors.map((d) => {
                    if(d.distanceFromCam){
                        const { _id, ...rest } = d.distanceFromCam;
                        d.distanceFromCam = {...rest};
                    }
                    if(d.measurements){
                        const { _id, ...rest } = d.measurements;
                        d.measurements = {...rest};
                    }
                    if(d.parameters){
                        const { _id, ...rest } = d.parameters;
                        d.parameters = {...rest};
                    }
                    const { _id, ...rest} = d
                    d = rest;
                    return d;
                })
            }
            untypedDressArray.push(dress);
        })

        const dresses: DressType[] = JSON.parse(JSON.stringify(untypedDressArray));
        dispatch(populateDresses(dresses));
        //console.log(dresses)
    }

    return (
        <>
        <div className={styles.container}>
            <div className={styles.leftBox}></div>
            <div className={styles.rightBox}></div>
            <form className={styles.form}>
                <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.l_box}>
                        <div className={styles.line}></div>
                        <div className={styles.t1}>Log in</div>
                        <div className={styles.line}> </div>
                    </div>
                    <InputBox 
                        className={styles.input} type="email" name="email" placeHolder="Email" required
                        onChange={(value)=>setUsername(value)}
                    />
                    <PassWordInput 
                        className={styles.input} name="password" placeHolder="Password" required
                        onChange={(value) => setPassword(value)}
                    />
                    <div className={styles.forgot_pwd}>Forgot password?</div>
                    <button type="button" className={styles.btnLogin} onClick={()=>handleLogin(false)}>Log in</button>
                    <button type="button" className={styles.btnBack} onClick={()=>navigate(paths.signup)}>Back</button>
                    <div className={styles.signup_box} onClick={()=>navigate(paths.signup)}>
                        Not a partner yet? <span>Become a partner here</span>
                    </div>
                </div>
            </form>
        </div>
        {showLoader &&
            <LoaderDialog message={loaderMsg}/>
        }
        {showAlertDialog && 
            <AlertDialog message={alertDialogMsg} title={alertDialogTitle} onDialogClose={closeAlertDialog}/>
        }
        </>
    )
}

export default Login;