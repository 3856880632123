import React, { useEffect, useState } from "react";

import styles from "../../../../styles/Dashboard/CMS/CustomInputs.module.css"

import { MeasurementsType } from "../../../../redux/slices/userSlice";

type UnitInputProps = {
    name: string;
    required: boolean;
    label: string;
    unit: string;
    onChange: <K extends keyof MeasurementsType>(key: K, value: MeasurementsType[K]) => void;
    defaultValue?: number;
    placeholder?: string;
    className?: string;
}


const UnitInputBox: React.FC<UnitInputProps> = ({
        label, name, required, unit, placeholder="", className, onChange, defaultValue
    }) => {

    const [focused, setFocused] = useState<boolean>(false);
    const [value, setValue] = useState("");

    const handleFocusChange = (focused: boolean) => {
        setFocused(focused);
        handleInputChange(String(Number(value)))
    }

    // handle change
    const handleInputChange  = (value: string) => {
        // Check if the new value is a valid float or empty
        if (/^-?\d*\.?\d*$/.test(value)) {
            // Remove leading zeros if the number is not just a zero or decimal like 0.x
            value = value.replace(/^(-?)0+(0\.|\d)/, '$1$2');
            setValue(value || '0');  // Set to '0' if the new value is empty
            if(onChange) onChange(name as keyof MeasurementsType, Number(value));
        }
    }

    // set the default value
    useEffect(() => {
        if(defaultValue!==undefined) setValue(String(defaultValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    return (
        <div 
            className={`${styles.UnitInputBox} ${styles.inputBox1} ${className && className} ${focused? styles.focused:""}`}
        >
            <div className={styles.label}>{label}:</div>
            <input 
                type="text" placeholder={placeholder} name={name} required={required} value={value}
                onFocus={()=>handleFocusChange(true)} onBlur={()=>handleFocusChange(false)}
                onChange={(e) => handleInputChange(e.target.value)}
            />
            <div className={styles.label}>{unit}</div>
        </div>
    )
}

export { UnitInputBox };