import React, { useState } from "react";
import styles from "../styles/components/SelectCountry.module.css";
import countries from "../assets/countries.json";
import { ChangeEvent } from "react";

type countryType = typeof countries[0]

type Props = {
    name: string,
    required: boolean;
    validate?: boolean;  //true if select can show red borders on no item selected
    onItemSelected: (item: countryType) => void;
}

const unselctedColor = 'grey';

const SelectCountry: React.FC<Props> = ({name, required, validate=false, onItemSelected}) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [color, setColor] = React.useState(unselctedColor);

    // Function to handle changing the value of color
    const changeColor = (index: number) => {
        setColor((index > 0) ? "black" : unselctedColor);
    };

    // handle selected item changed
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const index = e.target.selectedIndex;
        changeColor(index)
        setSelectedIndex(index);
        if(onItemSelected) onItemSelected(countries[index-1]);
    };

    return (
        <div className={`${styles.selectBox} ${(validate && selectedIndex === -1) && styles.error}`}>
            <select name={name} defaultValue="" className={styles.select} style={{color: color}}
                required={required} onChange={(e) => handleChange(e)}
            >
                <option key="-1" value="" disabled>Country</option>
                {countries.map((country, index) => (
                    <option key={index} value={country.code}>{country.name_common}</option>
                ))}
            </select>
        </div>
    )
}

export type { countryType }
export default SelectCountry;