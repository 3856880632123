// src/redux/slices/homeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HomeState {
    userInfo: UserInfoType,
    activeTabPath: string;
    homeTooltipInfo: {text: string, status:"info"|"error", show:boolean}
    addingNewItemState: {state: boolean, action:"adding"|"editing"}
    userExitingFromItemForm: boolean;
    saveTemplateTrigger: number;
    threeDClothAvailable: boolean;
    showUploadInstruction: boolean;
}

interface UserInfoType {
    name: string,
    email: string,
    telNumber: string,
    description: string,
    address: string,
    regNumber: string,
    profilePicture: string,
    role: 'brand'|'customer',
    brandId: string,
    isEmailVerified: boolean,
    createdAt: number,
}

const defaultUserInfo: UserInfoType = {
    name:"", email:"", telNumber:"", description:"", address:"", regNumber:"", profilePicture:"",
    role:'brand' as "brand"|"customer", brandId:"", isEmailVerified:false, createdAt: 0
}
const initialState: HomeState = {
    userInfo: {...defaultUserInfo},
    activeTabPath: "",
    homeTooltipInfo: {show: false, status:"info", text:""},
    addingNewItemState: {state: false, action:"adding"},
    userExitingFromItemForm: false,
    saveTemplateTrigger: 0,
    threeDClothAvailable: false,
    showUploadInstruction: true,
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setActiveTabPath: (state, action: PayloadAction<string>) => {
            state.activeTabPath = action.payload;
        },
        setHomeTooltipInfo: (state, action: PayloadAction<typeof initialState.homeTooltipInfo>) => {
            state.homeTooltipInfo = {...action.payload};
        },
        updateAddingNewItemState: (state, action: PayloadAction<typeof initialState.addingNewItemState>) => {
            state.addingNewItemState = {...action.payload};
        },
        setShowUploadInstructions: (state, action: PayloadAction<boolean>) => {
            state.showUploadInstruction = action.payload;
        },

        notifyUserExitingFromItemForm: (state, action: PayloadAction<boolean>) => {
            state.userExitingFromItemForm = action.payload;
        },

        // trigger saving of template
        triggerTemplateSave:(state) => {
            state.saveTemplateTrigger += 1;
        },

        // user info
        setUserInfo: (state, action: PayloadAction<UserInfoType>) => {
            state.userInfo = {...action.payload};
        },
        resetUserInfo: (state) => {
            state.userInfo = {...defaultUserInfo};
        },
        setThreeDAvailable: (state, action: PayloadAction<boolean>) => {
            state.threeDClothAvailable = action.payload;
        },
    },
});

export const { 
    setActiveTabPath, setHomeTooltipInfo, setUserInfo, resetUserInfo, triggerTemplateSave, setThreeDAvailable,
    updateAddingNewItemState, notifyUserExitingFromItemForm, setShowUploadInstructions
} = homeSlice.actions;

export type {UserInfoType }

export default homeSlice.reducer;
