import { ChangeEvent, useState } from "react"
import styles from "../styles/components/TextArea.module.css"

type Props = {
    placeHolder: string,
    required: boolean,
    name: string,
    validate?: boolean;  // only validate when this is true
    onChange?: (value: string) => void;
    maxLength?: number,
}

const TextArea = ({ placeHolder="", required, name, maxLength, validate=false, onChange} : Props) => {
    const [value, setValue] = useState("");
    
    // handle change
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const _value = e.target.value;
        setValue(_value);
        if(onChange) onChange(_value);
    }

    // check if input has any value
    const hasValue = () => {
        return value.length > 0;
    }

    return (
        <div className={`${styles.inputBox} ${(validate && required && !hasValue()) && styles.error}`}>
            <textarea 
                name={name} placeholder={placeHolder} required={required} spellCheck maxLength={maxLength}
                onChange={handleChange} value={value}>
            </textarea>
        </div>
    )
}

export default TextArea;