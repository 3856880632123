import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { setHomeTooltipInfo } from "../../../redux/slices/homeSlice";

import Header from "./Header";
import Navigation from "./Navigation";

import CMSHome from "./CMS/CMSHome";

import styles from "../../../styles/Dashboard/Home.module.css"
import { Outlet } from "react-router-dom";

const Home = () => {
    const dispatch = useDispatch();
    const {homeTooltipInfo, addingNewItemState} = useSelector((state: RootState) => state.home)
    const [addingItem, setAddingItem] = useState<boolean>(false);  // set to true to show ADDItem form

    /* monitor tooltip state and close an open tooltip after 5s */
    useEffect(() => {
        if(!homeTooltipInfo.show) return;
        setTimeout(() => {
            closeToolTip()
        }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeTooltipInfo])

    // monitor adding new item state
    useEffect(() => {
        const addingItem = addingNewItemState.state;
        if(addingItem){
            setAddingItem(true);
        }else{
            setAddingItem(false);
        }
    }, [addingNewItemState])

    // close tooltip
    const closeToolTip = () => {
        dispatch(setHomeTooltipInfo({...homeTooltipInfo, show:false, text:""}));
    }

    // Handle add item canceled 
    const addItemCancelled = () => {
        setAddingItem(false);
    }

    return (
        <div className={styles.container}>
            {homeTooltipInfo.show  &&
            <div className={styles.tooltip}>
                <div className={`${styles.icon} ${homeTooltipInfo.status === "error"? styles.neg:""}`}></div>
                <div className={styles.text}>{homeTooltipInfo.text}</div>
                <div className={styles.btnClose} onClick={closeToolTip}></div>
            </div>}
            <div className={styles.headerBox}>
                <Header />
            </div>
            {!addingItem && 
            <div className={styles.mainBox}>
                <div className={styles.navBox}>
                    <Navigation />
                </div>
                <div className={styles.contentBox}>
                    <Outlet />
                </div>
            </div>}
            {addingItem && 
                <CMSHome onCanceled={addItemCancelled}/>
            }
        </div>
    )
}

export default Home;