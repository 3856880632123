import React, { useEffect, useState } from "react"
import styles from "../styles/components/PasswordInput.module.css"

type Props = {
    placeHolder: string,
    required: boolean,
    name: string,
    className?: string,
    onChange?: (value: string) => void;
}

const PasswordInput = ({ placeHolder="", name, required, className, onChange} : Props) => {
    const [value, setValue] = useState("");
    let [focused, setFocused] = useState<boolean>(false);
    let [type, setType] = useState<React.HTMLInputTypeAttribute>("password");
    let [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        setType(visible? "text" : "password");
    }, [visible])

    const handleFocusEvent = (focused:boolean) => {
        setFocused(focused);
    }

    const toggleVisibility = () => {
        setVisible((state) => !state);
        setTimeout(() => {
            setVisible((state) => state?!state:false);
        }, 5000)
    }

    // handle input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if(onChange) onChange(e.target.value);
    }

    return (
        <div className={`${styles.inputBox} ${className} ${focused && styles.focus}`}>
            <input 
                type={type} name={name} placeholder={placeHolder} required={required} value={value}
                onFocus={() => handleFocusEvent(true)} onBlur={() => handleFocusEvent(false)}
                onChange={handleChange}
            />
            <div className={styles.btn_view} onClick={toggleVisibility}></div>
        </div>
    )
}

export default PasswordInput;