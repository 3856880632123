import { useState, memo, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "../../../styles/Dashboard/AccountInfo.module.css";

import InputDialog from "./InputDialog";
import { RootState } from "../../../redux/store";

type BrandInfoItem = {
    name: string;
    value: string;
    editable?: boolean;
    id: number
}

type AccountSettingsItem = {
    name: string;
    value: string;
    editable?: boolean;
    id: number
}

type CommunicationPermsItem = {
    name: string;
    value: string;
    enabled: boolean;
    id: number
}


const defaultBrandInfo: BrandInfoItem[] = [
    {name: "Brand name", value: "", editable: false, id:1},
    {name: "Registration number", value: "", editable: true, id:2},
    {name: "Phone number", value: "", editable: false, id:3},
    {name: "Address", value: "", editable: true, id:4}
];

const defaultAccountSettings: AccountSettingsItem[] = [
    {name: "Email", value: "info@gucci.com", editable: false, id:10},
    {name: "Password", value: "************", editable: false, id:11},
    {name: "Member since", value: "2024-04-15", editable: false, id:12}
]

const defaultCommPerms: CommunicationPermsItem[] = [
    {
        name: "Service updates via SMS", 
        value:"Enable if you want to recieve SMS on service updates", 
        enabled: true, id:20
    },
    {
        name: "Purchase emails", 
        value:"Enable if you want to be updated on every purchse via you email", 
        enabled: false, id:22
    }
]

/*---- Parent Component ----*/
const AccountInfo = memo(() => {
    const [brandName] = useState("Gucci");
    const [showInputDialog] = useState<boolean>(false);     // set to true to show confirm dialog

    /*useEffect(() => {
        setTimeout(() => {
            showToolTip("Work failed", false);
        })
    })*/

    const label: string = "Update username";

    const onSuccess = (value: string) => {
        console.log("Success:", value)
    }

    const onCancel = () => {
        console.log("Canceled");
    }
    return (
        <div className={styles.container}>
            <Header />
            <BrandInfo />
            <AccountSettings />
            <CommunicationPerms />
            {showInputDialog &&
            <InputDialog 
                placeHolder={brandName} required={false} onCancel={onCancel} onSuccess={onSuccess}
                label={label}
            />}
        </div>
    )
})


/*---- Header ----*/
const Header = () => {
    const { userInfo } = useSelector((state: RootState) => state.home);
    return (
        <div className={styles.header}>
            <div className={styles.heading1}>Account information</div>
            <div className={styles.line}></div>
            <div className={styles.heading2}>{userInfo.name}</div>
        </div>
    )
}

/*---- Brand Information ----*/
const BrandInfo = () => {
    const [items, setItems] = useState<BrandInfoItem[]>(defaultBrandInfo)
    const { userInfo } = useSelector((state: RootState) => state.home);

    useEffect(() => {
        let tmpItems = [...defaultBrandInfo];
        tmpItems = tmpItems.map((item) => {
            switch(item.id){
                case 1:{
                    return {...item, value:userInfo.name}  // brand name
                }
                case 2:{
                    return {...item, value:userInfo.regNumber}  // reg number
                }
                case 3:{
                    return {...item, value:userInfo.telNumber}  // phone number
                }
                case 4:{
                    return {...item, value:userInfo.address}  // address
                }
                default: {
                    return item;
                }
            }
        })
        setItems([...tmpItems]);
    }, [userInfo])

    
    return (
        <div className={`${styles.brandInfo} ${styles.contentBox}`}>
            <div className={styles.titleBox}>
                <div className={styles.title}>Brand information</div>
                <div className={styles.subTitle}>The details below will be shown on your invoice</div>
            </div>
            <div className={styles.itemsBox}>
                {items.map(({name, value, editable}, idx) => (
                    <div key={idx} className={styles.item}>
                        <div className={styles.label}>{name}</div>
                        <div className={styles.value}>{value}</div>
                        {editable && <div className={styles.btnEdit} title="Edit"></div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

/*---- Account settings ----*/
const AccountSettings = () => {
    const [items, setItems] = useState<AccountSettingsItem[]>(defaultAccountSettings)
    const { userInfo } = useSelector((state: RootState) => state.home);

    const timestampToDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since January is 0
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        let tmpItems = [...defaultAccountSettings];
        tmpItems = tmpItems.map((item) => {
            switch(item.id){
                case 10:{
                    return {...item, value:userInfo.email}  //email
                }
                case 12:{
                    return {...item, value:timestampToDate(userInfo.createdAt)}  // member since
                }
                default: {
                    return item;
                }
            }
        })
        setItems([...tmpItems]);
    }, [userInfo])

    return (
        <div className={`${styles.settings} ${styles.contentBox}`}>
            <div className={styles.titleBox}>
                <div className={styles.title}>Account Settings</div>
                <div className={styles.subTitle}></div>
            </div>
            <div className={styles.itemsBox}>
                {items.map(({name, value, editable}, idx) => (
                    <div key={idx} className={styles.item}>
                        <div className={styles.label}>{name}</div>
                        <div className={styles.value}>{value}</div>
                        {editable && <div className={styles.btnEdit} title="Edit"></div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

/*---- Communication permissions ----*/
const CommunicationPerms = () => {
    const [items, setItems] = useState<CommunicationPermsItem[]>(defaultCommPerms)

    const toggleSwitch = (idx: number) => {
        setItems(items.map((item, index) => {
            if (index === idx) item.enabled = !item.enabled
            return item;
        }))
    }

    return (
        <div className={`${styles.commPerms} ${styles.contentBox}`}>
            <div className={styles.titleBox}>
                <div className={styles.title}>Communication permissions</div>
                <div className={styles.subTitle}></div>
            </div>
            <div className={styles.itemsBox}>
                {items.map(({name, value, enabled}, idx) => (
                    <div key={idx} className={styles.item_2}>
                        <div className={styles.left}>
                            <div className={styles.label_2}>{name}</div>
                            <div className={styles.value_2}>{value}</div>
                        </div>
                        <div 
                            className={`${styles.right} ${enabled? styles.on : styles.off}`}
                            onClick={() => toggleSwitch(idx)} title={enabled? "Switch OFF" : "Switch ON"}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AccountInfo;