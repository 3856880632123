import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { getLocalStorageItem } from "../../../utils"
import axios, {  } from 'axios';

import { 
    notifyUserExitingFromItemForm, updateAddingNewItemState, setHomeTooltipInfo, 
} from "../../../redux/slices/homeSlice";
import { 
    TemplateClothChangeType, resetTemplate, setTemplateSavedState, setTemplateClothChangesState, persistSavedChanges
} from "../../../redux/slices/userSlice"
import { 
    TemplateChangeType, ClothesType, UnitType1, ParameterType, MeasurementsType, setTemplateItemID, 
    setTemplateAttributeCreateDate
} from "../../../redux/slices/userSlice";
import { resetUserInfo } from "../../../redux/slices/homeSlice";
import { paths } from "../../MainRouter";

import SearchBox from "../../../components/SearchBox";
import ConfirmDialog, { ConfirmBoxParamType, defaultConfirmBoxParams } from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";
import LoaderDialog from "../../../components/LoaderDialog";

import styles from "../../../styles/Dashboard/Header.module.css";
import { clearLocalStorageItem } from "../../../utils";

const ADD_ITEM_URL = process.env.REACT_APP_ADD_ITEM_URL;
const EDIT_ITEM_URL = process.env.REACT_APP_EDIT_ITEM_URL;
const ADD_ITEM_TIMEOUT_DURATION = 30 * 1000; // 30 seconds in milliseconds

type AddItemRequestDataType = {
    images: ClothesType["colors"];
    threeDModel: ClothesType["threeD"];
    brandId: string;
    name?: string;
    accessToken: string;
    description?: string;
    categories?: string[];
    tags?: string[];
    mainMaterial?: string;
    materials?: { name: string; percentage: number }[];
    currency?: string;
    price?: number;
};

type SecondaryItemPayloadType = {
    images: {
        colorValue: undefined|null|string
        images: undefined|null|{url: string, display: boolean}[]
        distanceFromCam: undefined|null|{
            value: number, unit: UnitType1
        },
        zoomLevel: undefined|null|number,
        setSameParameters: undefined|null|boolean,
        setSameMeasurements: undefined|null|boolean,
        parameters: undefined|null|ParameterType,
        measurements: undefined|null|MeasurementsType,
    }[]
    threeDModel: {
        front: undefined|null|{url: string}
        side: undefined|null|{url: string}
        back: undefined|null|{url: string};
    }[],
    accessToken: string;
}

type AddItemResponseType = {
    message:string, item: {
        id: string, brandId: string, createdAt: string, //2024-03-27T10:58:01.059Z
    }
}

type NavItem = {
    label: string;
    active: boolean;
}

const initNavItems: NavItem[] = [
    { label: "My Account", active: true },
    { label: "Billing", active: false },
    { label: "Support", active: false },
]

const Header = () => {
    const [naveItems, setNavItems] = useState<NavItem[]>(initNavItems)
    const { addingNewItemState, userInfo } = useSelector((state: RootState) => state.home);

    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>();
    const [confirmDialogParams, setConfirmDialogParams] = useState<ConfirmBoxParamType>(defaultConfirmBoxParams);

    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogTitle, setAlertDialogTitle] = useState("");
    const [alertDialogMsg, setAlertDialogMsg] = useState("");

    const [showLoader, setShowLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState("");

    const { userExitingFromItemForm, saveTemplateTrigger} = useSelector((state: RootState) => state.home)
    const { template, templateChanges, templateClothChanges, dresses } = useSelector((state: RootState) => state.users)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // display the loader
    const displayLoader = (message: string) => {
        setLoaderMsg(message);
        setShowLoader(true);
    }

    // hide Loader
    const hideLoader = () => {
        setShowLoader(false);
    }

    const setActiveNavItem = (_index: number) => {
        setNavItems(naveItems.map((item, index) => {
            if(_index === index) {
                item.active = true;
            }else{
                item.active = false;
            }
            return item;
        }))
    }

    //convert ISO 8601 date string to timestamp
    const convertDateToTimestamp = (dateString: string) => {
        const date = new Date(dateString);
        return date.getTime();
    }

    const addingNewItem = () => {
        return addingNewItemState.state;
    }

    const getActionBtnText = () => {
        if(addingNewItem()) {
            return "Publish Item";
        }
        return "Upload Item";
    }

    // handle clicking the publish button
    const handlePublish = () => {
        if(!addingNewItem()){
            // <publish item>
            dispatch(updateAddingNewItemState({state:true, action:"adding"}));
        }else{
            // Inventory component <upload item >
            setAlertDialogMsg(`When you publish an Item, it will be visible in the Hologlam App. You can't publish an
                    Item at the moment as our developers are currently building the Hologlam App. You will be notified
                    once the Hologlam App is up and running so that you can verify and publish your items.`);
            setAlertDialogTitle("Service pending");
            setShowAlertDialog(true);
        }
    }

    // show back confirm dialog
    const showBackConfirmDialog = () => {
        const params: ConfirmBoxParamType = {
            title: "Unsaved changes spotted!",
            message: `You have made some changes to this item, you will loose all unsaved changes if you leave now. 
                        Please save the form before leaving.`,
            negativeText: "Discard", positiveText: "Okay",
            onAccept: (msg) => {
                setShowConfirmDialog(false);
                dispatch(notifyUserExitingFromItemForm(false))
            },
            onDeny: (msg) => {
                // cancel adding and reset template
                setShowConfirmDialog(false)
                goBack();
            }
        }
        setConfirmDialogParams({...params});
        setShowConfirmDialog(true);
    }

    // show back confirm dialog
    const showLogoutConfirmDialog = () => {
        const params: ConfirmBoxParamType = {
            title: "Confirm action",
            message: `Are you sure you want to logout from this session, you will need to login to access your dashboard again.`,
            negativeText: "No", positiveText: "Sure",
            onAccept: (msg) => {
                setShowConfirmDialog(false);
                clearLocalStorageItem("accessToken");
                clearLocalStorageItem("refreshToken");
                dispatch(resetUserInfo());
                navigate(paths.login);
            },
            onDeny: (msg) => {
                setShowConfirmDialog(false);
            }
        }
        setConfirmDialogParams({...params});
        setShowConfirmDialog(true);
    }

    // go back
    const goBack = () => {
        dispatch(updateAddingNewItemState({action:"adding", state:false}))
        dispatch(setTemplateSavedState(true));
        dispatch(resetTemplate(true));
        dispatch(notifyUserExitingFromItemForm(false));
    }

    // no changes alert
    const showFormSavedAlert = () => {
        setAlertDialogTitle("");
        setAlertDialogMsg("No changes made! All changes have been saved.");
        setShowAlertDialog(true);
    }

    // no changes alert
    const showSessionExpiredAlert = () => {
        setAlertDialogTitle("Session Expired");
        setAlertDialogMsg("Sorry your session has expired, refresh your browser and try again.");
        setShowAlertDialog(true);
    }

    // timed out alert
    const showTimedOutAlert = () => {
        setAlertDialogTitle("Request timed out");
        setAlertDialogMsg("This request is taking too long. please check your internet connection and try again.");
        setShowAlertDialog(true);
    }

    // no changes alert
    const showInputErrorAlert = () => {
        setAlertDialogTitle("Errors in form");
        setAlertDialogMsg(`We encountered some errors in your form, please check that you entered correct 
                            data in all the fields and try again.`);
        setShowAlertDialog(true);
    }

    // no changes alert
    const showRequestErrorAlert = () => {
        setAlertDialogTitle("");
        setAlertDialogMsg("We encountered an error while trying to save your data. Please try again.");
        setShowAlertDialog(true);
    }

    // no changes alert
    const showInternalErrorAlert = () => {
        setAlertDialogTitle("Internal error");
        setAlertDialogMsg(`We encountered an internal error while trying to save your data. 
                Please try again later or contact us if error persists.`);
        setShowAlertDialog(true);
    }

    // no changes alert
    const showNotFoundErrorAlert = () => {
        setAlertDialogTitle("Not found");
        setAlertDialogMsg(`Sorry we couldn't find the item you are trying to edit, please refresh this page and try again.`);
        setShowAlertDialog(true);
    }

    // no changes alert
    const showSaveSuccessNotification = () => {
        dispatch(setHomeTooltipInfo({show: true, status:"info", text:"Item successfully saved to database!"}))
    }

    // set item name alert
    const showSetItemNameAlert = () => {
        setAlertDialogTitle("Name field is required");
        setAlertDialogMsg(`Before you save an Item you must provide a name (4 characters min) for the item. 
                    Please scroll to the bottom of this form and enter the \`Name\` field, just above \`Short description\`.`);
        setShowAlertDialog(true);
    }

    // listen for back click state changes
    useEffect(() => {
        if(!userExitingFromItemForm) return;
        if(template.saved) return goBack();
        showBackConfirmDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userExitingFromItemForm])

    // logout session
    const handleLogout = () => {
        showLogoutConfirmDialog();
    }

    /*---- listen for changes in form ----------*/
    useEffect(() => {
        let changes = false;
        type K = keyof TemplateChangeType
        Object.keys(templateChanges).forEach((key) => {
            if(templateChanges[key as K] !== null){
                changes = true;
            }
        })
        if(templateClothChanges.length === 0){
            changes = changes || false;     // no image changes
        }else{
            let change = false;
            templateClothChanges.forEach((item) => {
                change = change || Object.keys(item).some((key) => {
                    type keyType = keyof TemplateClothChangeType;
                    if((key as keyType) !== "threeD"){
                        return item[key as keyType] !== null;
                    }else{
                        type keyType2 = keyof TemplateClothChangeType["threeD"];
                        const threeDItem = item["threeD"];
                        return Object.keys(threeDItem).some((key2) => {
                            return threeDItem[key2 as keyType2] !== null
                        })
                    }
                })
            })
            dispatch(setTemplateClothChangesState(change));
            changes = changes || change;
        }
        //----if(changes) console.log({template, templateChanges, templateClothChanges, dresses})
        dispatch(setTemplateSavedState(!changes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateChanges, templateClothChanges, dresses])

    // listen for save triggers and attempt to save changes
    useEffect(() => {
        if(saveTemplateTrigger > 0) handleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveTemplateTrigger])

    // user wants to save template
    const handleSave = () => {
        if(template.saved) return showFormSavedAlert();
        const adding = addingNewItemState.action === "adding";
        let nameAvailable = true;
        if(adding){
            if(!templateChanges.name || templateChanges.name.length < 4) nameAvailable = false;
        }else{
            if(templateChanges.name && templateChanges.name.length < 4) nameAvailable = false;
        }
        if(!nameAvailable){
            return showSetItemNameAlert();
        }
        if(!getLocalStorageItem("accessToken")) return showSessionExpiredAlert();

        // add the new item
        addItemRequest();
    }

    // send add item request
    const addItemRequest = async () => {
        const editing = addingNewItemState.action === "editing";
        
        //console.log("templateChanges before:", templateChanges);
        const payload: AddItemRequestDataType = {
            accessToken: getLocalStorageItem("refreshToken")!, brandId: userInfo.brandId, images: [], threeDModel: []
        }

        // if editing name is to required so can be null, hence do not add if not changed
        if(!editing){
            payload.name = templateChanges.name!;
        }else{
            if(templateChanges.name) payload.name = templateChanges.name;
        }

        // Include optional fields in the payload if they are present
        if(templateChanges.description) payload.description = templateChanges.description;
        if(templateChanges.categories && templateChanges.categories[0].length > 0){
            payload.categories = [...templateChanges.categories[0]];
        }
        if(templateChanges.tags && templateChanges.tags.length > 0){
            payload.tags = [...templateChanges.tags];
        }
        if(templateChanges.mainMaterial) payload.mainMaterial = templateChanges.mainMaterial;
        if(templateChanges.materials) payload.materials = [...templateChanges.materials]
        if(templateChanges.currency) payload.currency = templateChanges.currency;
        if(templateChanges.price) payload.price = templateChanges.price;

        //images: ClothesType["colors"];
        //threeDModel: ClothesType["threeD"];
        const secondaryPayload: SecondaryItemPayloadType = {...JSON.parse(JSON.stringify(payload))};  // copy object

        // check for the presence of image fields - when adding new item
        if(templateClothChanges.length > 0) {
            type KeyType1 = keyof TemplateClothChangeType;
            templateClothChanges.forEach((cloth: TemplateClothChangeType, index) => {
                const tmpObj: any = {}
                Object.keys(cloth).forEach((key) => {
                    let k = key as KeyType1;
                    if(k === "threeD"){
                        let item  = {...cloth.threeD};
                        Object.keys(item).forEach((k) => {
                            type K = keyof typeof item;
                            if(item[k as K] == null) {
                                item = {...item, [k]:undefined}
                            }
                        })
                        secondaryPayload.threeDModel!.push({...item})
                    }else{
                        const item  = cloth[k];
                        tmpObj[k] = (item === null)? undefined : item;
                    }
                })
                secondaryPayload.images.push(tmpObj)
            })
        }
        // console.log({secondaryPayload})
    
        const headers = {
            'Content-Type': 'application/json', Authorization: `Bearer ${ secondaryPayload.accessToken }`
        };

        // set request url
        let requestURL = ""
        if(!editing){
            requestURL = ADD_ITEM_URL!
        }else{
            requestURL = `${EDIT_ITEM_URL}/${template.itemID}`;
        }
    
        displayLoader("please wait...");
        try {
            const response = await axios.post(`${requestURL}`, secondaryPayload, { 
                headers,
                timeout: ADD_ITEM_TIMEOUT_DURATION
            });

            /*---- Item has been saved ---*/
            hideLoader();
            const responseData = response.data as AddItemResponseType;
            handleItemSaved(responseData);

        } catch (error) {
            hideLoader();
            if (axios.isAxiosError(error)) {
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    // Handle timeout specific error
                    return showTimedOutAlert();
                }else{
                    const status = error.response?.status;
                    //const message = error.response?.data?.message || "Error adding clothing item.";
                    //const errors = error.response?.data?.errors || "Unknown Error"
                    console.log(error);
        
                    switch (status) {
                        case 420:
                            showInputErrorAlert();
                            break;
                        case 410:
                            showNotFoundErrorAlert();
                            break;
                        case 401:
                        case 403:
                            showSessionExpiredAlert();
                            break;
                        case 500:
                            showInternalErrorAlert();
                            break;
                        default:
                            showRequestErrorAlert();
                    }
                }
            } else {
                showInternalErrorAlert();
            }
        }
    };

    // handle item saved 
    const handleItemSaved = (responseData: AddItemResponseType) => {
        const editing = addingNewItemState.action === "editing";
        if(!editing){
            // saving
            dispatch(setTemplateItemID(responseData.item.id))
            dispatch(setTemplateAttributeCreateDate(convertDateToTimestamp(responseData.item.createdAt)))
            dispatch(persistSavedChanges("saving"));
            dispatch(updateAddingNewItemState({action:"editing", state:true}));
        }else{
            // editing
            dispatch(persistSavedChanges("editing"));
        }
        showSaveSuccessNotification();
        // console.log(dresses);
    }

    return (
        <>
        <div className={styles.container}>
            {addingNewItem() &&
            <div className={styles.btnBack} onClick={()=>dispatch(notifyUserExitingFromItemForm(true))}></div>}
            <div className={styles.title}>Hologlam</div>
            <nav>
                {!addingNewItem() && naveItems.map((item, idx) => (
                    <div key={item.label} className={`${styles.navItem} ${item.active && styles.active}`}
                        onClick={() => setActiveNavItem(idx)}>
                        {item.label}
                    </div>
                ))}
                {!addingNewItem() && 
                    <SearchBox type="search" name="search" placeHolder="Search" className={styles.searchBox}/>}
                {/* logout button */}
                {!addingNewItem() &&
                    <div className={styles.btnLogout} onClick={handleLogout}>Logout</div>}
                {addingNewItem() &&
                    <div 
                        className={`${styles.btnSave} ${template.saved?styles.saved:""}`} 
                        style={{marginLeft: addingNewItem()?"auto":undefined}} onClick={() => handleSave()}>Save
                    </div>}
                <div className={`${styles.btnUpdate} ${addingNewItem()?styles.editMode:""}`} onClick={()=>handlePublish()}>
                    {getActionBtnText()}
                </div>
            </nav>
        </div>
        {showConfirmDialog &&
        <ConfirmDialog {...confirmDialogParams}/>
        }
        {showAlertDialog &&
        <AlertDialog message={alertDialogMsg} title={alertDialogTitle} onDialogClose={()=>setShowAlertDialog(false)}/>
        }
        {showLoader &&
        <LoaderDialog message={loaderMsg} />
        }
        </>
    )
}

export default Header;