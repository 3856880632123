import React, { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateAddingNewItemState  } from "../../../../redux/slices/homeSlice";

import styles from "../../../../styles/Dashboard/CMS/AddItemDialog.module.css";
import CheckBox from "../../../../components/CheckBox";
import AlertDialog from "../../../../components/AlertDialog";
import MultiSelect, {SelectItemType, SelectedItemType} from "../../../../components/MultiSelect";


type checkBoxValue = "yes" | "no";
type Props = { 
    onDialogClose: () => void; 
    onCategorySelected: (categoryData: null | CategoryDialogResponse) => void;
    categoryInfo?: CategoryDialogResponse;
}

type CategoryDialogResponse = {
    category: SelectedItemType;
    threeD: ""|checkBoxValue;
}

const defaultItems: SelectItemType[] = [
    {label:"Women", value:"women", items:[
        {label:"T-shirt", value:"t-shirt"},
        {label:"Dresses", value:"dresses"},
        {label:"Trousers", value:"trousers"},
        {label:"Blouses", value:"blouses"},
        {label:"Skirts", value:"skirts"},
    ]},
    {label:"Men", value:"men", items:[
        {label:"T-shirt", value:"t-shirt"},
        {label:"Dresses", value:"dresses"},
        {label:"Trousers", value:"trousers"},
        {label:"Blouses", value:"blouses"},
        {label:"Skirts", value:"skirts"},
    ]}
]

const AddItemItemDialog: React.FC<Props> = ({onDialogClose, onCategorySelected, categoryInfo}) => {
    const {template} = useSelector((state: RootState) => state.users)
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState<string>("");
    const [alertDialogTitle, setAlertDialogTitle] = useState<string>("")

    const [groupValue, setGroupValue] = useState<"" | checkBoxValue>("")
    const [items] = useState<SelectItemType[]>(defaultItems);
    const [selectedCategoryInfo, setSelectedCategoryInfo] = useState<null|SelectedItemType>(null);

    const dispatch = useDispatch();

    const checkBoxToggled = (checked: boolean, value: string) => {
        const selectedValue = value as checkBoxValue;
        setGroupValue(selectedValue);
    }

    const onItemSelected = useCallback((info: SelectedItemType) => {
        setSelectedCategoryInfo(info as SelectedItemType);
    }, [])

    // check if editing and create category data
    /*useEffect(() => {
        const editing = (addingNewItemState.action === "editing");
        if(editing){
            handleNext();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])*/

    // check if there category info is available
    useEffect(() => {
        if(categoryInfo && (!(categoryInfo.category.selectIndex === 0 && categoryInfo.category.selectStack?.length))){
            setSelectedCategoryInfo(categoryInfo.category);
            setGroupValue(categoryInfo.threeD);
        }
    }, [categoryInfo])

    // close the dialog
    const handleCloseDialog = () => {
        if (onDialogClose) onDialogClose();
        if(template.category === null){
            dispatch(updateAddingNewItemState({action:"adding", state: false}))
        }
    }

    // show Alert dialog 
    const displayAlertDialog = (title: string, message: string) => {
        setAlertDialogTitle(title);
        setAlertDialogMessage(message);
        setShowAlertDialog(true);
    }

    // close Alert dialog
    const handleCloseAlertDialog = () => {
        setShowAlertDialog(false);
    }

    // Proceed to CMS (user clicked Next)
    const handleNext = () => {
        /*const editing = (addingNewItemState.action === "editing");
        if(editing){
            if (onCategorySelected) onCategorySelected(null)
            return;
        }*/

        if (!selectedCategoryInfo) {
            return displayAlertDialog("Select category", "You need to select a category to continue.");
        }
        const selectedCategoryData: CategoryDialogResponse = {
            category: selectedCategoryInfo,
            threeD: groupValue
        }
        if (onCategorySelected) onCategorySelected(selectedCategoryData);
    }

    return (
        <div className={styles.contentBox}>
            {showAlertDialog && 
                <AlertDialog message={alertDialogMessage} title={alertDialogTitle} onDialogClose={handleCloseAlertDialog}/>
            }
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.title}>Choose category of clothes</div>
                    <div className={styles.subtitle}>Select category from option below</div>
                </div>
                <MultiSelect 
                    name="category" label="Chose category" className={styles.input} required={false} items={items}
                    onItemSelected={onItemSelected} selectedInfo={categoryInfo?.category}
                />
                <div className={styles.threeDbox}>
                    <div className={styles.l1}>Add 3D clothing version?</div>
                    <div className={styles.checkBoxGroup}>
                        <CheckBox className={styles.checkbox} groupValue={groupValue} label="Yes" value="yes" onToggle={checkBoxToggled}/>
                        <CheckBox isChecked className={styles.checkbox} groupValue={groupValue} label="No" value="no" onToggle={checkBoxToggled}/>
                    </div>
                </div>
                <div className={styles.actionBox}>
                    <div className={styles.btnBack} onClick={handleCloseDialog}>Back</div>
                    <div className={styles.btnNext} onClick={handleNext}>Next</div>
                </div>
            </div>
        </div>
    )
}

export type { CategoryDialogResponse }
export { defaultItems as categoryItems }
export default AddItemItemDialog;