// src/router/MainRouter.tsx
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import SignUp from './screens/SignUp';
import Login from './screens/Login';
import Home from "./screens/DashBoard/Home";
import AccountInfo from './screens/DashBoard/AccountInfo';
import Inventory from './screens/DashBoard/Inventory';

import PrivateRoute from './PrivateRoute';

const paths = {
    login: "/login",
    signup: "/signup",
    dashboard: "/dashboard",
    accountInfo: "accountInfo",
    inventory: "inventory",
}

const MainRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to={paths.dashboard} />} />
                <Route path={paths.signup} element={<SignUp />} />
                <Route path={paths.login} element={<Login />} />
                <Route path={paths.dashboard} element={
                    // guard for authenticated users
                    <PrivateRoute>
                        <Home />  
                    </PrivateRoute>
                }>
                    <Route path="" element={<Navigate to={paths.accountInfo} />} />
                    <Route path={paths.accountInfo} element={
                        <PrivateRoute>
                            <AccountInfo />
                        </PrivateRoute>
                    } />
                    <Route path={paths.inventory} element={
                        <PrivateRoute>
                            <Inventory />
                        </PrivateRoute>
                    } />
                    <Route path="*" element={<Navigate to={paths.inventory} />} /> {/* No route match within Home redirects to Inventory */}
                </Route>
                <Route path="*" element={<Navigate to={paths.login} />} /> {/* General no route match redirects to Login */}
                {/* Additional routes can be defined here */}
            </Routes>
        </BrowserRouter>
    );
};

export {paths}
export default MainRouter;
