import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios, {  } from 'axios';
import {getLocalStorageItem} from "../../../utils"
import { TemplateType, deleteDressItem, populateTemplate } from "../../../redux/slices/userSlice";

import { setActiveTabPath, updateAddingNewItemState, setHomeTooltipInfo } from "../../../redux/slices/homeSlice";
import { paths } from "../../MainRouter";

import SearchBox from "../../../components/SearchBox";
import InputSelectGeneric from "../../../components/InputSelectGeneric";
import ConfirmDialog, {defaultConfirmBoxParams, ConfirmBoxParamType} from "../../../components/ConfirmDialog";
import AlertDialog from "../../../components/AlertDialog";
import LoaderDialog from "../../../components/LoaderDialog";

import styles from "../../../styles/Dashboard/Inventory.module.css";
import { RootState } from "../../../redux/store";

type InventoryHeaderProps = {
    onFilterFunctionChange: (func: null|((items: InventoryItemType[], value?:any) => InventoryItemType[]), param:any, id:number) => void
    filterCount: null|number
}

type SelectItem = { text: string; value: string }
type FilterByItem = { text: string; value: string }
/*type ShopItem = {
    published: boolean;
    name: string;
    stock: number;
    price: number;
    category: Array<Array<string>>;
    tags: Array<string>;
    ratings: {rating: number, count: number};
    date: number
}*/

const DELETE_ITEM_URL = process.env.REACT_APP_DELETE_ITEM_URL
const DELETE_ITEM_TIMEOUT_DURATION = 60 * 1000; // 30 seconds in milliseconds


/*---- Parent Component ----*/
const Inventory: React.FC = () => {
    const dispatch = useDispatch();
    const [pagerData, setPagerData] = useState<PagerDataType>(defaultPageData)
    const [filterCount, setFilterCount] = useState<null|number>(null)

    const inventoryRef = useRef<InventoryRefType>(null);
    // announce route
    useEffect(() => {
        dispatch(setActiveTabPath(paths.inventory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // handlePager Updates
    const handlePagerUpdates = (_pagerData: PagerDataType) => {
        setPagerData({..._pagerData})
    }

    const onFilterFunctionChanged = (func: null|((items: InventoryItemType[], value?:any) => InventoryItemType[]), param:any, id:number) => {
        if(inventoryRef.current) inventoryRef.current.filter(func, param, id);
    }

    // handle filter item count changes
    const onFilterCountChanged = (count: number) => {
        setFilterCount(count);
    }

    return (
        <div className={styles.container}>
            <Header 
                onFilterFunctionChange={(func, param, id)=>onFilterFunctionChanged(func, param, id)}
                filterCount={filterCount}
            />
            <Content onFilterCountChange={onFilterCountChanged} pagerData={pagerData} ref={inventoryRef}/>
            <Pager onPagerUpdated={handlePagerUpdates}/>
        </div>
    )
}

export default Inventory;


/*---- Header Component ----*/
const Header: React.FC<InventoryHeaderProps> = ({onFilterFunctionChange, filterCount}) => {
    const {dresses} = useSelector((state: RootState) => state.users)
    const [reset, setReset] = useState(false);

    const dispatch = useDispatch();

    const filterByItems: FilterByItem[] = [
        {text: "Date", value: "date"}, 
        {text: "Price", value: "price"}, 
        {text: "A-Z", value: "az"}, 
        {text: "Z-A", value: "za"}
    ]

    const defaultStockStatusFilterItems: SelectItem[] = [
        {text: "In stock", value: "in"}, { text: "Out of stock", value: "out"}
    ]

    const [filterItems] = useState<FilterByItem[]>(filterByItems)
    const [stockFilterItems]  = useState<SelectItem[]>(defaultStockStatusFilterItems)

    const resetFilter =  () => {
        setReset((state) => !state);
        onFilterFunctionChange(null, null, 0);
    }

    const addNewItem = () => {
        dispatch(updateAddingNewItemState({state:true, action:"adding"}))
    }

    // filter by category
    const categoryFilterFunction = (items: InventoryItemType[], _category:string): InventoryItemType[] => {
        return items.filter(({category}) => {
            let count = 0
            category.forEach((catArray) => catArray.forEach((cat) => {
                if(cat.toLowerCase() === _category.toLowerCase()) count += 1;
            }))
            return count > 0;
        })
    }

    // filter by date
    const dateFilterFunction = (items: InventoryItemType[]): InventoryItemType[] => {
        function sortObjectsByTimestamp (objectsArray: InventoryItemType[]) {
            // Sort the objects array based on the timestamp property
            objectsArray.sort((a, b) => b.date - a.date);

            // Return the sorted array
            return objectsArray;
        }
        return sortObjectsByTimestamp(items)
    }

    // filter by price
    const priceFilterFunction = (items: InventoryItemType[]): InventoryItemType[] => {
        function sortObjectsByTimestamp (objectsArray: InventoryItemType[]) {
            // Sort the objects array based on the timestamp property
            objectsArray.sort((a, b) => b.price - a.price);

            // Return the sorted array
            return objectsArray;
        }
        return sortObjectsByTimestamp(items)
    }

    // filter by a - z or z -a
    const a2zFilterFunction = (items: InventoryItemType[], order: "a-z"|"z-a"): InventoryItemType[] => {
        function sortObjectsByTimestamp (objectsArray: InventoryItemType[]) {
            // Sort the objects array based on the timestamp property
            objectsArray.sort((a, b) => {
                if(a.name.toLowerCase().trim() === b.name.toLowerCase().trim()) return 0
                if(order === "a-z"){
                    return (a.name.toLowerCase().trim() < b.name.toLowerCase().trim())?-1:1  // a - z
                }else{
                    return (a.name.toLowerCase().trim() < b.name.toLowerCase().trim())?1:-1  // z - a
                }
            });

            // Return the sorted array
            return objectsArray;
        }
        return sortObjectsByTimestamp(items)
    }

    // filter by in stock or out of stock
    const stockStateFilterFunction = (items: InventoryItemType[], inStock:boolean): InventoryItemType[] => {
        return items.filter(({stock}) => inStock? (stock > 0) : (stock <= 0))
    }

    // filter search query (name and description)
    const searchQueryFilterFunction = (items: InventoryItemType[], query:string): InventoryItemType[] => {
        return items.filter(({name}) => name.toLowerCase().trim().indexOf(query.toLowerCase().trim()) !== -1)
    }

    // returns number of items
    const getItemCount = () => {
        return dresses? dresses.length : 0
    }

    // returns the number of filter 
    const getFilterCount = () => {
        if(filterCount) return filterCount;
        return getItemCount()
    }

    // returns number of published items
    const getPublishedCount = () => {
        return dresses.reduce((prev, {attributes}) => {
            return attributes.published? prev+1 : prev
        }, 0)
    }

    // returns number of drafts
    const getDraftCount = () => {
        return dresses.reduce((prev, {attributes}) => {
            return attributes.published? prev : prev+1
        }, 0)
    }

    // get all categories
    const getAllCategory = () => {
        const listSet = new Set();
        dresses.forEach(({attributes, clothes}) => {
            if(attributes.categories){
                attributes.categories.forEach((tag) => tag.forEach((t) => listSet.add(t)))
            }
        })
        const list: {text:string, value:string}[] = []
        listSet.forEach((value) => list.push({text:value as string, value:value as string}))
        return list.map(({text, value}) => ({text: `${text.charAt(0).toUpperCase()}${text.substring(1)}`, value:value.toLowerCase()}));
    }

    // handle first filter
    const handleFilter1 = (text: string, value: string) => {
        type Values = "date"|"price"|"az"|"za"
        const v = value as Values;
        switch (v){
            case "date":
                onFilterFunctionChange(dateFilterFunction, null, 1);
                break;
            case "price":
                onFilterFunctionChange(priceFilterFunction, null, 1)
                break;
            case "az":
                onFilterFunctionChange(a2zFilterFunction, "a-z", 1)
                break
            case "za":
                onFilterFunctionChange(a2zFilterFunction, "z-a", 1)
                break;
        }
    }

    // handle second filter (category)
    const handleFilter2 = (text: string, value: string) => {
        onFilterFunctionChange(categoryFilterFunction, value, 2);
    }

    // handle third filter (in stock or out of stock)
    const handleFilter3 = (text: string, value: string) => {
        onFilterFunctionChange(stockStateFilterFunction, (value === "in"), 3);
    }

    // handle search filter
    const handleSearchChange = (query: string) => {
        onFilterFunctionChange(searchQueryFilterFunction, query, 4);
    }

    return (
        <div className={styles.Header}>
            <div className={styles.titleBox}>
                <div className={styles.title}>Product Items</div>
                <div className={styles.btnAdd} onClick={addNewItem}>Add new</div>
            </div>
            <div className={styles.summaryBox}>
                <div className={styles.c1}>All {getItemCount()}</div>
                <div className={styles.c2}>Published {getPublishedCount()}</div>
                <div className={styles.c3}>Drafts {getDraftCount()}</div>
            </div>
            <div className={styles.controlBox}>
                <SearchBox className={styles.search} name="search" placeHolder="Search product" 
                    focusedBorder="1px solid #0d0d0d" onChange={handleSearchChange}
                />
                <InputSelectGeneric 
                    name="filter" required={false} items={filterItems} defaultValue="Filter by"
                    boxClassName={`${styles.selectInputBox}`} inputClassName={styles.selectInput}
                    onChange={(text, value) => handleFilter1(text, value)} reset={reset}
                />
                <InputSelectGeneric 
                    name="category" required={false} items={getAllCategory()} defaultValue="Filter by Category"
                    boxClassName={`${styles.selectInputBox}`} inputClassName={styles.selectInput}
                    onChange={(text, value) => handleFilter2(text, value)} reset={reset}
                />
                <InputSelectGeneric 
                    name="stock-status" required={false} items={stockFilterItems} defaultValue="Select by stock status"
                    boxClassName={`${styles.selectInputBox}`} inputClassName={styles.selectInput}
                    onChange={(text, value) => handleFilter3(text, value)} reset={reset}
                />
                <div className={styles.btnFilter} onClick={()=>resetFilter()}>Clear filter</div>
                <div className={styles.item_count}>{getFilterCount()} items</div>
            </div>
        </div>
    )
}



/*---- Content ----*/
type ContentProps = {
    pagerData: PagerDataType,
    onFilterCountChange?: (count: number) => void;
}
type InventoryItemType = {
    name: string, category: Array<string[]>, published:boolean; price: number, tags: string[]
    ratings: {rating:number, count:number}, date: number, stock: number, uuid: string, currency: string
}
type InventoryRefType = {
    filter: (func: null|((items: InventoryItemType[], value?:any) => InventoryItemType[]), param: any, id: number) => void,
}
type InventoryFilterFunction = {
    func: (items: InventoryItemType[], value?:any) => InventoryItemType[]
    param: any;
    id: number
}
const Content = forwardRef<InventoryRefType, ContentProps>(({pagerData, onFilterCountChange}, ref) => {
    const {dresses, templateChanges, templateClothChanges} = useSelector((state: RootState) => state.users)
    const dispatch = useDispatch();
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [confirmDialogParams, setConfirmDialogParams] = useState<ConfirmBoxParamType>(defaultConfirmBoxParams)
    
    const [showLoader, setShowLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState("");

    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogTitle, setAlertDialogTitle] = useState("");
    const [alertDialogText, setAlertDialogText] = useState("");

    const [filterFunctions, setFilterFunctions] = useState<null|Map<number,InventoryFilterFunction>>(null);
    const filteredItemCount = useRef(0);

    
    useImperativeHandle(ref, () => ({
        filter: (func: null|((items: InventoryItemType[], value?:any) => InventoryItemType[]), param: any, id: number) => {
            if(func !== null) {
                setFilterFunctions((funcs) => new Map(funcs).set(id,{func, id,param}))
            }else{
                setFilterFunctions(null);
            }
        },
    }));

    // track filtered item count state and update parent
    useEffect(() => {
        if(onFilterCountChange) onFilterCountChange(filteredItemCount.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredItemCount.current])

    // getItemsToDisplay 
    const getDisplayIndexes = () => {
        const startIndex = (pagerData.currentPage-1) * parseInt(String(pagerData.itemsPerPage));
        const endIndex = pagerData.currentPage * parseInt(String(pagerData.itemsPerPage)) - 1;
        return {startIndex, endIndex}
    }

    // format date from UNIX timestamp
    function formatTimestamp(timestamp: number) {
        // Check if the timestamp is in seconds (10 digits or less)
        if (timestamp.toString().length <= 10) {
            timestamp *= 1000; // Convert Unix timestamp from seconds to milliseconds
        }
        const date = new Date(timestamp ); // Convert Unix timestamp to milliseconds
      
        const year = date.getFullYear();
        // Months are zero-based in JavaScript, add 1 for human-readable month
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        // Optional: Convert to 12-hour format
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const formattedHours = hours.toString().padStart(2, '0');
      
        return `${year}/${month}/${day} at ${formattedHours}:${minutes} ${ampm}`;
    }
      

    // returns tag string from array
    const getTagString = (tags: Array<string>) => {
        let tagString = "";
        tags.forEach((tag, idx) => {
            tagString += tag;
            if (idx !== (tags.length-1)) tagString += ", ";
        })
        return tagString;
    }

    // returns tag string from array
    const getCategoryString = (categories: Array<string>) => {
        let catString = "";
        categories.forEach((category, idx) => {
            catString += category;
            if (idx !== (categories.length-1)) catString += " > ";
        })
        return catString;
    }

    // check if item is part of page
    const displayItem = (index: number) => {
        const displayIndexes = getDisplayIndexes();
        return (index >= displayIndexes.startIndex) && (index <= displayIndexes.endIndex);
    }

    // hide Alert Dialog
    const hideAlertDialog = () => {
        setShowAlertDialog(false);
    }

    // display alert dialog
    /*const displayAlertDialog = (title: string, msg: string) => {
        setAlertDialogTitle(title);
        setAlertDialogText(msg);
        setShowAlertDialog(true);
    }*/

    // session error alert
    const showSessionExpiredAlert = () => {
        setAlertDialogTitle("Session Expired");
        setAlertDialogText("Sorry your session has expired, refresh your browser and try again.");
        setShowAlertDialog(true);
    }

    // timed out alert
    const showTimedOutAlert = () => {
        setAlertDialogTitle("Request timed out");
        setAlertDialogText("This request is taking too long. please check your internet connection and try again.");
        setShowAlertDialog(true);
    }

    // request error alert
    const showRequestErrorAlert = () => {
        setAlertDialogTitle("");
        setAlertDialogText("We encountered an error while trying to delete this item. Please try again.");
        setShowAlertDialog(true);
    }

    // internal error alert
    const showInternalErrorAlert = () => {
        setAlertDialogTitle("Internal error");
        setAlertDialogText(`We encountered an internal error while trying to delete this item. 
                Please try again later or contact us if error persists.`);
        setShowAlertDialog(true);
    }

    // not found alert
    const showNotFoundErrorAlert = () => {
        setAlertDialogTitle("Not found");
        setAlertDialogText(`Sorry we couldn't find the item you are trying to delete, please refresh this page and try again.`);
        setShowAlertDialog(true);
    }

    // hide loader
    const hideLoader = () => {
        setShowLoader(false);
    }

    // display loader
    const displayLoader = (msg: string) => {
        setLoaderMsg(msg);
        setShowLoader(true);
    }

    // returns true if the user has some clothes
    const userHasClothes = () => {
        /*return dresses.length > 0 && dresses[0] && dresses[0].clothes && dresses[0].clothes.colors &&
            dresses[0].clothes.colors?.length > 0*/
        return dresses && dresses.length > 0
    }

    // return the users clothes
    const getUsersClothes = (): Array<InventoryItemType> => {
        /*if(!dresses || dresses.length === 0 || !dresses[0].clothes || !dresses[0].clothes.colors ||
            !(dresses[0].clothes.colors.length > 0)) return []*/
        if(!dresses || dresses.length === 0) return [];

        let list = dresses.map(({attributes, clothes, uuid}) => {
            const name = attributes.name;
            const price = attributes.price;
            const published = attributes.published;
            const date = attributes.dateCreated;
            const ratings = {...attributes.ratings};
            const tags = attributes.tags;
            const stock = clothes?(clothes.colors?clothes.colors.length:0):0
            const category = attributes.categories;
            const currency = attributes.currency as string;
            return {name, category, date, price, published, ratings, stock, tags, uuid, currency}
        })

        if(filterFunctions && filterFunctions.size > 0){
            let outList = list;
            filterFunctions.forEach((map) => {
                outList =  map.func(outList, map.param)
            })
            filteredItemCount.current = outList.length;
            return outList;
        }else{
            filteredItemCount.current = list.length;
            return list;
        }
    }

    // show delete confirm dialog
    const showDelConfirmDialog = (uuid: string) => {
        const params:ConfirmBoxParamType = {
            message: "Are you sure you want to delete this item, all its content and images? this action can't be reversed.",
            negativeText: "No", positiveText: "Yes", title: "Confirm Delete", 
            onAccept: ((msg) => {
                setShowConfirmDialog(false);
                deleteItem(uuid, true);
            }),
            onDeny: ((msg) => {
                setShowConfirmDialog(false);
            })
        }
        setConfirmDialogParams({...params});
        setShowConfirmDialog(true);
    }

    // handle edit item
    const editItem = (uuid: string) => {
        //find item index
        const index = dresses.findIndex((dress) => dress.uuid === uuid);
        if(index === -1) return;

        const dress = dresses[index];
        const newTemplate: TemplateType = {
            attributes: dress.attributes,
            category: null,
            clothes: dress.clothes!,
            itemID: dress.itemID,
            uuid: dress.uuid,
            selectedColorIndex: 0,
            saved: true,
            clothChanges: false,
        }

        //console.log({dress, newTemplate, templateChanges, templateClothChanges});
        dispatch(populateTemplate(newTemplate));
        dispatch(updateAddingNewItemState({action:"editing", state:true}))
    }

    // delete an item
    const deleteItem = async (uuid: string, confirmed: boolean) => {
        const index = dresses.findIndex((dress) => dress.uuid === uuid);
        if(index === -1) return;

        const dress = dresses[index];

        if(!confirmed) return showDelConfirmDialog(uuid);

        const accessToken = getLocalStorageItem("refreshToken")

        const headers = {
            'Content-Type': 'application/json', Authorization: `Bearer ${ accessToken }`
        };

        // set request url
        let requestURL = `${DELETE_ITEM_URL}/${dress.itemID}`;
    
        displayLoader("please wait...");
        try {
            const response = await axios.post(`${requestURL}`, {} , { 
                headers,
                timeout: DELETE_ITEM_TIMEOUT_DURATION
            });

            /*---- Item has been deleted ---*/
            hideLoader();
            dispatch(setHomeTooltipInfo({show: true, status:"info", text:"Item successfully deleted"}));
            dispatch(deleteDressItem(uuid));
            const responseData = response.data
            console.log(responseData);
        } catch (error) {
            hideLoader();
            if (axios.isAxiosError(error)) {
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    // Handle timeout specific error
                    return showTimedOutAlert();
                }else{
                    const status = error.response?.status;
                    console.log(error);
        
                    switch (status) {
                        case 410:
                            showNotFoundErrorAlert();
                            break;
                        case 401:
                        case 403:
                            showSessionExpiredAlert();
                            break;
                        case 500:
                            showInternalErrorAlert();
                            break;
                        default:
                            showRequestErrorAlert();
                    }
                }
            } else {
                showInternalErrorAlert();
            }
        }
    }

    return (
        <>
        <div className={styles.Content}>
            <div className={styles.header}>
                <div className={styles.c_image}></div>
                <div className={styles.c_name}>Name</div>
                <div className={styles.c_stock}>Stock</div>
                <div className={styles.c_price}>Price</div>
                <div className={styles.c_category}>Category</div>
                <div className={styles.c_tags}>Tags</div>
                <div className={styles.c_ratings}></div>
                <div className={styles.c_date}>Date</div>
            </div>
            <div className={styles.itemsBox}>
                {userHasClothes() && getUsersClothes().map(({
                        name, date, stock, price, ratings, category, tags, uuid, currency
                    }, idx) => (
                    displayItem(idx)?
                    <div key={`${date}-${idx}`} className={styles.item}>
                        <div className={styles.i_image}>
                            <div className={styles.image_box}>
                                <div className={styles.image}></div>
                            </div>
                        </div>
                        <div className={styles.i_name}>
                            <div className={styles.item_name}>{name}</div>
                            <div className={styles.btn_box_1}>
                                <div className={styles.btn_edit_1} onClick={() => editItem(uuid)}>Edit</div>
                                <div className={styles.line_1}></div>
                                <div className={styles.btn_del_1} onClick={()=>deleteItem(uuid, false)}>Delete</div>
                            </div>
                        </div>
                        <div className={styles.i_stock}>{stock}</div>
                        <div className={styles.i_price}>{currency}{price}</div>
                        <div className={styles.i_category}>{getCategoryString(category[0])}</div>
                        <div className={styles.i_tags}>{getTagString(tags)}</div>
                        <div className={styles.i_ratings}>{`${ratings.rating} | ${ratings.count}`}</div>
                        <div className={styles.i_date}>{formatTimestamp(date)}</div>
                    </div>
                    :
                    null
                ))}
            </div>
        </div>
        {showConfirmDialog && 
            <ConfirmDialog {...confirmDialogParams} />
        }
        {showAlertDialog &&
            <AlertDialog message={alertDialogText} title={alertDialogTitle} onDialogClose={hideAlertDialog}/>
        }
        {showLoader &&
            <LoaderDialog message={loaderMsg}/>
        }
        </>
    )
})


/*---- Pager ----*/
type PagerProps = {
    onPagerUpdated: (pagerData: PagerDataType) => void
} 
type PagerDataType = {
    pageCount: number,
    currentPage: number,
    display: boolean
    itemsPerPage: number
}
const defaultPageData: PagerDataType  = {
    pageCount: 1, currentPage: 1, display: true, itemsPerPage: 5
}
const Pager: React.FC<PagerProps> = ({onPagerUpdated}) => {
    const {dresses} = useSelector((state: RootState) => state.users)
    const [pagerData, setPagerData] = useState<PagerDataType>(defaultPageData)

    // watch pager changes
    useEffect(() => {
        onPagerUpdated(pagerData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagerData])

    // update value
    const updatePagerValue = <K extends keyof PagerDataType>(key:K, value: PagerDataType[K]) => {
        setPagerData((data) => ({...data, [key]:value}))
    }

    // go too first page
    const goToFirstPage = () => {
        updatePagerValue("currentPage", 1);
    }

    // go to previous page
    const goToPreviousPage = () => {
        updatePagerValue("currentPage", Math.max(1,pagerData.currentPage-1));
    }

    // go to next page
    const goToNextPage = () => {
        updatePagerValue("currentPage", Math.min(pagerData.pageCount,pagerData.currentPage+1));
    }

    // go to last page
    const goToLastPage = () => {
        updatePagerValue("currentPage", pagerData.pageCount);
    }

    // set initial pager values
    useEffect(() => {
        let pageCount = parseInt(String(dresses.length/pagerData.itemsPerPage));
        pageCount += 1;
        updatePagerValue("pageCount", pageCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dresses])

    return (
        <div className={styles.Pager}>
            <div className={styles.actionBox}>
                <div className={`${styles.p_start} ${styles.p_btn}`} title="Page 1" onClick={goToFirstPage}></div>
                <div className={`${styles.p_back} ${styles.p_btn}`} title="Previous" onClick={goToPreviousPage}></div>
                <div className={styles.page}>{pagerData.currentPage} of {pagerData.pageCount}</div>
                <div className={`${styles.p_next} ${styles.p_btn}`} title="Next" onClick={goToNextPage}></div>
                <div className={`${styles.p_end} ${styles.p_btn}`} title="Last page" onClick={goToLastPage}></div>
            </div>
        </div>
    )
}