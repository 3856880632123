import React, { useRef, useState } from "react";

import styles from "../../../../styles/Dashboard/CMS/ColorPickerDialog.module.css";

import CheckBox, {CheckBoxRefType} from "../../../../components/CheckBox";

type Props = {
    subTitle?: string;
    onClose: () => void;
    onSubmit: (selectedColorInfo: SelectedColorType) => void;
    selectedColorIndexes?: number[];
}
type SelectedColorType = {
    colors: ColorType[];
    selectedIndexes: null|number[];
}

type ColorItemProp = {
    name: string;
    value: string;  //can be any valid css color value
    onToggle: (checked: boolean, value: string) => void
    className?: string;
}

type ColorType = {
    name: string; value: string
}

const defaultAvailableColors: ColorType[] = [
    {name: "White", value:"white"}, {name: "Red", value:"red"}, {name: "Sky blue", value:"skyblue"},
    {name: "Blue", value:"blue"}, {name: "Green", value:"green"}, {name: "Pink", value:"pink"},
    {name: "Yellow", value:"yellow"}, {name: "Black", value:"black"}, {name: "Brown", value:"brown"},
    {name: "Navy", value:"navy"}, {name: "Orange", value:"orange"}, {name: "Grey", value:"grey"},
    {name: "Chocolate", value:"chocolate"}, {name: "Cyan", value:"cyan"}

]

/*---- ColorPickerDialog (Main) ----*/
const ColorPickerDialog: React.FC<Props> = ({subTitle, onClose, onSubmit, selectedColorIndexes}) => {
    const [colors, setColors] = useState<ColorType[]>(defaultAvailableColors);
    const [selectedIndexes, setSelectedIndexes] = useState<null|number[]>(null)

    // close this dialog
    const closeDialog = () => {
        if (onClose) onClose();
    }

    // clear all selected colors
    const clearAll = () => {
        const oldColors = colors;
        setColors([]);
        setTimeout(() => {
            setColors(oldColors)
            setSelectedIndexes(null)
        })
    }

    // handle color item toggle
    const onItemToggle = (checked: boolean, value: string, index: number) => {
        if(checked){
            if(!selectedIndexes){
                setSelectedIndexes([index])
            }else{
                if(!selectedIndexes.includes(index)){
                    setSelectedIndexes([...selectedIndexes, index])
                }
            }
        }else{
            if(!selectedIndexes) return;
            if(!selectedIndexes.includes(index)) return;
            const idx = selectedIndexes.indexOf(index);
            const newList = [...selectedIndexes.slice(0, idx), ...selectedIndexes.slice(idx+1)];
            setSelectedIndexes(newList.length > 0? newList : null);
        }
    }

    // handle submit
    const handleSubmit = () => {
        if(onSubmit) onSubmit({colors, selectedIndexes})
    }

    // return false if this color has not been marked as selected
    const displayColor = (colorIndex: number) => {
        if(!selectedColorIndexes || selectedColorIndexes.length === 0) return true;
        return !selectedColorIndexes.includes(colorIndex)
    }

    return (
        <div className={styles.contentBox}>
            <div className={styles.content}>
                <div className={styles.btnClose} onClick={closeDialog}></div>
                <div className={styles.title}>Choose color</div>
                <div className={styles.subTitle}>{subTitle || "Select the available colors for the Item"}</div>
                <div className={styles.colorBox}>
                    {colors.map(({name, value}, idx) => ( displayColor(idx)?
                        <ColorItem 
                            key={idx} value={value} name={name} className={styles.c_item} 
                            onToggle={(checked, value) => onItemToggle(checked, value, idx)}
                        />
                        :
                        null
                    ))}
                </div>
                <div className={styles.actionBox}>
                    <div className={styles.btnClear} onClick={clearAll}>Clear all</div>
                    <div className={styles.btnSubmit} onClick={handleSubmit}>Submit</div>
                </div>
            </div>
        </div>
    )
}


/*---- ColorItem ----*/
const ColorItem: React.FC<ColorItemProp> = ({name, value, className, onToggle}) => {
    const checkBoxRef = useRef<CheckBoxRefType>(null);

    // handle checkbox toggle
    const handleToggle = (checked: boolean, value: string) => {
        if(onToggle) onToggle(checked, value)
    }

    // Invoke the toggle event
    const triggerToggle = () => {
        checkBoxRef.current?.toggle();
    }

    return (
        <div className={`${styles.ColorItem} ${className? className : ""}`} onClick={triggerToggle}>
            <div className={styles.color} style={{backgroundColor: value}}>{}</div>
            <div className={styles.colorName}>{name}</div>
            <CheckBox 
                iconStyle={styles.checkBoxIcon} label={""} className={styles.checkBox}
                value={value} onToggle={handleToggle} ref={checkBoxRef}
            />
        </div>
    )
}

export type {ColorType, SelectedColorType}
export {defaultAvailableColors as colors}
export default ColorPickerDialog;