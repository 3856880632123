
// Sorts an array of objects by their value property
import { AxiosResponse } from 'axios';
import { LocalStorageType } from "../types";
import { imageFetcher } from './imageFetcher';

import scaleImage from './scaleImageFile';

type ValueObject = {
  value: string;
  [key: string]: any;
};
const sortArrayByValue = (arr: ValueObject[]): ValueObject[] => {
    return arr.sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
    });
}

const isAxiosResponse = (message: any): message is AxiosResponse => {
  if(typeof message === "string") return false;
    return message &&
           'data' in message &&
           'status' in message &&
           'statusText' in message &&
           'headers' in message &&
           'config' in message;
}

// get item from local storage
const getLocalStorageItem = <K extends keyof LocalStorageType>(key: K): LocalStorageType[K]|null  => {
    return localStorage.getItem(key);
}

const setLocalStorage = <K extends keyof LocalStorageType>(key: K, value: LocalStorageType[K])  => {
    localStorage.setItem(key, value);
}

const clearLocalStorageItem = <K extends keyof LocalStorageType>(key: K) => {
    localStorage.removeItem(key);
}




export {
  sortArrayByValue, isAxiosResponse, getLocalStorageItem, setLocalStorage, clearLocalStorageItem , scaleImage, imageFetcher
} 