import React from 'react';
import MainRouter from './router/MainRouter';
import styles from './styles/App.module.css'; // Import CSS module

function App() {
  return (
    <div className={styles.appContainer}>
      <MainRouter />
    </div>
  );
}

export default App;