import React, { useEffect, useState } from "react";
import styles from "../styles/components/InputSelectGeneric.module.css";
import { ChangeEvent } from "react";

type Props = {
    name: string;
    required: boolean;
    defaultValue: string;
    items: {text:string, value:string}[]
    onChange?: (text:string, value:string) => void
    reset?: boolean
    boxClassName?: string,
    inputClassName?: string,
}

const unselectedColor = 'grey';

const SelectCountry: React.FC<Props> = ({
        name, required, defaultValue, items, boxClassName="", inputClassName="", onChange, reset
    }) => {

    const focusedBorder = "1px solid #3D3D3D";
    const normalBorder = "0.5px solid #3D3D3D";

    const [color, setColor] = useState(unselectedColor);
    const [focused, setFocused] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');

    // Function to handle changing the value of color
    const changeColor = (e: ChangeEvent<HTMLSelectElement>) => {
        const index = e.target.selectedIndex;
        setColor((index > 0) ? "black" : unselectedColor);
        if(onChange) onChange(items[index-1].text, items[index-1].value);
    };

    const handleFocus = (focused: boolean) => {
        setFocused(focused);
    }

    useEffect(() => {
        setSelectedOption("")
        setColor(unselectedColor)
    }, [reset])

    return (
        <div 
            className={`${styles.selectBox} ${boxClassName} ${focused && styles.focus}`} 
            style={{border: focused? focusedBorder : normalBorder}}
        >
            <select name={name} className={`${styles.select} ${inputClassName}`} style={{color: color}}
                required={required} onChange={(e) => {changeColor(e); setSelectedOption(e.target.value)}} 
                onFocus={() => handleFocus(true)} value={selectedOption}
                onBlur={() => handleFocus(false)}
            >
                <option key="-1" value="" disabled>{defaultValue}</option>
                {items.map((item, index) => (
                    <option key={new Date().getMilliseconds()+index} value={item.value}>{item.text}</option>
                ))}
            </select>
        </div>
    )
}

export default SelectCountry;