import React, { useState, useEffect, useRef, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import axios, { AxiosResponse } from 'axios';

import styles from "../styles/components/SignUpForm.module.css";
import { isAxiosResponse } from "../utils";

import InputBox from "./InputBox";
import TextArea from "./TextArea";
import SelectCountry, { countryType } from "./SelectCountry";
import SelectCountryCode from "./SelectCountryCode";
import AlertDialog from "./AlertDialog";
import ConfirmDialog, {ConfirmBoxParamType, defaultConfirmBoxParams} from "./ConfirmDialog";

import LoaderDialog from "./LoaderDialog";

import { paths } from "../router/MainRouter";

interface Env {
    REACT_APP_API_URL: string;
    REACT_APP_API_KEY: string;
}
  
const env: Env = {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL!,
    REACT_APP_API_KEY: process.env.REACT_APP_API_KEY!,
};

type Props = {
    showDialog: (display: boolean) => void;
    className?: string;
}

const defaultValues = {
    companyName: "",
    email: "",
    countryCode: "",
    tel: "",
    country: "",
    message: "",
}
type valuesType = typeof defaultValues;
type valuesKeyType = "companyName"|"email"|"countryCode"|"tel"|"country"|"message"

const LoginForm = ({showDialog, className}: Props) => {
    const navigate = useNavigate();
    const [width, setWidth] = useState(0);
    const elementRef = useRef<HTMLFormElement | null>(null); // Reference to the element
    const [values, setValues] = useState(defaultValues);
    const [validateCountry, setValidateCountry] = useState(false); // only start validating if set to true
    const [validateMessage, setValidateMessage] = useState(false); // only start validating if set to true
    
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMsg, setAlertDialogMsg] = useState("");
    const [alertDialogTitle, setAlertDialogTitle] = useState("");

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [confirmBoxParams, setConfirmBoxParams] = useState<ConfirmBoxParamType>(defaultConfirmBoxParams);

    const [errorMsg, setErrorMsg] = useState("");
    const [, setFormHasErrors] = useState(false);

    const [showLoader, setShowLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState("");

    const [formSubmitted, setFormSubmitted] = useState(false);

    const title = "Become a partner";
    const text = `Send us an inquiry to become partners and our support team will reply within
                    24 hours to discuss on the partnership.`;

    const nameRegex = /^.{1,60}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const telRegex = /^\d{4,20}$/;
    const messageMaxLength = 2000;
    const maxNameLength = 60;
    const maxNumberLength = 20;

    // track window size changes and update country code input width
    useEffect(() => {
        window.addEventListener('resize', updateWidth); // Add resize listener
        updateWidth(); // Set initial width
    
        return () => window.removeEventListener('resize', updateWidth); // Cleanup
    }, []);

    // initialize countryCode input width
    useEffect(() => {
        if(elementRef.current) setWidth(elementRef.current.offsetWidth);
    }, [])

    // update country code input box width
    const updateWidth = () => {
        if (elementRef.current) {
          setWidth(elementRef.current.offsetWidth); // Use offsetWidth to get the width
        }
    };

    // default function to show alert dialog
    const displayAlertDialog = (title: string, message: string) => {
        setAlertDialogMsg(message);
        setAlertDialogTitle(title);
        setTimeout(() => {
            setShowAlertDialog(true);
        }, 100)
    }

    // close AlertDialog
    const closeAlertDialog = ()=> {
        setShowAlertDialog(false);
        setAlertDialogMsg("");
        setAlertDialogTitle("");
    }

    // function to showLoader
    const displayLoader = (message: string) => {
        setLoaderMsg(message);
        setShowLoader(true);
    }

    // display the confirm dialog
    const displayConfirmDialog = (params: ConfirmBoxParamType) => {
        setConfirmBoxParams({...params})
        setShowConfirmDialog(true);
    }

    // dismiss the confirm dialog
    const dismissConfirmDialog = () => {
        setShowConfirmDialog(false);
    }

    // function to show confirm dialog
    const displayDuplicationConfirmDialog = () => {
        const params: ConfirmBoxParamType = {
            title: "You are good to go!",
            message: `Your partnership request has already been sent, we'll get back to you via email shortly. Do you want
                        to resend another partnership request request?`,
            negativeText: "Yes", positiveText:"No",
            onAccept: () => {
                dismissConfirmDialog();
                return;
            },
            onDeny: () => {
                dismissConfirmDialog();
                displayAlertDialog("", `You can now Resend your request. ensure that there is enough reason for you 
                    to send multiple partnership request`);
                setFormSubmitted(false);
                return;
            }
        }
        displayConfirmDialog(params);
    }

    // hide loader
    const dismissLoader = () => {
        setShowLoader(false);
    }

    // formatter for company name
    const nameFormatter = (value: string) => {
        return value.replace(/\s+/g, " ");
    }

    // formatter for number
    const numberFormatter = (value: string) => {
        return value.replace(/[^0-9]/g, "");
    }

    // update values state
    const updateValues = (key: valuesKeyType, value: string) => {
        setValues({...values, [key]:value});
    }

    // handle country selected
    const countrySelected = (country: countryType) => {
        updateValues("country", country.name_common);
    }

    // show a dialog for error found in form
    const showFormErrorDialog = (msg?:string) => {
        setAlertDialogMsg(msg?msg:errorMsg);
        setAlertDialogTitle("Form has errors!");
        setTimeout(() => {
            setShowAlertDialog(true);
        }, 100)
    }


    // check if all data is valid
    const isDataValid = async (): Promise<{valid:boolean, errorMsg: string}> => {
        setErrorMsg("");
        setFormHasErrors(false);

        let wait = () => {
            return new Promise<boolean>((resolve) => {
                setTimeout(() => resolve(true), 100);
            });
        }

        await wait();

        if(
            !nameRegex.test(values.companyName) ||
            !emailRegex.test(values.email) ||
            !telRegex.test(values.tel) ||    /*TODO: Work on the error that occurs when you negate this line*/
            values.message.length === 0
        ){
            return {
                errorMsg: "Oops! You made some error in your form. Please correct the error in the red box(es) and try again.",
                valid: false
            }
        }

        // check for country code error (may never happen)
        if(!values.countryCode){
            return {
                errorMsg: `A country code is required. Please select a country code from the country code dropdown 
                            and try again.`,
                valid: false
            }
        }

        // check for country error
        if(!values.country){
            return {
                errorMsg: "A Country is required. Please select a country from the country dropdown and try again.",
                valid: false
            }
        }
        return {errorMsg:"", valid:true};  // form is valid
    }

    // handle submit
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        displayLoader("Processing...");
        e.preventDefault();

        if(formSubmitted){
            dismissLoader();
            displayDuplicationConfirmDialog();
            return;
        }

        setValidateCountry(true);  // can now validate country
        setValidateMessage(true);  // can now validate message
        
        await new Promise((resolve) => setTimeout(() => resolve(true), 10));
        const {errorMsg, valid} = await isDataValid();

        dismissLoader();
        if(!valid){
            setErrorMsg(errorMsg);
            await new Promise((resolve) => setTimeout(() => resolve(true), 10));
            showFormErrorDialog(errorMsg);
            return;
        }

        // form is valid
        let payLoad = {...values, email:"obentech@gmail.com"}
        /*axios.post(`http://localhost:3005/api/users/partners`)
            .then(response => console.log(response))
            .catch(error => console.error(error));*/
        displayLoader("Please wait...");
        sendPartnershipRequest(
            payLoad,
            (data) => {
                // success
                dismissLoader();
                console.log("Success");
                showDialog(true);   // show success dialog
                setFormSubmitted(true);
                return;
            },
            (message) => {
                // error
                dismissLoader();
                if (isAxiosResponse(message)){
                    const errorData = message.data;
                    if(!errorData.errors && errorData.errors.length > 0 && errorData.errors[0].type === "field"){
                        showFormErrorDialog(
                            `Sorry, we encountered a problem while validating your request. Check that you provided
                                all fields in the form correctly and try again.`
                        )
                    }else{
                        showFormErrorDialog(
                            `Sorry, we encountered a problem while validating your request. Check that you provided
                                all fields in the form correctly and try again later!`
                        )
                    }
                    return;
                }
                
                console.log(`Error: ${message}`)
                showFormErrorDialog(
                    `Sorry, we encountered a problem while validating your request. Check that you provided
                        all fields in the form correctly and try again later!`
                )
                
            }
        ).catch((reason) => {
            console.log("Fatal Error: ", reason)
            showFormErrorDialog(
                `Sorry, we encountered a technical error while sending your request. Try again later of please contact us
                    if the error persist`
            )
        })
    }

    /**
     * Sends a partnership request to the server.
     * 
     * @param {Object} requestData - The data to be sent in the partnership request.
     * @param {function} onSuccess - Callback function to execute on successful request.
     * @param {function} onError - Callback function to execute on request error.
     */
    const sendPartnershipRequest = async (
        requestData: valuesType,
        onSuccess: (data: any) => void,
        onError: (message: string|AxiosResponse<any, any>) => void
    ) => {
        try {
            console.log(env.REACT_APP_API_URL);
            const response = await axios.post(`${env.REACT_APP_API_URL}/users/partners`, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${env.REACT_APP_API_KEY}`,
                },
            });
    
            if (response.status === 201) {
                onSuccess(response.data);
            } else {
                onError(`Received unexpected status code: ${response.status}`);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if(error.response.data){
                        onError(error.response.data);
                        return;
                    }else{
                        onError("");
                    }
                    /*console.error("Error data:", error.response.data);
                    console.error("Error status:", error.response.status);*/
                    
                } else if (error.request) {
                    console.error("No response received:", error.request);
                    onError("No response received from the server.");
                } else {
                    console.error("Error:", error.message);
                    onError(error.message);
                }
            } else {
                console.error("An unexpected error occurred:", error);
                onError("An unexpected error occurred");
            }
        }
    };

    
    return (
        <div className={`${styles.container} ${className?className:""}`}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
            <form className={styles.formContainer} ref={elementRef} onSubmit={handleSubmit}>
                <InputBox type="text" name="company" placeHolder="Company name" required maxLength={maxNameLength}
                    pattern={{pattern:nameRegex, errorMsg:"Enter a valid company name"}} formatter={nameFormatter}
                    onChange={(value) => updateValues("companyName", value)}
                />
                <InputBox type="email" name="email" placeHolder="Email" required 
                    pattern={{pattern:emailRegex, errorMsg:"Enter a valid Email"}} 
                    onChange={(value) => updateValues("email", value)}
                />
                <div className={styles.telBox}>
                    <SelectCountryCode 
                        className={styles.left} name="code" width={`${width}px`} required
                        onChange={(countryCode) => updateValues("countryCode", countryCode)}
                    />
                    <InputBox className={styles.right} type="tel" name="tel" placeHolder="Phone number" 
                        required pattern={{pattern:telRegex, errorMsg:"Enter a valid Tel Number"}} 
                        formatter={numberFormatter} maxLength={maxNumberLength}
                        onChange={(value) => updateValues("tel", value)}
                    />
                </div>
                <SelectCountry name="country" required onItemSelected={countrySelected} validate={validateCountry}/>
                <TextArea placeHolder="Message" name="message" required maxLength={messageMaxLength}
                    onChange={(value) => updateValues("message", value)} validate={validateMessage}/>
                <input type="submit" className={styles.btnSubmit}/>
                <div className={styles.loginBox}>
                    <div className={styles.info}>Already have an account? &nbsp;
                        <span onClick={()=> navigate(paths.login)}>Log in</span>
                    </div>
                </div>
            </form>
            {showAlertDialog && 
            <AlertDialog message={alertDialogMsg} title={alertDialogTitle} onDialogClose={closeAlertDialog}/>}
            {showLoader &&
            <LoaderDialog message={loaderMsg}/>}
            {showConfirmDialog &&
            <ConfirmDialog {...confirmBoxParams}/>}
        </div>
    )
}

export default LoginForm;