import React, { useState, useRef, useEffect, memo } from 'react';
import styles from '../styles/components/MultiSelect.module.css';

type Props = {
    label: string;
    name: string;
    required: boolean;
    items: SelectItemType[];
    selectedInfo?: SelectedItemType;  // set the select state
    onItemSelected: (info: SelectedItemType) => void
    className?: string;
    width?: string;
}

type SelectItemType = {
    label: string;
    value: string;
    items?: SelectItemType[];
}

type SelectedItemType = {
    selectStack: number[] | null;
    selectIndex: number;
}

const MultiSelect: React.FC<Props> = memo(({ name, className, width, label, items, onItemSelected, selectedInfo}) => {
    const [activeItem, setActiveItem] = useState<SelectItemType[]>(items);  // based on navigation
    const [selectStack, setSelectStack] = useState<null | number[]>(null);  // based on navigation
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SelectItemType | null>(null);
    const [selectedItemList, setSelectedItemList] = useState<SelectItemType | null>(null);
    const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
    const [currentItemInfo, setCurrentItemInfo] = useState<null|SelectedItemType>(null)
    const dropdownRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(selectedInfo){
            setCurrentItemInfo(selectedInfo);
            let activeItem = items;
            selectedInfo.selectStack?.forEach((index) => {
                if (activeItem[index] && activeItem[index].items){
                    activeItem = activeItem[index].items as SelectItemType[];
                }
            })
            setSelectedItem(activeItem[selectedInfo.selectIndex]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInfo])

    // watch item selection
    useEffect(() => {
        if(selectedItemIndex === -1) return;
        const currentItem: SelectedItemType = {selectIndex: selectedItemIndex, selectStack: selectStack}
        onItemSelected(currentItem)
        setCurrentItemInfo(currentItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, selectStack])

    // monitor list open state
    useEffect(() => {
        if (isOpen) {
            if(currentItemInfo && currentItemInfo.selectStack && currentItemInfo.selectStack.length > 0){
                let activeItem = items;
                currentItemInfo.selectStack.forEach((index) => {
                    if (activeItem[index] && activeItem[index].items){
                        activeItem = activeItem[index].items as SelectItemType[];
                    }
                })
                setSelectStack(currentItemInfo.selectStack);
                setActiveItem(activeItem);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    // open or close select input
    const handleToggleSelect = () => {
        setIsOpen(!isOpen);
    }

    // Function to handle the selection of a country
    const handleSelect = (item: SelectItemType, index: number) => {
        if(item.items) {
            setSelectedItemIndex(-1);
            if(selectStack) {
                setSelectStack([...selectStack, index])
            }else{
                setSelectStack([index]);
            }
            setActiveItem(item.items);
            setSelectedItemList(item);
        }else{
            setSelectedItem(item);
            setSelectedItemIndex(index);
            setIsOpen(false); // Close the dropdown
        }
    };

    // Handle backward list navigation
    const goBack = () => {
        if(!selectStack) return;
        setSelectedItemIndex(-1)
        if(selectStack.length === 1){
            setActiveItem(items);
            setSelectStack(null);
        }else{
            const currentStack = [...selectStack.slice(0, -1)]
            let targetItems: SelectItemType[]  = items;
            currentStack.forEach((index) => {
                if(targetItems[index].items) {
                    targetItems = targetItems[index].items as SelectItemType[];
                }
            })
            setActiveItem (targetItems);
            setSelectStack(currentStack);
        }
    }

    // Click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && !(event.target === selectRef.current)) {
                setIsOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    return (
        <div className={`${styles.selectContainer} ${className}`}>
            <div className={`${styles.selectBox} ${isOpen && styles.active}`} onClick={handleToggleSelect} 
                data-name={name} ref={selectRef} 
            >
                {selectedItem ? (selectedItem.label) : (label)}
            </div>
            {isOpen && (
                <div className={styles.dropdown} ref={dropdownRef} style={{width:width}}>
                    {(selectStack !== null) && // show header
                        <div className={styles.dropdownHeader}>
                            <div className={styles.btnBack} onClick={goBack}></div>
                            {selectedItemList?.label}
                        </div>}
                    {activeItem.map((item, index) => { // iterate via items
                        let {label, items} = item;
                        return (
                            <div key={index} className={`${styles.dropdownItem} ${items && styles.hasItems}`} 
                                onClick={() => handleSelect(item, index)} 
                            >
                                {label}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
  );
});

export type { SelectItemType, SelectedItemType }
export default MultiSelect;
