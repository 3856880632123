import React from 'react';
import styles from '../styles/components/LoaderDialog.module.css'; // Create a CSS module for styling

type Props = {message: string}

const LoaderDialog: React.FC<Props> = ({ message }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
        <p className={styles.loaderMessage}>{message}</p>
      </div>
    </div>
  );
};

export default LoaderDialog;
