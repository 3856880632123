import React, { useState, useRef, useEffect } from 'react';
import styles from '../styles/components/SelectCountryCode.module.css';
import countries from '../assets/countries.json';

type Country = {
  name_common: string;
  code: string;
  flag_svg: string;
  flag_png: string;
};

type Props = {
  name: string,
  required: boolean,
  className: string,
  width?: string
  onChange?: (countryCode: string) => void;
};

const SelectCountry = ({ name, required, className, width, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Function to handle the selection of a country
  const handleSelect = (country: Country) => {
    setSelectedCountry(country);
    if(onChange) onChange(country.code);
    setIsOpen(false); // Close the dropdown
  };

  // trigger onChangeCall for default selected country code (+1);
  useEffect(() => {
    setTimeout(() => {
      if(onChange) onChange(selectedCountry.code)
    }, 100)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  return (
    <div className={`${styles.selectContainer} ${className}`}>
      <div className={styles.selectBox} onClick={() => setIsOpen(!isOpen)} data-name={name}>
        {selectedCountry ? (
          <>
            <img src={selectedCountry.flag_png} alt={selectedCountry.name_common} className={styles.flagIcon} />
            {selectedCountry.code}
          </>
        ) : (
          'Country code'
        )}
      </div>
      {isOpen && (
        <div className={styles.dropdown} ref={dropdownRef} style={{width:width}}>
          {countries.map((country, index) => (
            <div key={index} className={styles.dropdownItem} onClick={() => handleSelect(country)}>
              <img src={country.flag_png} alt={country.name_common} className={styles.flagIcon} />
              {country.code} ({country.name_common})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCountry;
