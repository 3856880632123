import React, { useImperativeHandle, forwardRef, useEffect, useState} from "react";

import styles from "../styles/components/CheckBox.module.css"

type Props = {
    label: string,
    value: string,
    onToggle: (checked: boolean, value: string) => void;
    isChecked?: boolean;
    groupValue?: string;    // if this checkbox is part of a group group value must match value before it is checked
    className?: string;
    labelStyle?: string;
    iconStyle?: string;


}

type CheckBoxRefType = {
    toggle: () => void,
    check: () => void,
    uncheck: () => void,
}


const CheckBox = forwardRef<CheckBoxRefType, Props>(({
        label, value, labelStyle="", iconStyle="", onToggle, groupValue, className, isChecked
    }, ref) => {
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        if(isChecked) {
            setChecked(true);
        }else{
            setChecked(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(!groupValue) return;
        if(groupValue !== value) {
            setChecked(false);
        }else{
            setChecked(true);
        }
    }, [groupValue, value])

    // Use `useImperativeHandle` to expose specific functions to the parent component
    useImperativeHandle(ref, () => ({
        toggle, check, uncheck
    }));

    // handle check click
    const handleClick = () => {
        let newState = !checked
        setChecked(newState);
        if (onToggle) onToggle(newState, value);
    }

    // Expose toggle to the parent
    const toggle = () => {
        handleClick();
    };

    // check the boss
    const check = () => {
        setChecked(true);
    };

    // uncheck
    const uncheck = () => {
        setChecked(false);
    }

    const title = () => {
        return checked? "Uncheck" : "Check";
    }

    return (
        <div className={`${styles.content} ${className? className : ""}`}>
            <div 
                className={`${styles.icon} ${checked && styles.checked} ${iconStyle}`} 
                onClick={handleClick} title={title()}
            ></div>
            <div className={`${styles.label} ${labelStyle}`} onClick={handleClick} title={title()}>{label}</div>
        </div>
    )
})

export type {CheckBoxRefType}
export default CheckBox;