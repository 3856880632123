import React from "react"

import styles from "../styles/components/ConfirmDialog.module.css";


const defaultConfirmBoxParams = {
    title: "Confirm Action",
    message: "Are you sure you'll love to proceed with this?",
    positiveText: "Yes",
    negativeText: "No",
    onAccept: (message: any) => {return},
    onDeny: (message: any) => {return}
}
type ConfirmBoxParamType = typeof defaultConfirmBoxParams

const ConfirmDialog: React.FC<ConfirmBoxParamType> = ({title, message, onAccept, onDeny, positiveText, negativeText}) => {

    // close the dialog
    const handleAccepted = (message?: any) => {
        if (onAccept) onAccept(message)
    }

    const handleDenied = (message?: any) => {
        if (onDeny) onDeny(message);
    }

    return (
        <div className={styles.contentBox}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subtitle}>{message}</div>
                </div>
                <div className={styles.actionBox}>
                    <div className={styles.btnCancel} onClick={() => handleDenied()}>{negativeText? negativeText : "Cancel"}</div>
                    <div className={styles.btnOk} onClick={() => handleAccepted()}>{positiveText? positiveText : "OK"}</div>
                </div>
            </div>
        </div>
    )
}

export type {ConfirmBoxParamType}
export {defaultConfirmBoxParams}
export default ConfirmDialog;