// src/components/PrivateRoute.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from './MainRouter';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

import { getLocalStorageItem } from '../utils';

type PrivateRouteProps = {
    children: ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const {userInfo} = useSelector((state: RootState) => state.home);

    const refreshToken = getLocalStorageItem("refreshToken")
    
    const isAuthenticated = (): boolean => {
        return (userInfo.email.length > 0 && userInfo.brandId.length > 0 && 
            userInfo.name.length > 0 && (refreshToken?((refreshToken.length > 0)?true:false):false))
    };

    return (
        <>
            {isAuthenticated()?children:<Navigate to={paths.login} />}
        </>
    )   
}

export default PrivateRoute;
