import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../../styles/Dashboard/Navigation.module.css";

import { setActiveTabPath } from "../../../redux/slices/homeSlice";
import { RootState } from "../../../redux/store";

import { paths } from "../../MainRouter";

type NavItem = {
    label: string;
    icon: {default: string};
    active: boolean;
    pathName: string,
}

const svgIcons = {
    user: require('../../../svg/ic_nav_user.svg'),
    inventory: require('../../../svg/ic_nav_inventory.svg'),
    overview: require('../../../svg/ic_nav_overview.svg'),
    billing: require('../../../svg/ic_nav_billing.svg'),
};


const Navigation = () => {
    const initNavItems: NavItem[] = [
        {label: "Account Information", icon: svgIcons.user, active: true, pathName: paths.accountInfo},
        {label: "Inventory", icon: svgIcons.inventory, active: false, pathName: paths.inventory},
        {label: "Overview", icon: svgIcons.overview, active: false, pathName: ""},
        {label: "Billing", icon: svgIcons.billing, active: false, pathName: ""},
    ]
    const {activeTabPath} = useSelector((state: RootState) => state.home)
    const [navItems, setNavItems] = useState<NavItem[]>(initNavItems);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setActiveNavItem = (pathName: string) => {
        if(!pathName) return;
        dispatch(setActiveTabPath(pathName))
        navigate(pathName);
    }

    useEffect(() => {
        if(activeTabPath === "") return;
        const activeIndex = navItems.findIndex(({pathName}) => activeTabPath === pathName);
        if(activeIndex === -1) return;

        setNavItems((items) => items.map((item,index) => {
            if(index === activeIndex){
                return {...item, active:true}
            }else{
                return {...item, active:false}
            }
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabPath])

    useEffect(() => {
        dispatch(setActiveTabPath(paths.accountInfo))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.container}>
            <nav>
                {navItems.map(({label,icon, active, pathName}, idx) => (
                    <div 
                        key={label}
                        className={`${styles.navItem} ${active && styles.active}`}
                        onClick={() => setActiveNavItem(pathName)}
                        style={{ backgroundImage: `url(${icon.default})` }}
                    >
                        {label}
                    </div>
                ))}
            </nav>
            <div className={styles.btnHelp}>Help center</div>
        </div>
    )
}

export default Navigation;