import React from "react"

import styles from "../styles/components/AlertDialog.module.css";


type Props = {
    title: string;
    message: string;
    onDialogClose: () => void; 
}

const AlertDialog: React.FC<Props> = ({title, message, onDialogClose}) => {

    // close the dialog
    const handleClosedialog = () => {
        if (onDialogClose) onDialogClose()
    }

    return (
        <div className={styles.contentBox}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subtitle}>{message}</div>
                </div>
                <div className={styles.actionBox}>
                    <div className={styles.btnOk} onClick={handleClosedialog}>OK</div>
                </div>
            </div>
        </div>
    )
}

export default AlertDialog;